var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { getAggregation } from './getAggregation';
import { IndicesController } from './indices.controller';
var pivotPathSeparator = '__';
export function pivot(relevantData, options, response, parentPath, indicesCtrl) {
  var e_1, _a, e_2, _b, e_3, _c, e_4, _d;
  var _e;
  if (response === void 0) {
    response = {
      key: 'root'
    };
  }
  if (parentPath === void 0) {
    parentPath = 'root';
  }
  if (indicesCtrl === void 0) {
    indicesCtrl = new IndicesController();
  }
  try {
    for (var _f = __values(options.columns), _g = _f.next(); !_g.done; _g = _f.next()) {
      var column = _g.value;
      if (column.attribute) {
        indicesCtrl.getOrCreateIndex(column.attribute, relevantData);
      }
    }
  } catch (e_1_1) {
    e_1 = {
      error: e_1_1
    };
  } finally {
    try {
      if (_g && !_g.done && (_a = _f.return)) _a.call(_f);
    } finally {
      if (e_1) throw e_1.error;
    }
  }
  try {
    for (var _h = __values(options.rows), _j = _h.next(); !_j.done; _j = _h.next()) {
      var row = _j.value;
      if (row.attribute) {
        indicesCtrl.getOrCreateIndex(row.attribute, relevantData);
      }
    }
  } catch (e_2_1) {
    e_2 = {
      error: e_2_1
    };
  } finally {
    try {
      if (_j && !_j.done && (_b = _h.return)) _b.call(_h);
    } finally {
      if (e_2) throw e_2.error;
    }
  }
  try {
    for (var _k = __values(options.values), _l = _k.next(); !_l.done; _l = _k.next()) {
      var value = _l.value;
      if (value.attribute) {
        indicesCtrl.getOrCreateIndex(value.attribute, relevantData);
      }
    }
  } catch (e_3_1) {
    e_3 = {
      error: e_3_1
    };
  } finally {
    try {
      if (_l && !_l.done && (_c = _k.return)) _c.call(_k);
    } finally {
      if (e_3) throw e_3.error;
    }
  }
  /**
   * Rows
   */
  if (options.rows && options.rows.length > 0) {
    response.rows = response.rows || [];
    var item = getPivotRowItem(relevantData, options.rows[0], options, indicesCtrl, parentPath);
    response.rows.push(item);
  }
  /**
   * Columns
   */
  if (options.columns && options.columns.length > 0) {
    response.columns = response.columns || [];
    var item = getPivotColumnItem(relevantData, options.columns[0], options, indicesCtrl, parentPath);
    response.columns.push(item);
  }
  /**
   * Value Aggregations
   */
  if (response.key === 'root') {
    try {
      for (var _m = __values(options.values), _o = _m.next(); !_o.done; _o = _m.next()) {
        var value = _o.value;
        response.values = response.values || [];
        if (parentPath === 'root' && ((_e = options.options) === null || _e === void 0 ? void 0 : _e.calculateTotals) === false) {
          continue;
        }
        var agg = getAggregation(value, relevantData, indicesCtrl.getIndex(value.attribute), value.attribute);
        response.values.push(agg);
      }
    } catch (e_4_1) {
      e_4 = {
        error: e_4_1
      };
    } finally {
      try {
        if (_o && !_o.done && (_d = _m.return)) _d.call(_m);
      } finally {
        if (e_4) throw e_4.error;
      }
    }
  }
  return response;
}
function getPivotRowItem(modelData, options, globalOptions, modelIndexMap, parentPath) {
  var e_5, _a, e_6, _b, e_7, _c, e_8, _d;
  if (parentPath === void 0) {
    parentPath = '';
  }
  var item = {
    key: options.attribute
  };
  var indexMap = modelIndexMap.getIndex(options.attribute);
  if (!indexMap) {
    return item;
  }
  try {
    for (var _e = __values(indexMap.entries()), _f = _e.next(); !_f.done; _f = _e.next()) {
      var _g = __read(_f.value, 2),
        key = _g[0],
        itemKeys = _g[1];
      var rowItem = {
        key: key
      };
      var relevantData = new Map();
      try {
        for (var itemKeys_1 = (e_6 = void 0, __values(itemKeys)), itemKeys_1_1 = itemKeys_1.next(); !itemKeys_1_1.done; itemKeys_1_1 = itemKeys_1.next()) {
          var key_1 = itemKeys_1_1.value;
          if (!modelData.has(key_1)) {
            continue;
          }
          relevantData.set(key_1, modelData.get(key_1));
        }
      } catch (e_6_1) {
        e_6 = {
          error: e_6_1
        };
      } finally {
        try {
          if (itemKeys_1_1 && !itemKeys_1_1.done && (_b = itemKeys_1.return)) _b.call(itemKeys_1);
        } finally {
          if (e_6) throw e_6.error;
        }
      }
      if (!relevantData.size) continue;
      try {
        for (var _h = (e_7 = void 0, __values(globalOptions.values)), _j = _h.next(); !_j.done; _j = _h.next()) {
          var value = _j.value;
          rowItem.values = rowItem.values || [];
          if (parentPath === 'root' && (options === null || options === void 0 ? void 0 : options.calculateTotals) === false) {
            continue;
          }
          var agg = getAggregation(value, relevantData, modelIndexMap.getIndex(value.attribute), value.attribute);
          rowItem.values.push(agg);
        }
      } catch (e_7_1) {
        e_7 = {
          error: e_7_1
        };
      } finally {
        try {
          if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
        } finally {
          if (e_7) throw e_7.error;
        }
      }
      item.rows = item.rows || [];
      item.rows.push(rowItem);
      if (globalOptions.rows.length > 1) {
        var newOptions = __assign(__assign({}, globalOptions), {
          rows: globalOptions.rows.slice(1)
        });
        pivot(relevantData, newOptions, rowItem, parentPath + pivotPathSeparator + options.attribute + pivotPathSeparator + key);
      } else {
        if (globalOptions.columns) {
          try {
            for (var _k = (e_8 = void 0, __values(globalOptions.columns)), _l = _k.next(); !_l.done; _l = _k.next()) {
              var columnOption = _l.value;
              rowItem.columns = rowItem.columns || [];
              var columnItem = getPivotColumnItem(relevantData, columnOption, globalOptions, modelIndexMap, parentPath + pivotPathSeparator + options.attribute + pivotPathSeparator + key);
              rowItem.columns.push(columnItem);
            }
          } catch (e_8_1) {
            e_8 = {
              error: e_8_1
            };
          } finally {
            try {
              if (_l && !_l.done && (_d = _k.return)) _d.call(_k);
            } finally {
              if (e_8) throw e_8.error;
            }
          }
        }
      }
    }
  } catch (e_5_1) {
    e_5 = {
      error: e_5_1
    };
  } finally {
    try {
      if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
    } finally {
      if (e_5) throw e_5.error;
    }
  }
  return item;
}
function getPivotColumnItem(modelData, options, globalOptions, modelIndexMap, parentPath) {
  var e_9, _a, e_10, _b, e_11, _c;
  if (parentPath === void 0) {
    parentPath = '';
  }
  var item = {
    key: options.attribute
  };
  var indexMap = modelIndexMap.getIndex(options.attribute);
  if (!indexMap) {
    return item;
  }
  try {
    for (var _d = __values(indexMap.entries()), _e = _d.next(); !_e.done; _e = _d.next()) {
      var _f = __read(_e.value, 2),
        key = _f[0],
        itemKeys = _f[1];
      var rowItem = {
        key: key
      };
      var relevantData = new Map();
      try {
        for (var itemKeys_2 = (e_10 = void 0, __values(itemKeys)), itemKeys_2_1 = itemKeys_2.next(); !itemKeys_2_1.done; itemKeys_2_1 = itemKeys_2.next()) {
          var key_2 = itemKeys_2_1.value;
          if (!modelData.has(key_2)) {
            continue;
          }
          relevantData.set(key_2, modelData.get(key_2));
        }
      } catch (e_10_1) {
        e_10 = {
          error: e_10_1
        };
      } finally {
        try {
          if (itemKeys_2_1 && !itemKeys_2_1.done && (_b = itemKeys_2.return)) _b.call(itemKeys_2);
        } finally {
          if (e_10) throw e_10.error;
        }
      }
      if (!relevantData.size) continue;
      try {
        for (var _g = (e_11 = void 0, __values(globalOptions.values)), _h = _g.next(); !_h.done; _h = _g.next()) {
          var value = _h.value;
          rowItem.values = rowItem.values || [];
          if (parentPath === 'root' && (options === null || options === void 0 ? void 0 : options.calculateTotals) === false) {
            continue;
          }
          var agg = getAggregation(value, relevantData, modelIndexMap.getIndex(value.attribute), value.attribute);
          rowItem.values.push(agg);
        }
      } catch (e_11_1) {
        e_11 = {
          error: e_11_1
        };
      } finally {
        try {
          if (_h && !_h.done && (_c = _g.return)) _c.call(_g);
        } finally {
          if (e_11) throw e_11.error;
        }
      }
      item.columns = item.columns || [];
      item.columns.push(rowItem);
      if (globalOptions.columns.length > 1) {
        var newOptions = __assign(__assign({}, globalOptions), {
          columns: globalOptions.columns.slice(1)
        });
        pivot(relevantData, newOptions, rowItem, parentPath + pivotPathSeparator + options.attribute + pivotPathSeparator + key);
      }
    }
  } catch (e_9_1) {
    e_9 = {
      error: e_9_1
    };
  } finally {
    try {
      if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
    } finally {
      if (e_9) throw e_9.error;
    }
  }
  return item;
}
