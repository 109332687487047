var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
var ScriptController = /** @class */function () {
  function ScriptController() {
    this.scripts = new Map();
    this.scriptFunctions = new Map();
  }
  ScriptController.prototype.keys = function () {
    return Array.from(this.scripts.keys());
  };
  ScriptController.prototype.setScript = function (key, script) {
    this.scripts.set(key, script);
  };
  ScriptController.prototype.removeScript = function (key) {
    this.scripts.delete(key);
  };
  ScriptController.prototype.getScript = function (key) {
    return this.scripts.get(key);
  };
  ScriptController.prototype.hasScript = function (key) {
    return this.scripts.has(key);
  };
  ScriptController.prototype.getScriptAsFn = function (key, argsAsKeys) {
    if (this.scriptFunctions.has(key)) {
      return this.scriptFunctions.get(key);
    }
    var script = this.getScript(key);
    if (!script) {
      throw new Error("Script ".concat(key, " not found"));
    }
    var wrapperScript = this.getWrapperScript(script);
    var fn = new (Function.bind.apply(Function, __spreadArray(__spreadArray([void 0, 'script_type'], __read(argsAsKeys), false), [wrapperScript], false)))();
    this.scriptFunctions.set(key, fn);
    return fn;
  };
  ScriptController.prototype.getWrapperScript = function (script) {
    var wrapperScript = "\n    \"use strict\";\n      ".concat(script, "\n      ");
    return wrapperScript;
  };
  ScriptController.prototype.executeScript = function (key, type, args) {
    var script = this.getScript(key);
    if (!script) {
      throw new Error("Script ".concat(key, " not found"));
    }
    if (!args.context) {
      args.context = args.options || {};
    }
    var argsAsKeys = Object.keys(args);
    var argsAsArray = Object.values(args);
    var fn = this.getScriptAsFn(key, argsAsKeys);
    return fn.apply(void 0, __spreadArray([type], __read(argsAsArray), false));
  };
  return ScriptController;
}();
export { ScriptController };
