import cloneDeep from 'lodash/cloneDeep';
import { perf } from './performance';
import { generateKey } from './helper';
var ChangeDetector = /** @class */function () {
  function ChangeDetector() {
    this.updateListener = new Set();
  }
  ChangeDetector.prototype._init = function (options) {
    var _this = this;
    this.key = options.cdRefKey;
    this.analyticsController = options.analyticsController;
    this.parentCd = options.parentCd;
    this.dcuplInitOptions = options.dcuplInitOptions;
    if (this.parentCd) {
      this.off = this.parentCd.on(function (msg) {
        var _a;
        if (msg.action === 'update' && msg.originCdRefKey === ((_a = _this.parentCd) === null || _a === void 0 ? void 0 : _a.key)) {
          _this.trigger(msg);
        }
      });
    }
  };
  ChangeDetector.prototype.destroy = function () {
    if (this.off) {
      this.off();
    }
  };
  ChangeDetector.prototype.trigger = function (msg) {
    if (!msg.originCdRefKey) {
      msg.originCdRefKey = this.key;
    }
    this.updateListener.forEach(function (cb) {
      cb(msg);
    });
  };
  ChangeDetector.prototype.on = function (cb) {
    var _this = this;
    this.updateListener.add(cb);
    return function () {
      _this.updateListener.delete(cb);
    };
  };
  return ChangeDetector;
}();
export { ChangeDetector };
export var clone = function (obj, shouldClone) {
  if (!shouldClone) {
    return obj;
  }
  if (typeof structuredClone !== 'undefined') {
    return structuredClone(obj);
  } else {
    return cloneDeep(obj);
  }
};
var finish = function (cdRef, msg, start, context, result) {
  var _a, _b;
  try {
    var shouldClone = !!((_b = (_a = cdRef.dcuplInitOptions.performance) === null || _a === void 0 ? void 0 : _a.clone) === null || _b === void 0 ? void 0 : _b.enabled) && !!msg.cloneResult;
    var valueToReturn = clone(result, shouldClone);
    var end = perf.now();
    msg.duration = end - start;
    cdRef === null || cdRef === void 0 ? void 0 : cdRef.trigger(msg);
    var value = {
      duration: msg.duration
    };
    if (msg.modelKey) {
      value.model = msg.modelKey;
    }
    if (msg.args) {
      value.args = msg.args;
    }
    cdRef.analyticsController.mark({
      name: "".concat(msg.name, ":end"),
      context: context
    });
    cdRef.analyticsController.measure({
      name: "".concat(msg.name),
      start: "".concat(msg.name, ":start"),
      end: "".concat(msg.name, ":end"),
      context: context,
      detail: value
    });
    return valueToReturn;
  } catch (err) {
    return result;
  }
};
export var trigger = function (msg) {
  var log = function (_target, _propertyKey, descriptor) {
    var originalMethod = descriptor.value;
    descriptor.value = function () {
      var _a;
      var args = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
      }
      var cdRef = this.cdRef;
      var start = perf.now();
      var context = '';
      if (msg.mergeAnalytics) {} else {
        context = generateKey();
      }
      cdRef.analyticsController.mark({
        name: "".concat(msg.name, ":start"),
        context: context
      });
      var modelKey = (_a = this.model) === null || _a === void 0 ? void 0 : _a.key;
      if (!msg.originCdRefKey && cdRef.key) {
        msg.originCdRefKey = cdRef.key;
      }
      if (modelKey) {
        msg.modelKey = modelKey;
      }
      try {
        var result = originalMethod.apply(this, args);
        msg.method = _propertyKey;
        msg.args = args;
        if (result && 'then' in result) {
          return result.then(function (result) {
            return finish(cdRef, msg, start, context, result);
          });
        } else {
          return finish(cdRef, msg, start, context, result);
        }
      } catch (err) {
        throw err;
      }
    };
    return descriptor;
  };
  return log;
};
