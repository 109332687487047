var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { isDate } from 'date-fns';
// returns a list of properties based on the input data
export var getPropertiesForData = function (data, deep) {
  var e_1, _a, e_2, _b;
  var properties = [];
  try {
    for (var data_1 = __values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
      var item = data_1_1.value;
      var _loop_1 = function (key, value) {
        var property = properties.find(function (p) {
          return p.key === key;
        });
        var type = getDcuplPropertyTypeForNativeType(value);
        if (property) {
          if (property.type !== type) {
            property.type = 'any';
          }
        } else {
          var prop = {
            key: key,
            type: type
          };
          properties.push(prop);
        }
      };
      try {
        for (var _c = (e_2 = void 0, __values(Object.entries(item))), _d = _c.next(); !_d.done; _d = _c.next()) {
          var _e = __read(_d.value, 2),
            key = _e[0],
            value = _e[1];
          _loop_1(key, value);
        }
      } catch (e_2_1) {
        e_2 = {
          error: e_2_1
        };
      } finally {
        try {
          if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
        } finally {
          if (e_2) throw e_2.error;
        }
      }
      if (!deep) {
        break;
      }
    }
  } catch (e_1_1) {
    e_1 = {
      error: e_1_1
    };
  } finally {
    try {
      if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
    } finally {
      if (e_1) throw e_1.error;
    }
  }
  return properties;
};
export var isInt = function (n) {
  return Number(n) === n && n % 1 === 0;
};
export var isFloat = function (n) {
  return Number(n) === n && n % 1 !== 0;
};
export var getDcuplPropertyTypeForNativeType = function (value) {
  var type = typeof value;
  switch (type) {
    case 'string':
      return 'string';
    case 'number':
      if (isInt(value)) {
        return 'int';
      } else if (isFloat(value)) {
        return 'float';
      } else {
        return 'any';
      }
    case 'boolean':
      return 'boolean';
    case 'object':
      return isDate(value) ? 'date' : 'json';
    default:
      return 'any';
  }
};
