var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
export var getFilterEntryId = function (filterItemId, value) {
  if (value === void 0) {
    value = '';
  }
  return "".concat(filterItemId, "#").concat(value);
};
export var getNumberConstraintUid = function (options) {
  var value = "".concat(options.gt, "_").concat(options.gte, "_").concat(options.lt, "_").concat(options.lte, "_").concat(options.eq, "_").concat(options.ne);
  return value;
};
export var getDateConstraintUid = function (options) {
  var value = "".concat(options.gt, "_").concat(options.gte, "_").concat(options.lt, "_").concat(options.lte, "_").concat(options.eq, "_").concat(options.ne);
  return value;
};
export var uniqBy = function (arr, predicate) {
  var cb = typeof predicate === 'function' ? predicate : function (o) {
    return o[predicate];
  };
  return __spreadArray([], __read(arr.reduce(function (map, item) {
    var key = item === null || item === undefined ? item : cb(item);
    map.has(key) || map.set(key, item);
    return map;
  }, new Map()).values()), false);
};
export var groupBy = function (arr, criteria) {
  return arr.reduce(function (obj, item) {
    // Check if the criteria is a function to run on the item or a property of it
    var key = typeof criteria === 'function' ? criteria(item) : item[criteria];
    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }
    // Push the value to the object
    obj[key].push(item);
    // Return the object to the next item in the loop
    return obj;
  }, {});
};
export function generateKey(length) {
  if (length === void 0) {
    length = 10;
  }
  var text = '';
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}
export var removeTrailingSlash = function (str) {
  return str.replace(/\/+$/, '');
};
export var escapeRegExp = function (text) {
  if (typeof text !== 'string') return '';
  return text.replace(/[[\]{}()*+?.,\\^$|#]/g, '\\$&');
  /**
   * original regex
   * remove \s -
   */
  // return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};
export var unescapeRegExp = function (escapedText) {
  if (typeof escapedText !== 'string') return '';
  return escapedText.replace(/\\([[\]{}()*+?.,\\^$|#])/g, '$1');
};
