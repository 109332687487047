var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
export function computeSuggestions(data, index, options, queryManager) {
  var e_1, _a;
  if (!options.max) {
    options.max = 20;
  }
  var suggestions = [];
  var relevantSearchTerm = options.transform ? queryManager.transformValue(options.value, options.transform) : options.value;
  try {
    for (var _b = __values(index.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
      var _d = __read(_c.value, 2),
        key = _d[0],
        value = _d[1];
      var isRelevantAttributeIndex = value.some(function (item) {
        return data.has(item);
      });
      var relevantKey = options.transform ? queryManager.transformValue(key, options.transform) : key;
      if (options.excludeUndefineds && typeof key === 'undefined') {
        continue;
      }
      if (options.excludeNulls && key === null) {
        continue;
      }
      if (isRelevantAttributeIndex && (relevantSearchTerm === '' || relevantSearchTerm === '//')) {
        var suggestion = {
          value: key,
          keys: value
        };
        suggestions.push(suggestion);
      } else if (isRelevantAttributeIndex && queryManager.queryFind(relevantKey, relevantSearchTerm, 'some')) {
        var suggestion = {
          value: key,
          keys: value
        };
        suggestions.push(suggestion);
      }
      if (suggestions.length === options.max) {
        break;
      }
    }
  } catch (e_1_1) {
    e_1 = {
      error: e_1_1
    };
  } finally {
    try {
      if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
    } finally {
      if (e_1) throw e_1.error;
    }
  }
  return suggestions;
}
