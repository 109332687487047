import { Injectable } from '@angular/core';
import { Dcupl } from '@dcupl/core';
import { AppLoaderConfiguration, DcuplAppLoader } from '@dcupl/loader';
import { ModelDefinition } from '@dcupl/common';
import { ConfigService } from './config.service';
import { DataConfig } from '../../../../../shared/types/config/data-config.types';
import { EmbertProperty } from '../../../../../shared/types/config/dcupl.types';

@Injectable({
  providedIn: 'root',
})
export class DcuplService {
  private DCUPL_MODELS = {
    MAIN: 'main',
  };

  constructor(private configService: ConfigService) {}

  public async fetchSource(dataConfig: DataConfig) {
    const dcupl = new Dcupl();

    const loader = new DcuplAppLoader();

    dcupl.loaders.add(loader);

    try {
      const resource = this.getDataResourceForConfig(dataConfig);
      if (resource) {
        const config: AppLoaderConfiguration.ProjectConfiguration = {
          resources: [resource],
          options: {},
        };
        loader.config.set(config);

        const modelDefinition = this.getModelDefinitionForConfig(dataConfig);

        await loader.process();
        dcupl.models.set(modelDefinition);
        await dcupl.init();
        const properties = this.getAvailableProperties(dcupl);
        if (properties) {
          this.configService.updateConfig({
            data: {
              ...dataConfig,
              hasValidSource: true,
              availableProperties: properties,
            },
          });
        }
      }
    } catch (err) {
      console.error(err);
      this.configService.updateConfig({
        data: {
          ...dataConfig,
          hasValidSource: false,
        },
      });
    }
  }

  private getAvailableProperties(dcupl: Dcupl): EmbertProperty[] {
    if (!dcupl) {
      return [];
    }
    const model = dcupl['modelParser'].models.get(this.DCUPL_MODELS.MAIN)?.getModelDefinition();

    const properties: EmbertProperty[] = [];

    if (model) {
      if (model.keyProperty == 'key') {
        properties.push({
          key: model.keyProperty,
          type: 'string',
        });
      }
      if (model.properties) {
        model.properties.forEach((property) => {
          properties.push({
            key: property.key,
            type: property.type as any,
          });
        });
      }
    }
    return properties;
  }

  private getModelDefinitionForConfig(dataConfig: DataConfig): ModelDefinition {
    return {
      key: this.DCUPL_MODELS.MAIN,
      autoGenerateKey: dataConfig.autoGenerateKey || false,
      autoGenerateProperties: true,
      keyProperty: dataConfig.keyProperty || 'key',
    };
  }
  private getDataResourceForConfig(dataConfig: DataConfig): AppLoaderConfiguration.DataResource | undefined {
    if (dataConfig.type === 'server') {
      return {
        type: 'data',
        url: dataConfig.server?.url || '',
        model: this.DCUPL_MODELS.MAIN,
      };
    }
    if (dataConfig.type === 'spreadsheet') {
      return {
        type: 'data',
        url: dataConfig.spreadsheet?.url || '',
        model: this.DCUPL_MODELS.MAIN,
      };
    }
    return;
  }
}
