var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { cloneDeep, findIndex } from 'lodash';
var DcuplQueryBuilder = /** @class */function () {
  function DcuplQueryBuilder() {
    this._defaultQueryOptions = {
      modelKey: '',
      groupKey: 'root',
      groupType: 'and',
      queries: []
    };
    this.currentQuery = cloneDeep(this._defaultQueryOptions);
  }
  DcuplQueryBuilder.prototype.init = function (options) {
    this._initOptions = cloneDeep(options);
    this.currentQuery = cloneDeep(this._defaultQueryOptions);
    this.currentQuery.modelKey = options.modelKey;
  };
  DcuplQueryBuilder.prototype.reset = function () {
    this.init(this._initOptions);
    return this.getQuery();
  };
  DcuplQueryBuilder.prototype.isQuery = function (query) {
    return 'attribute' in query;
  };
  DcuplQueryBuilder.prototype.isQueryGroup = function (query) {
    return 'groupKey' in query;
  };
  DcuplQueryBuilder.prototype.isRootQueryGroup = function (groupOrQuery) {
    return this.isQueryGroup(groupOrQuery) && groupOrQuery.groupKey === 'root';
  };
  DcuplQueryBuilder.prototype.getQueryGroup = function (groupKey) {
    var _this = this;
    return this.currentQuery.queries.find(function (q) {
      return _this.isQueryGroup(q) && q.groupKey === groupKey;
    });
  };
  DcuplQueryBuilder.prototype.getQuery = function () {
    return cloneDeep(this.currentQuery);
  };
  DcuplQueryBuilder.prototype.hasQueryGroup = function (groupKey) {
    var _this = this;
    return this.currentQuery.queries.some(function (query) {
      return _this.isQueryGroup(query) && query.groupKey === groupKey;
    });
  };
  DcuplQueryBuilder.prototype.has = function (options) {
    var _this = this;
    if (options.groupKey && options.queryKey) {
      var group = this.getQueryGroup(options.groupKey);
      if (group) {
        return group.queries.some(function (q) {
          return _this.isQuery(q) && q.queryKey === options.queryKey;
        });
      }
    } else if (options.groupKey) {
      return this.hasQueryGroup(options.groupKey);
    }
    return false;
  };
  DcuplQueryBuilder.prototype.applyOptions = function (options, mode) {
    if (mode === void 0) {
      mode = 'update';
    }
    if (mode === 'set') {
      this.currentQuery.count = options.count;
      this.currentQuery.start = options.start;
      this.currentQuery.sort = options.sort;
      this.currentQuery.projection = options.projection;
    } else {
      if (typeof (options === null || options === void 0 ? void 0 : options.sort) !== 'undefined') {
        this.currentQuery.sort = options.sort;
      }
      if (typeof (options === null || options === void 0 ? void 0 : options.count) !== 'undefined') {
        this.currentQuery.count = options.count;
      }
      if (typeof (options === null || options === void 0 ? void 0 : options.start) !== 'undefined') {
        this.currentQuery.start = options === null || options === void 0 ? void 0 : options.start;
      }
      if (typeof (options === null || options === void 0 ? void 0 : options.projection) !== 'undefined') {
        this.currentQuery.projection = options === null || options === void 0 ? void 0 : options.projection;
      }
    }
    return this.getQuery();
  };
  DcuplQueryBuilder.prototype.applyQuery = function (queryToApply, options, relevantQuery) {
    var e_1, _a;
    var _this = this;
    if (!relevantQuery) relevantQuery = this.currentQuery;
    if (Array.isArray(queryToApply)) {
      try {
        for (var queryToApply_1 = __values(queryToApply), queryToApply_1_1 = queryToApply_1.next(); !queryToApply_1_1.done; queryToApply_1_1 = queryToApply_1.next()) {
          var query = queryToApply_1_1.value;
          this.applyQuery(query, undefined, relevantQuery);
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (queryToApply_1_1 && !queryToApply_1_1.done && (_a = queryToApply_1.return)) _a.call(queryToApply_1);
        } finally {
          if (e_1) throw e_1.error;
        }
      }
    } else if (this.isRootQueryGroup(queryToApply)) {
      if (queryToApply.groupType) relevantQuery.groupType = queryToApply.groupType;
      // if (queryToApply.options) relevantQuery.options = queryToApply.options;
      if ((options === null || options === void 0 ? void 0 : options.mode) === 'set') {
        relevantQuery.queries = queryToApply.queries;
      } else {
        relevantQuery.queries = __spreadArray(__spreadArray([], __read(relevantQuery.queries), false), __read(queryToApply.queries), false);
      }
    } else if (this.isQuery(queryToApply)) {
      var idx = relevantQuery.queries.findIndex(function (query) {
        return _this.isQueryGroup(query) && query.groupKey === queryToApply.attribute;
      });
      if (idx > -1) {
        var queryGroup = relevantQuery.queries[idx];
        if ((options === null || options === void 0 ? void 0 : options.mode) === 'set') {
          queryGroup.queries = [queryToApply];
        } else {
          queryGroup.queries.push(queryToApply);
        }
      } else {
        relevantQuery.queries.push({
          groupKey: queryToApply.attribute,
          groupType: 'or',
          queries: [queryToApply]
        });
      }
      // handle query group
    } else if (this.isQueryGroup(queryToApply)) {
      var idx = relevantQuery.queries.findIndex(function (query) {
        return _this.isQueryGroup(query) && query.groupKey === queryToApply.groupKey;
      });
      if (idx > -1) {
        var queryGroup = relevantQuery.queries[idx];
        if (queryToApply.groupType) queryGroup.groupType = queryToApply.groupType;
        // if (queryToApply.options) queryGroup.options = queryToApply.options;
        if ((options === null || options === void 0 ? void 0 : options.mode) === 'set') {
          relevantQuery.queries[idx] = queryToApply;
        } else {
          queryGroup.queries = __spreadArray(__spreadArray([], __read(queryGroup.queries), false), __read(queryToApply.queries), false);
        }
      } else {
        relevantQuery.queries.push({
          groupKey: queryToApply.groupKey,
          groupType: queryToApply.groupType || 'or',
          queries: queryToApply.queries
          // options: queryToApply.options,
        });
      }
    }
    return relevantQuery;
  };
  DcuplQueryBuilder.prototype.removeAllQueries = function () {
    this.currentQuery.queries = [];
    return this.getQuery();
  };
  DcuplQueryBuilder.prototype.removeQuery = function (queryToRemove, relevantQuery) {
    // handle default query
    var e_2, _a;
    var _this = this;
    if (!relevantQuery) relevantQuery = this.currentQuery;
    if (this.isQuery(queryToRemove)) {
      var idx = relevantQuery.queries.findIndex(function (query) {
        return _this.isQueryGroup(query) && query.groupKey === queryToRemove.attribute;
      });
      if (idx > -1) {
        var queryGroup = relevantQuery.queries[idx];
        var idxOfQueryToRemove = findIndex(queryGroup.queries, queryToRemove);
        if (idxOfQueryToRemove > -1) {
          queryGroup.queries.splice(idxOfQueryToRemove, 1);
        }
        if (queryGroup.queries.length === 0) {
          relevantQuery.queries.splice(idx, 1);
        }
      }
    } else if (this.isQueryGroup(queryToRemove)) {
      var idx = relevantQuery.queries.findIndex(function (query) {
        return _this.isQueryGroup(query) && query.groupKey === queryToRemove.groupKey;
      });
      if (idx > -1) {
        if (!queryToRemove.queries) {
          relevantQuery.queries.splice(idx, 1);
        } else {
          var queryGroup = relevantQuery.queries[idx];
          try {
            for (var _b = __values(queryToRemove.queries), _c = _b.next(); !_c.done; _c = _b.next()) {
              var q = _c.value;
              var idxOfQueryToRemove = findIndex(queryGroup.queries, q);
              if (idxOfQueryToRemove > -1) {
                queryGroup.queries.splice(idxOfQueryToRemove, 1);
              }
            }
          } catch (e_2_1) {
            e_2 = {
              error: e_2_1
            };
          } finally {
            try {
              if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            } finally {
              if (e_2) throw e_2.error;
            }
          }
          if (queryGroup.queries.length === 0) {
            relevantQuery.queries.splice(idx, 1);
          }
        }
      }
    }
    return relevantQuery;
  };
  return DcuplQueryBuilder;
}();
export { DcuplQueryBuilder };
