import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const openCloseAnimation = trigger('openClose', [
  transition(':enter', [style({ height: 0 }), animate('150ms ease-in-out', style({ height: '*' }))]),
  transition(':leave', [animate('150ms ease-in-out', style({ height: 0 }))]),
]);

export const openCloseWidthAnimation = trigger('openCloseWidth', [
  transition(':enter', [
    style({
      width: '0px',
    }),
    animate('150ms ease-in-out', style({ width: '*' })),
  ]),
  transition(':leave', [animate('150ms ease-in-out', style({ width: '0px' }))]),
]);

export const fadeInRightAnimation = trigger('fadeInRight', [
  transition(':enter', [
    style({
      transform: 'translateX(100%)',
    }),
    animate('350ms ease-in-out', style({ transform: 'translateX(0%)' })),
  ]),
  transition(':leave', [animate('150ms ease-in-out', style({ transform: 'translateX(100%)' }))]),
]);

export const fadeInLeftAnimation = trigger('fadeInLeft', [
  transition(':enter', [
    style({
      transform: 'translateX(-150%)',
      width: '0%',
      opacity: 0,
    }),
    animate('300ms ease-in-out', style({ transform: 'translateX(0%)', width: '*', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('150ms ease-in-out', style({ transform: 'translateX(-150%)', width: '0%', opacity: 0 })),
  ]),
]);

export const fadeInDownAnimation = trigger('fadeInDown', [
  transition(':enter', [
    style({
      transform: 'translateY(-100%)',
      opacity: '0%',
    }),
    animate('350ms ease-in-out', style({ transform: 'translateY(0%)', opacity: '100%' })),
  ]),
  transition(':leave', [animate('150ms ease-in-out', style({ transform: 'translateY(-100%)', opacity: '0%' }))]),
]);

export const fadeInAnimation = trigger('fadeIn', [
  transition(':enter', [
    style({
      opacity: '0%',
    }),
    animate('100ms ease-in-out', style({ opacity: '100%' })),
  ]),
  transition(':leave', [animate('150ms ease-in-out', style({ opacity: '0%' }))]),
]);

export const sidebarAnimation = trigger('sidebarAnimation', [
  transition(':enter', [
    group([
      // query('.sidebar_animated_backdrop', [
      style({
        opacity: '0%',
      }),
      animate('150ms ease-in-out', style({ opacity: '100%' })),
      // ]),
      query(
        '.sidebar_animated_content',
        [
          style({
            transform: 'translateX(100%)',
          }),
          animate('300ms ease-in-out', style({ transform: 'translateX(0%)' })),
        ],
        { optional: true },
      ),
    ]),
  ]),
  transition(':leave', [
    group([
      // query('.sidebar_animated_backdrop', [
      animate('150ms 100ms ease-in-out', style({ opacity: '0%' })),
      // ]),

      query('.sidebar_animated_content', [animate('150ms ease-in-out', style({ transform: 'translateX(100%)' }))], {
        optional: true,
      }),
    ]),
  ]),
]);

export const sideBarSlideInFromRight = trigger('sideBarSlideInFromRight', [
  transition(':enter', [
    style({
      transform: 'translateX(100%) scale(100%)',
      transformOrigin: 'top right',
      width: '100%',
      height: '100%',
    }),
    animate('250ms ease-in-out', style({ transform: 'translateX(0%)' })),
  ]),
  transition(':leave', [animate('150ms ease-in-out', style({ transform: 'translateX(100%)' }))]),
]);
