var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { CatalogApi } from './catalog-api';
var Catalog = /** @class */function (_super) {
  __extends(Catalog, _super);
  function Catalog(dcuplApi, cdRef, indicesController) {
    var _this = _super.call(this, dcuplApi, cdRef, indicesController) || this;
    _this.dcuplApi = dcuplApi;
    _this.cdRef = cdRef;
    _this.indicesController = indicesController;
    return _this;
  }
  Catalog.prototype.init = function (listKey, model, data) {
    this.listKey = listKey;
    this.model = model;
    this.filter.init(model, data);
    _super.prototype.initListeners.call(this);
  };
  Catalog.prototype.update = function (model, data, indicesController) {
    this.model = model;
    this.filter.update(model, data, indicesController);
  };
  return Catalog;
}(CatalogApi);
export { Catalog };
