var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { get, isEqual, find, size, startsWith, isEqualWith, differenceBy, every, some, cloneDeep, endsWith, isMatch } from 'lodash';
import { DcuplQueryBuilder } from './query-builder';
var QueryManager = /** @class */function () {
  function QueryManager(scriptController) {
    this.scriptController = scriptController;
    this.registeredCustomOperator = new Map();
    this.queryBuilder = new DcuplQueryBuilder();
  }
  QueryManager.prototype.registerCustomOperator = function (operator, fn) {
    this.registeredCustomOperator.set(operator, fn);
  };
  QueryManager.prototype.transformValue = function (value, transformers) {
    var e_1, _a;
    var _this = this;
    if (Array.isArray(value)) {
      return value.map(function (val) {
        return _this.transformValue(val, transformers);
      });
    }
    if (typeof value !== 'string') {
      return value;
    }
    try {
      for (var transformers_1 = __values(transformers), transformers_1_1 = transformers_1.next(); !transformers_1_1.done; transformers_1_1 = transformers_1.next()) {
        var transformer = transformers_1_1.value;
        if (transformer === 'lowercase') {
          value = value.toLowerCase();
        } else if (transformer === 'trim') {
          value = value.trim();
        } else if (transformer === 'removeWhitespace') {
          value = value.replace(/\s/g, '');
        }
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (transformers_1_1 && !transformers_1_1.done && (_a = transformers_1.return)) _a.call(transformers_1);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
    return value;
  };
  QueryManager.prototype.evaluateQuery = function (query, dataEntry) {
    var _a, _b, _c, _d, _e, _f;
    var dataEntryValue;
    var queryValue = query.value;
    var arrayValueHandling = ((_a = query.options) === null || _a === void 0 ? void 0 : _a.arrayValueHandling) || 'some';
    if (Array.isArray((_b = query.options) === null || _b === void 0 ? void 0 : _b.transform) && query.options.transform.length > 0) {
      queryValue = this.transformValue(queryValue, query.options.transform);
    }
    if (query.attribute.includes('.') || query.attribute.includes('[')) {
      dataEntryValue = get(dataEntry, query.attribute);
    } else {
      dataEntryValue = dataEntry[query.attribute];
    }
    if (Array.isArray((_c = query.options) === null || _c === void 0 ? void 0 : _c.transform) && query.options.transform.length > 0) {
      dataEntryValue = this.transformValue(dataEntryValue, query.options.transform);
    }
    if (typeof dataEntryValue === 'undefined' || dataEntryValue === null) {
      var allowedUndefinedAndNullOperators = ['typeof', 'isTruthy', 'eq'];
      if (!allowedUndefinedAndNullOperators.includes(query.operator)) {
        return false;
      }
    }
    switch (query.operator) {
      case 'eq':
        if (!((_d = query.options) === null || _d === void 0 ? void 0 : _d.invert)) {
          return isEqual(dataEntryValue, queryValue);
        } else {
          return !isEqual(dataEntryValue, queryValue);
        }
      case 'find':
        {
          if (!((_e = query.options) === null || _e === void 0 ? void 0 : _e.invert)) {
            return this.queryFind(dataEntryValue, queryValue, arrayValueHandling);
          } else {
            return !this.queryFind(dataEntryValue, queryValue, arrayValueHandling);
          }
        }
      case 'lt':
        if (Array.isArray(dataEntryValue)) {
          if (arrayValueHandling === 'some') {
            return dataEntryValue.some(function (value) {
              return value < queryValue;
            });
          } else {
            return dataEntryValue.every(function (value) {
              return value < queryValue;
            });
          }
        } else {
          return dataEntryValue < queryValue;
        }
      case 'gt':
        if (Array.isArray(dataEntryValue)) {
          if (arrayValueHandling === 'some') {
            return dataEntryValue.some(function (value) {
              return value > queryValue;
            });
          } else {
            return dataEntryValue.every(function (value) {
              return value > queryValue;
            });
          }
        } else {
          return dataEntryValue > queryValue;
        }
      case 'lte':
        if (Array.isArray(dataEntryValue)) {
          if (arrayValueHandling === 'some') {
            return dataEntryValue.some(function (value) {
              return value <= queryValue;
            });
          } else {
            return dataEntryValue.every(function (value) {
              return value <= queryValue;
            });
          }
        } else {
          return dataEntryValue <= queryValue;
        }
      case 'gte':
        if (Array.isArray(dataEntryValue)) {
          if (arrayValueHandling === 'some') {
            return dataEntryValue.some(function (value) {
              return value >= queryValue;
            });
          } else {
            return dataEntryValue.every(function (value) {
              return value >= queryValue;
            });
          }
        } else {
          return dataEntryValue >= queryValue;
        }
      case 'typeof':
        if (!((_f = query.options) === null || _f === void 0 ? void 0 : _f.invert)) {
          if (Array.isArray(dataEntryValue)) {
            if (arrayValueHandling === 'some') {
              return dataEntryValue.some(function (value) {
                return typeof value === queryValue;
              });
            } else {
              return dataEntryValue.every(function (value) {
                return typeof value === queryValue;
              });
            }
          } else {
            return typeof dataEntryValue === queryValue;
          }
        } else {
          return typeof dataEntryValue !== queryValue;
        }
      case 'isTruthy':
        if (queryValue === true) {
          if (Array.isArray(dataEntryValue)) {
            if (arrayValueHandling === 'some') {
              return dataEntryValue.some(function (value) {
                return typeof !!value;
              });
            } else {
              return dataEntryValue.every(function (value) {
                return typeof !!value;
              });
            }
          } else {
            return !!dataEntryValue;
          }
        } else {
          return !dataEntryValue;
        }
      case 'size':
        return size(dataEntryValue) === queryValue;
      default:
        if (this.scriptController.hasScript(query.operator)) {
          return this.scriptController.executeScript(query.operator, 'operator', {
            query: query,
            item: dataEntry
          });
        }
        /**
         * @deprecated
         * Use custom script instead
         */
        if (this.registeredCustomOperator.has(query.operator)) {
          var fn = this.registeredCustomOperator.get(query.operator);
          if (typeof fn === 'function') {
            return fn(query, dataEntry);
          }
        }
    }
    return false;
  };
  QueryManager.prototype.evaluateEqOperator = function (query, initialDataset, indexMap) {
    var e_2, _a;
    var _b;
    var filteredData = new Map();
    var indices = indexMap.get(query.attribute);
    if (indices) {
      var value = void 0;
      if (typeof query.value === 'string') {
        value = query.value;
      } else if (((_b = query.value) === null || _b === void 0 ? void 0 : _b.key) && Object.keys(query.value).length === 1) {
        value = query.value.key;
      }
      if (typeof value !== 'undefined') {
        var indexValues = indices.get(value);
        if (indexValues) {
          indexValues.forEach(function (key) {
            if (initialDataset.has(key)) {
              filteredData.set(key, initialDataset.get(key));
            }
          });
          return filteredData;
        }
      }
    }
    try {
      // fallback if indices do not work properly...
      for (var initialDataset_1 = __values(initialDataset), initialDataset_1_1 = initialDataset_1.next(); !initialDataset_1_1.done; initialDataset_1_1 = initialDataset_1.next()) {
        var _c = __read(initialDataset_1_1.value, 2),
          key = _c[0],
          value = _c[1];
        if (this.evaluateQuery(query, value)) {
          filteredData.set(key, value);
        }
      }
    } catch (e_2_1) {
      e_2 = {
        error: e_2_1
      };
    } finally {
      try {
        if (initialDataset_1_1 && !initialDataset_1_1.done && (_a = initialDataset_1.return)) _a.call(initialDataset_1);
      } finally {
        if (e_2) throw e_2.error;
      }
    }
    return filteredData;
  };
  QueryManager.prototype.equalRegexFn = function (value, queryValue) {
    var _this = this;
    if (typeof queryValue === 'string' && startsWith(queryValue, '/') && endsWith(queryValue, '/')) {
      try {
        var regex = new RegExp(queryValue.slice(1, -1));
        return regex.test(value);
      } catch (err) {
        return false;
      }
    } else if (Array.isArray(value)) {
      return !!value.find(function (val) {
        return isEqualWith(val, queryValue, _this.equalRegexFn);
      });
    } else {
      return isMatch(value, queryValue);
    }
  };
  QueryManager.prototype.queryFind = function (entryValue, queryValue, arrayValueHandling) {
    var _this = this;
    if (Array.isArray(entryValue)) {
      if (typeof queryValue === 'string' || typeof queryValue === 'number' || typeof queryValue === 'boolean') {
        if (arrayValueHandling === 'some') {
          return entryValue.some(function (value) {
            return isEqualWith(value, queryValue, _this.equalRegexFn);
          });
        } else {
          return entryValue.every(function (value) {
            return isEqualWith(value, queryValue, _this.equalRegexFn);
          });
        }
      } else {
        return !!find(entryValue, queryValue);
      }
    } else {
      return isEqualWith(entryValue, queryValue, this.equalRegexFn);
    }
  };
  QueryManager.prototype.validateQuery = function (query) {
    var _this = this;
    if (this.queryBuilder.isQuery(query)) {
      if (!query.attribute || !query.operator) {
        throw new Error('invalid query - missing attribute or operator');
      }
      if (query.operator === 'find' && Array.isArray(query.value)) {
        throw new Error('invalid query - find operator cannot have array value');
      }
      return true;
    } else if (this.queryBuilder.isQueryGroup(query)) {
      return every(query.queries, function (q) {
        return _this.validateQuery(q);
      });
    } else {
      throw new Error('invalid query - missing query or attribute');
    }
  };
  QueryManager.prototype.getEvaluatedQueryDataset = function (query, initialDataset, indexMap) {
    var e_3, _a, e_4, _b;
    var _this = this;
    var filteredData = new Map();
    if (query.attribute.includes('[*]')) {
      var attribute = query.attribute.replace('[*]', '');
      var _loop_1 = function (key, value) {
        var arrayValue = value[attribute];
        if (Array.isArray(arrayValue)) {
          var results = arrayValue.map(function (arrayItem, index) {
            arrayItem;
            var queryCopy = cloneDeep(query);
            queryCopy.attribute = queryCopy.attribute.replace('[*]', "[".concat(index, "]"));
            return _this.evaluateQuery(queryCopy, value);
          });
          if (some(results)) {
            filteredData.set(key, value);
          }
        }
      };
      try {
        for (var initialDataset_2 = __values(initialDataset), initialDataset_2_1 = initialDataset_2.next(); !initialDataset_2_1.done; initialDataset_2_1 = initialDataset_2.next()) {
          var _c = __read(initialDataset_2_1.value, 2),
            key = _c[0],
            value = _c[1];
          _loop_1(key, value);
        }
      } catch (e_3_1) {
        e_3 = {
          error: e_3_1
        };
      } finally {
        try {
          if (initialDataset_2_1 && !initialDataset_2_1.done && (_a = initialDataset_2.return)) _a.call(initialDataset_2);
        } finally {
          if (e_3) throw e_3.error;
        }
      }
    } else if (query.operator === 'eq') {
      return this.evaluateEqOperator(query, initialDataset, indexMap);
    } else {
      try {
        for (var initialDataset_3 = __values(initialDataset), initialDataset_3_1 = initialDataset_3.next(); !initialDataset_3_1.done; initialDataset_3_1 = initialDataset_3.next()) {
          var _d = __read(initialDataset_3_1.value, 2),
            key = _d[0],
            value = _d[1];
          if (this.evaluateQuery(query, value)) {
            filteredData.set(key, value);
          }
        }
      } catch (e_4_1) {
        e_4 = {
          error: e_4_1
        };
      } finally {
        try {
          if (initialDataset_3_1 && !initialDataset_3_1.done && (_b = initialDataset_3.return)) _b.call(initialDataset_3);
        } finally {
          if (e_4) throw e_4.error;
        }
      }
    }
    return filteredData;
  };
  QueryManager.prototype.evaluateQueryGroup = function (queryGroup, initialDataset, earlyReturn, indexMap) {
    var e_5, _a, e_6, _b;
    var _c;
    var filteredData = new Map();
    var currentDataset = initialDataset;
    earlyReturn;
    if (!queryGroup.queries || ((_c = queryGroup.queries) === null || _c === void 0 ? void 0 : _c.length) === 0) {
      return currentDataset;
    }
    try {
      for (var _d = __values(queryGroup.queries), _e = _d.next(); !_e.done; _e = _d.next()) {
        var query = _e.value;
        var result = new Map();
        if (this.queryBuilder.isQuery(query)) {
          result = this.getEvaluatedQueryDataset(query, currentDataset, indexMap);
        } else {
          result = this.evaluateQueryGroup(query, currentDataset, earlyReturn, indexMap);
        }
        if (filteredData.size === 0) {
          filteredData = result;
        }
        if (queryGroup.groupType === 'or') {
          result.forEach(function (item) {
            filteredData.set(item.key, item);
          });
        } else {
          var diff = differenceBy(Array.from(filteredData.values()), Array.from(result.values()), 'key');
          try {
            for (var diff_1 = (e_6 = void 0, __values(diff)), diff_1_1 = diff_1.next(); !diff_1_1.done; diff_1_1 = diff_1.next()) {
              var item = diff_1_1.value;
              filteredData.delete(item.key);
            }
          } catch (e_6_1) {
            e_6 = {
              error: e_6_1
            };
          } finally {
            try {
              if (diff_1_1 && !diff_1_1.done && (_b = diff_1.return)) _b.call(diff_1);
            } finally {
              if (e_6) throw e_6.error;
            }
          }
        }
        if (queryGroup.groupType === 'and') {
          currentDataset = filteredData;
        } else {
          currentDataset = initialDataset;
        }
      }
    } catch (e_5_1) {
      e_5 = {
        error: e_5_1
      };
    } finally {
      try {
        if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
      } finally {
        if (e_5) throw e_5.error;
      }
    }
    return filteredData;
  };
  QueryManager.prototype.queryData = function (initialData, rootQuery, earlyReturn, indexMap) {
    // const currentDataset = new Map(initialData);
    var currentDataset = initialData;
    if (rootQuery.queries.length === 0) {
      return initialData;
    }
    if (!rootQuery.groupKey) {
      rootQuery.groupKey = 'root';
    }
    if (!rootQuery.groupType) {
      rootQuery.groupType = 'and';
    }
    if (!rootQuery.queries) {
      rootQuery.queries = [];
    }
    this.validateQuery(rootQuery);
    return this.evaluateQueryGroup(rootQuery, currentDataset, earlyReturn, indexMap);
  };
  return QueryManager;
}();
export { QueryManager };
