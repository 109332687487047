export var perf = {
  now: function () {
    if (typeof performance !== 'undefined') {
      return performance.now();
    } else {
      return new Date().getTime();
    }
  },
  mark: function (name) {
    if (typeof performance !== 'undefined') {
      performance.mark(name);
    }
  },
  measure: function (name, start, end) {
    if (typeof performance !== 'undefined') {
      performance.measure(name, start, end);
    }
  },
  getEntriesByName: function (url) {
    if (typeof performance !== 'undefined') {
      return performance.getEntriesByName(url);
    }
    return undefined;
  },
  getEntriesByType: function (type) {
    if (typeof performance !== 'undefined') {
      return performance.getEntriesByType(type);
    }
    return undefined;
  }
};
