var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { parseStringTemplate } from 'string-template-parser';
export var isValidProperty = function (property) {
  var validDatatypes = ['string', 'int', 'float', 'date', 'json', 'boolean', 'any', 'Array<string>', 'Array<int>', 'Array<float>', 'Array<date>'];
  if (!property.key || !property.type || validDatatypes.indexOf(property.type) === -1) {
    return {
      errorGroup: 'ModelDefinitionError',
      errorType: 'InvalidModelDefinition',
      attribute: property.key,
      description: 'Basic property config invalid.'
    };
  }
  if (property.derive && !(property.derive.localReference || !property.derive.remoteProperty)) {
    return {
      errorGroup: 'ModelDefinitionError',
      errorType: 'InvalidModelDefinition',
      attribute: property.key,
      description: 'Derive property config invalid'
    };
  }
  return;
};
export var validateModels = function (model, allModels) {
  var errors = [];
  model.references.forEach(function (ref) {
    if (!allModels.has(ref.model)) {
      errors.push({
        errorGroup: 'ModelDefinitionError',
        errorType: 'MissingModel',
        attribute: ref.key,
        model: model.key,
        description: "Referenced model ".concat(ref.model, " does not exist")
      });
    }
    if ('derive' in ref) {
      errors.push.apply(errors, __spreadArray([], __read(validateDerivedReference(ref, model, allModels)), false));
    }
    if ('resolve' in ref) {
      errors.push.apply(errors, __spreadArray([], __read(validateResolvedReference(ref, model, allModels)), false));
    }
    if ('groupBy' in ref) {
      errors.push.apply(errors, __spreadArray([], __read(validateGroupedReference(ref, model)), false));
    }
  });
  model.properties.forEach(function (prop) {
    if ('derive' in prop) {
      errors.push.apply(errors, __spreadArray([], __read(validateDerivedProperty(prop, model, allModels)), false));
    }
    if ('expression' in prop) {
      errors.push.apply(errors, __spreadArray([], __read(validateExpressionProperty(prop, model)), false));
    }
  });
  return errors;
};
var validateDerivedReference = function (ref, model, allModels) {
  var errors = [];
  var localRef = model.references.get(ref.derive.localReference);
  if (!localRef) {
    errors.push({
      errorGroup: 'ModelDefinitionError',
      errorType: 'MissingReference',
      attribute: ref.key,
      model: model.key,
      description: "Local Referenced ".concat(ref.derive.localReference, " does not exist in model ").concat(model.key)
    });
    return errors;
  }
  var remoteModel = allModels.get(localRef.model);
  if (remoteModel) {
    var remoteReferenece = remoteModel.references.get(ref.derive.remoteReference);
    if (!remoteReferenece) {
      errors.push({
        errorGroup: 'ModelDefinitionError',
        errorType: 'MissingReference',
        attribute: ref.key,
        model: model.key,
        description: "Remote Reference ".concat(ref.derive.remoteReference, " does not exist in model ").concat(remoteModel.key)
      });
    }
  }
  return errors;
};
var validateResolvedReference = function (ref, model, allModels) {
  var errors = [];
  var remoteModel = allModels.get(ref.resolve.model);
  if (!remoteModel) {
    errors.push({
      errorGroup: 'ModelDefinitionError',
      errorType: 'MissingModel',
      attribute: ref.key,
      model: model.key,
      description: "Referenced model ".concat(ref.model, " does not exist")
    });
    return errors;
  }
  var remoteReferenece = remoteModel.references.get(ref.resolve.reference);
  if (!remoteReferenece) {
    errors.push({
      errorGroup: 'ModelDefinitionError',
      errorType: 'MissingReference',
      attribute: ref.key,
      model: model.key,
      description: "Resolved Reference ".concat(ref.resolve.reference, " does not exist in model ").concat(remoteModel.key)
    });
  }
  return errors;
};
var validateGroupedReference = function (ref, model) {
  var errors = [];
  var remoteReferenece = model.references.get(ref.groupBy.reference);
  if (!remoteReferenece) {
    errors.push({
      errorGroup: 'ModelDefinitionError',
      errorType: 'MissingReference',
      attribute: ref.key,
      model: model.key,
      description: "Resolved Reference ".concat(ref.groupBy.reference, " does not exist in model ").concat(model.key)
    });
  }
  return errors;
};
var validateDerivedProperty = function (prop, model, allModels) {
  var errors = [];
  var localRef = model.references.get(prop.derive.localReference);
  if (!localRef) {
    errors.push({
      errorGroup: 'ModelDefinitionError',
      errorType: 'MissingReference',
      attribute: prop.key,
      model: model.key,
      description: "Local Referenced ".concat(prop.derive.localReference, " does not exist in model ").concat(model.key)
    });
    return errors;
  }
  var remoteModel = allModels.get(localRef.model);
  if (remoteModel) {
    if (prop.derive.remoteProperty === 'key') {
      return errors;
    }
    var remoteProperty = remoteModel.properties.get(prop.derive.remoteProperty);
    if (!remoteProperty) {
      errors.push({
        errorGroup: 'ModelDefinitionError',
        errorType: 'MissingProperty',
        attribute: prop.key,
        model: model.key,
        description: "Remote Property ".concat(prop.derive.remoteProperty, " does not exist in model ").concat(remoteModel.key)
      });
    }
  }
  return errors;
};
var validateExpressionProperty = function (prop, model) {
  var errors = [];
  var template = parseStringTemplate(prop.expression);
  template.variables.forEach(function (templateVar) {
    if (templateVar.name === 'key' || templateVar.name.endsWith('.key')) {
      return;
    }
    if (!model.properties.get(templateVar.name) && !model.references.get(templateVar.name)) {
      errors.push({
        errorGroup: 'ModelDefinitionError',
        errorType: 'UnknownExpressionVariable',
        attribute: prop.key,
        model: model.key,
        description: "Expression Variable ".concat(templateVar.name, " not found in expression ").concat(prop.key, " in ").concat(model.key)
      });
    }
  });
  return errors;
};
export var isValidReference = function (reference) {
  var validReferenceTypes = ['multiValued', 'singleValued'];
  if (!reference.key || !reference.type || !reference.model || validReferenceTypes.indexOf(reference.type) === -1) {
    return {
      errorGroup: 'ModelDefinitionError',
      errorType: 'InvalidModelDefinition',
      attribute: reference.key,
      description: 'Basic reference config invalid'
    };
  }
  if ('derive' in reference && !(reference.derive.localReference || !reference.derive.remoteReference)) {
    return {
      errorGroup: 'ModelDefinitionError',
      errorType: 'InvalidModelDefinition',
      attribute: reference.key,
      description: 'Derive reference config invalid'
    };
  }
  if ('resolve' in reference && !(reference.resolve.reference || !reference.resolve.model)) {
    return {
      errorGroup: 'ModelDefinitionError',
      errorType: 'InvalidModelDefinition',
      attribute: reference.key,
      description: 'Resolve reference config invalid'
    };
  }
  return;
};
