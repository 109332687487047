!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define("string-template-parser", [], t) : "object" == typeof exports ? exports["string-template-parser"] = t() : e["string-template-parser"] = t();
}(this, function () {
  return function (e) {
    function t(r) {
      if (n[r]) return n[r].exports;
      var i = n[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return e[r].call(i.exports, i, i.exports, t), i.l = !0, i.exports;
    }
    var n = {};
    return t.m = e, t.c = n, t.d = function (e, n, r) {
      t.o(e, n) || Object.defineProperty(e, n, {
        configurable: !1,
        enumerable: !0,
        get: r
      });
    }, t.n = function (e) {
      var n = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return t.d(n, "a", n), n;
    }, t.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, t.p = "", t(t.s = 1);
  }([function (e, t, n) {
    "use strict";

    function r(e) {
      var n = void 0 === e ? {} : e,
        r = n.ESCAPE,
        u = void 0 === r ? /^\\/ : r,
        o = n.VARIABLE_START,
        s = void 0 === o ? /^\${\s*/ : o,
        a = n.VARIABLE_END,
        c = void 0 === a ? /^\s*}/ : a,
        l = n.PIPE_START,
        f = void 0 === l ? /^\s*\|\s*/ : l,
        p = n.PIPE_PARAMETER_START,
        d = void 0 === p ? /^\s*:\s*/ : p,
        v = n.QUOTED_STRING,
        _ = void 0 === v ? t.DEFAULT_QUOTED_STRING_REGEX : v,
        T = n.QUOTED_STRING_TEST,
        m = void 0 === T ? null : T,
        E = n.QUOTED_STRING_GET_AND_ADVANCE,
        A = void 0 === E ? null : E,
        h = n.QUOTED_STRING_IN_PARAMETER_TEST,
        R = void 0 === h ? null : h,
        N = n.QUOTED_STRING_IN_PARAMETER_GET_AND_ADVANCE,
        S = void 0 === N ? null : N,
        b = m || function (e) {
          return _.test(e);
        },
        g = A || i(_),
        P = R || function (e) {
          return _.test(e);
        },
        O = S || i(_);
      return function (e) {
        function t(e) {
          void 0 === e && (e = 1), E = E.substr(e);
        }
        function n(e) {
          void 0 === e && (e = /^/), t(E.match(e)[0].length);
        }
        function r() {
          var e;
          return n(u), e = E.length > 0 ? E[0] : "", t(), e;
        }
        function i(e) {
          var t = (void 0 === e ? {} : e).isNull,
            n = void 0 !== t && t;
          N = {
            name: "",
            pipes: []
          }, D = !n;
        }
        function o() {
          A.variables.push(N), i({
            isNull: !0
          });
        }
        function a(e) {
          var t = (void 0 === e ? {} : e).isNull,
            n = void 0 !== t && t;
          S = {
            name: "",
            parameters: []
          }, I = !n;
        }
        function l() {
          N.pipes.push(S), a({
            isNull: !0
          });
        }
        function p(e) {
          var t = (void 0 === e ? {} : e).isNull,
            n = void 0 !== t && t;
          y = "", G = !n;
        }
        function v() {
          S.parameters.push(y), p({
            isNull: !0
          });
        }
        function _() {
          return !!c.test(E) && (n(c), h >= 3 && v(), h >= 2 && l(), h >= 1 && o(), h = 0, !0);
        }
        function T() {
          return !!f.test(E) && (n(f), h >= 3 && v(), h >= 2 && l(), h >= 1 && a(), h = 2, !0);
        }
        function m() {
          return !!d.test(E) && (n(d), h >= 3 && v(), h >= 2 && p(), h = 3, !0);
        }
        for (var E = e, A = {
            literals: [],
            variables: []
          }, h = 0, R = "", N = {
            name: "",
            pipes: []
          }, S = {
            name: "",
            parameters: []
          }, y = "", D = !1, I = !1, G = !1; E && E.length > 0;) switch (h) {
          case 0:
            if (u.test(E)) {
              R += r();
              continue;
            }
            if (s.test(E)) {
              h = 1, i(), A.literals.push(R), R = "", n(s);
              continue;
            }
            R += E[0], t();
            break;
          case 1:
            if (u.test(E)) {
              N.name += r();
              continue;
            }
            if (_() || T()) continue;
            if (b(E)) {
              N.name += g(E, t);
              continue;
            }
            N.name += E[0], t();
            break;
          case 2:
            if (u.test(E)) {
              S.name += r();
              continue;
            }
            if (_() || m() || T()) continue;
            if (b(E)) {
              S.name += g(E, t);
              continue;
            }
            S.name += E[0], t();
            break;
          case 3:
            if (u.test(E)) {
              y += r();
              continue;
            }
            if (_() || m() || T()) continue;
            if (P(E)) {
              y += O(E, t);
              continue;
            }
            y += E[0], t();
        }
        return G && S.parameters.push(y), I && N.pipes.push(S), D && A.variables.push(N), A.literals.push(R), A;
      };
    }
    function i(e) {
      return function (t, n) {
        var r = t.match(e);
        return n(r[0].length), r.slice(2).join("").replace("\\\\", "\\");
      };
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.DEFAULT_QUOTED_STRING_REGEX = /^('((?:[^'\\]|\\.)*)'|'((?:[^'\\]|\\.)*)$|"((?:[^"\\]|\\.)*)"|"((?:[^"\\]|\\.)*)$)/, t.parseStringTemplateGenerator = r, t.parseStringTemplate = r(), t.getQuotedStringAndAdvanceForRegex = i;
  }, function (e, t, n) {
    e.exports = n(2);
  }, function (e, t, n) {
    "use strict";

    function r(e) {
      for (var n in e) t.hasOwnProperty(n) || (t[n] = e[n]);
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), r(n(0)), r(n(3));
  }, function (e, t, n) {
    "use strict";

    function r(e) {
      return function (t) {
        return e.hasOwnProperty(t) ? e[t] : t.split(".").reduce(function (e, t) {
          return e.hasOwnProperty(t) ? e[t] : "";
        }, e);
      };
    }
    function i(e, t, n, i) {
      void 0 === i && (i = r(t));
      var u = i(e.name);
      return e.pipes.reduce(function (e, t) {
        return n.hasOwnProperty(t.name) ? n[t.name](e, t.parameters) : e;
      }, null == u ? "" : u);
    }
    function u(e, t, n, u) {
      return void 0 === u && (u = r(t)), 0 === e.literals.length ? "" : e.literals.slice(1).reduce(function (r, o, s) {
        return "" + r + i(e.variables[s], t, n, u) + o;
      }, e.literals[0]);
    }
    function o(e, t, n) {
      return u(s.parseStringTemplate(e), t, n);
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var s = n(0);
    t.evaluateParsedString = u, t.evaluateStringTemplate = o;
  }]);
});
