var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
var AppLoaderProgressHandler = /** @class */function () {
  function AppLoaderProgressHandler() {
    this.context = '';
    this.total = 0;
    this.totalItems = [];
    this.processedItems = new Set();
    this.downloadedItems = new Set();
    this.failedItems = new Set();
    this.remainingItems = new Set();
    this.processed = 0;
    this.remaining = 0;
    this.progress = 0;
  }
  AppLoaderProgressHandler.prototype.init = function (items) {
    this.total = items.length;
    this.totalItems = __spreadArray([], __read(items), false);
    this.remaining = items.length;
    this.remainingItems = new Set(items);
    return this.getProgress();
  };
  AppLoaderProgressHandler.prototype.update = function (item, success, context) {
    if (success) {
      this.downloadedItems.add(item);
    } else {
      this.failedItems.add(item);
    }
    this.currentItem = item;
    this.context = context;
    this.processed++;
    this.remaining--;
    this.progress = Math.round(this.processed * 100 / this.total);
    this.currentItem = item;
    this.processedItems.add(item);
    this.remainingItems.delete(item);
    return this.getProgress();
  };
  AppLoaderProgressHandler.prototype.getProgress = function () {
    var progress = {
      total: this.total,
      processed: this.processed,
      remaining: this.remaining,
      downloadedItems: Array.from(this.downloadedItems.values()),
      failedItems: Array.from(this.failedItems.values()),
      progress: this.progress,
      context: this.context,
      currentItem: this.currentItem,
      totalItems: this.totalItems,
      processedItems: Array.from(this.processedItems.values()),
      remainingItems: Array.from(this.remainingItems.values())
    };
    return progress;
  };
  return AppLoaderProgressHandler;
}();
export { AppLoaderProgressHandler };
