var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { DcuplApi } from './dcupl-api';
/**
 * This is the dcupl core class. It is used to initialize the dcupl library.
 * @param options The options to initialize the dcupl library.
 * @example
 * ```ts
 * const dcupl = new Dcupl();
 * const dcupl = new Dcupl({
 *  config: {
 *   projectId: 'xxx',
 *   apiKey: 'yyy',
 *  },
 * });
 *
 * ```
 */
var Dcupl = /** @class */function (_super) {
  __extends(Dcupl, _super);
  function Dcupl(options) {
    return _super.call(this, options) || this;
  }
  return Dcupl;
}(DcuplApi);
export { Dcupl };
