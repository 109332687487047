var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { trigger, computeSuggestions, pivot, IndicesController } from '@dcupl/common';
import { Filter } from './filter/filter';
import { ItemService } from './item/item';
var CatalogApi = /** @class */function () {
  function CatalogApi(dcuplApi, cdRef, indicesController) {
    var _this = this;
    this.dcuplApi = dcuplApi;
    this.cdRef = cdRef;
    this.indicesController = indicesController;
    this.query = {
      apply: function (query, options) {
        return _this.applyQuery(query, options);
      },
      applyOptions: function (itemOptions, options) {
        return _this.applyItemOptions(itemOptions, options);
      },
      remove: function (options) {
        return _this.removeQuery(options);
      },
      reset: function (options) {
        if (options === void 0) {
          options = {
            skipProcessing: false
          };
        }
        return _this.resetQuery(options);
      },
      execute: function (query) {
        return _this.executeQuery(query);
      },
      one: function (options) {
        return _this._getItem(options);
      },
      many: function (options) {
        return _this._getManyItems(options);
      },
      get: function () {
        return _this.getQuery();
      },
      has: function (options) {
        return _this.hasGroupOrQueryKey(options);
      }
    };
    this.filters = {
      get: function (options) {
        return _this.getFilter(options);
      },
      getAll: function (options) {
        return _this.getFilters(options);
      }
    };
    this.fn = {
      pivot: function (options) {
        return _this.getPivot(options);
      },
      aggregate: function (options) {
        return _this.getAggregate(options);
      },
      suggest: function (options) {
        return _this._getSuggestions(options);
      },
      groupBy: function (options) {
        return _this._getSections(options);
      },
      facets: function (options) {
        return _this.getFacets(options);
      },
      metadata: function () {
        return _this.getMetadata();
      }
    };
    this.itemService = new ItemService(this.dcuplApi['modelParser'], this.dcuplApi['scriptController']);
    this.filter = new Filter(this.dcuplApi['modelParser'], this.cdRef, this.itemService, this.indicesController, this.dcuplApi['queryManager']);
  }
  CatalogApi.prototype.initListeners = function () {
    this.initListMetadata();
  };
  CatalogApi.prototype.initListMetadata = function () {
    this.metadata = {
      key: this.model.key,
      currentSize: this.filter.filteredData.size,
      initialSize: this.filter.initialData.size,
      attributes: this.getModelAttributes(),
      views: this.getViews(),
      appliedQuery: this.filter.queryBuilder.getQuery()
    };
  };
  CatalogApi.prototype.getModelAttributes = function () {
    var attributes = __spreadArray(__spreadArray([], __read(this.model.references.values()), false), __read(this.model.properties.values()), false);
    return attributes;
  };
  CatalogApi.prototype.getViews = function () {
    var _this = this;
    var views = [];
    this.filter.modelParser.views.forEach(function (view) {
      if (view.model === _this.model.key) {
        views.push(view);
      }
    });
    return views;
  };
  CatalogApi.prototype.getMetadata = function () {
    return this._getMetadata();
  };
  CatalogApi.prototype._getMetadata = function () {
    this.initListMetadata();
    return this.metadata;
  };
  CatalogApi.prototype.getFilters = function (options) {
    return this._getFilters(options);
  };
  CatalogApi.prototype._getFilters = function (options) {
    var _this = this;
    var result = this.filter.getFilterItems(options);
    if (options === null || options === void 0 ? void 0 : options.filterKeys) {
      return options.filterKeys.map(function (key) {
        var filter = result.find(function (filter) {
          return filter.key === key;
        });
        if (!filter) {
          console.log("Filter ".concat(key, " not found in model ").concat(_this.model.key));
        }
        return filter;
      }).filter(Boolean);
    }
    return result;
  };
  CatalogApi.prototype.getFilter = function (options) {
    return this._getFilter(options);
  };
  CatalogApi.prototype._getFilter = function (options) {
    var result = this.filter.getFilterItem(options.filterKey, options);
    return result;
  };
  CatalogApi.prototype.getQuery = function () {
    return this.filter.queryBuilder.getQuery();
  };
  CatalogApi.prototype.hasGroupOrQueryKey = function (options) {
    return this.filter.queryBuilder.has(options);
  };
  CatalogApi.prototype.applyQuery = function (queryToApply, options) {
    var query = this.filter.queryBuilder.applyQuery(queryToApply, options);
    this.filter.updateFilteredData({
      skipProcessing: options === null || options === void 0 ? void 0 : options.skipProcessing
    });
    return query;
  };
  CatalogApi.prototype.applyItemOptions = function (itemOptions, options) {
    var query = this.filter.queryBuilder.applyOptions(itemOptions, options === null || options === void 0 ? void 0 : options.mode);
    this.filter.updateFilteredData({
      skipProcessing: options === null || options === void 0 ? void 0 : options.skipProcessing
    });
    return query;
  };
  CatalogApi.prototype.removeQuery = function (queryToRemove, options) {
    var query;
    if (queryToRemove) {
      query = this.filter.queryBuilder.removeQuery(queryToRemove);
    } else {
      query = this.filter.queryBuilder.removeAllQueries();
    }
    this.filter.updateFilteredData({
      skipProcessing: options === null || options === void 0 ? void 0 : options.skipProcessing
    });
    return query;
  };
  CatalogApi.prototype.resetQuery = function (options) {
    var query = this.filter.queryBuilder.reset();
    this.filter.updateFilteredData({
      skipProcessing: options === null || options === void 0 ? void 0 : options.skipProcessing
    });
    return query;
  };
  CatalogApi.prototype.executeQuery = function (options) {
    var cacheContext = {
      options: options,
      query: this.filter.queryBuilder.getQuery()
    };
    var hasCache = this.filter.cacheCtrl.has('query', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('query', cacheContext);
    }
    if (!options) {
      options = this.filter.queryBuilder.getQuery();
    }
    var fullOptions = Object.assign({}, options, {
      modelKey: this.model.key
    });
    var queriedData = this.dcuplApi['queryManager'].queryData(this.filter.filteredData, fullOptions, false, this.filter.indicesController['indexMap']);
    var items = this.itemService.getItems(queriedData, this.model, options);
    this.filter.cacheCtrl.set('query', cacheContext, items);
    return items;
  };
  CatalogApi.prototype._getSections = function (options) {
    var cacheContext = {
      options: options,
      query: this.filter.queryBuilder.getQuery()
    };
    var hasCache = this.filter.cacheCtrl.has('sections', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('sections', cacheContext);
    }
    var response = this.filter.getSections(options);
    this.filter.cacheCtrl.set('sections', cacheContext, response);
    return response;
  };
  CatalogApi.prototype._getItem = function (options) {
    var item = this.itemService.getItem(options.itemKey, this.filter.filteredData, this.model, options);
    return item;
  };
  CatalogApi.prototype._getManyItems = function (options) {
    var items = this.itemService.getManyItems(options.itemKeys, this.filter.filteredData, this.model, options);
    return items;
  };
  CatalogApi.prototype._getSuggestions = function (options) {
    if (typeof options.excludeUndefineds === 'undefined') {
      options.excludeUndefineds = true;
    }
    if (typeof options.excludeNulls === 'undefined') {
      options.excludeNulls = true;
    }
    var cacheContext = {
      options: options,
      query: this.filter.queryBuilder.getQuery()
    };
    var hasCache = this.filter.cacheCtrl.has('suggestions', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('suggestions', cacheContext);
    }
    var attribute = this.model.getAttribute(options.attribute);
    if (!attribute) {
      throw new Error("Attribute ".concat(options.attribute, " does not exist"));
    }
    if (typeof options.value === 'string' && options.value.startsWith('/') && options.value.endsWith('/')) {
      try {
        new RegExp(options.value.slice(1, -1));
      } catch (err) {
        return [];
      }
    }
    var relevantData;
    if (options.relevantData !== 'all') {
      relevantData = this.filter.filteredData;
    } else {
      relevantData = this.filter.initialData;
    }
    var indexMap = this.filter.indicesController.getOrCreateIndex(options.attribute, this.filter.initialData);
    var suggestions = computeSuggestions(relevantData, indexMap, options, this.dcuplApi['queryManager']);
    this.filter.cacheCtrl.set('suggestions', cacheContext, suggestions);
    return suggestions;
  };
  CatalogApi.prototype.getFacets = function (options) {
    return this._getFacets(options);
  };
  CatalogApi.prototype._getFacets = function (options) {
    var cacheContext = {
      options: options,
      query: this.filter.queryBuilder.getQuery()
    };
    var hasCache = this.filter.cacheCtrl.has('facets', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('facets', cacheContext);
    }
    var reference = this.model.references.get(options.attribute);
    var remoteModel;
    if (reference) {
      remoteModel = this.filter.modelParser.models.get(reference.model);
    }
    if (typeof options.excludeZeros === 'undefined') {
      options.excludeZeros = true;
    }
    if (typeof options.excludeUndefineds === 'undefined') {
      options.excludeUndefineds = true;
    }
    if (typeof options.excludeUnresolved === 'undefined') {
      options.excludeUnresolved = true;
    }
    if (typeof options.calculateResults === 'undefined') {
      options.calculateResults = true;
    }
    var entries = this.filter.getFacets(options.attribute, remoteModel, {
      calculateFacets: options.calculateResults,
      count: options.count,
      excludeZeros: options.excludeZeros,
      excludeUndefineds: options.excludeUndefineds,
      excludeUnresolved: options.excludeUnresolved,
      skipProcessing: false
    });
    this.filter.cacheCtrl.set('facets', cacheContext, entries);
    return entries;
  };
  CatalogApi.prototype.getAggregate = function (options) {
    var cacheContext = {
      options: options,
      query: this.filter.queryBuilder.getQuery()
    };
    var hasCache = this.filter.cacheCtrl.has('aggregation', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('aggregation', cacheContext);
    }
    var aggregate = this.filter.getAggregate(options);
    this.filter.cacheCtrl.set('aggregation', cacheContext, aggregate);
    return aggregate;
  };
  CatalogApi.prototype.getPivot = function (options) {
    if (!options || !Array.isArray(options.columns) || !Array.isArray(options.rows) || !options.values || !options.values.length) {
      console.log("Invalid pivot options");
      return {
        key: 'invalid_options'
      };
    }
    var cacheContext = {
      query: this.filter.queryBuilder.getQuery(),
      options: options
    };
    var cachedValue = this.filter.cacheCtrl.get('pivot', cacheContext);
    if (cachedValue) {
      return cachedValue;
    }
    var indicesCtrl = new IndicesController();
    if (this.filter.filteredData.size === this.filter.initialData.size) {
      indicesCtrl = this.filter.indicesController;
    }
    var result = pivot(this.filter.filteredData, options, {
      key: 'root'
    }, 'root', indicesCtrl);
    this.filter.cacheCtrl.set('pivot', cacheContext, result);
    return result;
  };
  var _a;
  __decorate([trigger({
    scope: 'list',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", Object)], CatalogApi.prototype, "_getMetadata", null);
  __decorate([trigger({
    scope: 'list',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], CatalogApi.prototype, "_getFilters", null);
  __decorate([trigger({
    scope: 'list',
    name: 'catalog:getFilter',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], CatalogApi.prototype, "_getFilter", null);
  __decorate([trigger({
    scope: 'list',
    name: 'catalog:execute_query',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], CatalogApi.prototype, "executeQuery", null);
  __decorate([trigger({
    scope: 'list',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], CatalogApi.prototype, "_getSections", null);
  __decorate([trigger({
    scope: 'list',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], CatalogApi.prototype, "_getItem", null);
  __decorate([trigger({
    scope: 'list',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], CatalogApi.prototype, "_getManyItems", null);
  __decorate([trigger({
    scope: 'list',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], CatalogApi.prototype, "_getSuggestions", null);
  __decorate([trigger({
    scope: 'list',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], CatalogApi.prototype, "_getFacets", null);
  __decorate([trigger({
    scope: 'list',
    cloneResult: false
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], CatalogApi.prototype, "getPivot", null);
  return CatalogApi;
}();
export { CatalogApi };
