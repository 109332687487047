var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { isValid, parse } from 'date-fns';
import { evaluateStringTemplate } from 'string-template-parser';
import { IndicesController, getPropertiesForData, generateKey } from '@dcupl/common';
import { isValidReference, isValidProperty } from './model.helper';
export var EntryMetadataValues = {
  REF_VALUE: '_dcupl_ref_'
};
var DcuplModel = /** @class */function () {
  function DcuplModel(modelParser) {
    this.modelParser = modelParser;
    this.properties = new Map();
    this.references = new Map();
    this.data = new Map();
    this.supportsAutoCreation = false;
    this.autoGenerateKey = false;
    this.autoGenerateProperties = false;
    this.keyProperty = 'key';
    this.valueMappings = [];
    this.errors = [];
    this.indicesController = new IndicesController();
    this.cdRef = modelParser.cdRef;
  }
  DcuplModel.prototype.init = function (modelDefinition) {
    this.key = modelDefinition.key;
    this.initialDefinition = modelDefinition;
    this.applyPropertiesAndReferences(modelDefinition);
    this.valueMappings = modelDefinition.valueMappings || [];
    this.meta = modelDefinition.meta;
  };
  DcuplModel.prototype.getModelDefinition = function () {
    if (this.autoGenerateProperties) {
      var modelDef = {
        key: this.key,
        keyProperty: this.keyProperty,
        references: Array.from(this.references.values()),
        properties: Array.from(this.properties.values()),
        autoGenerateKey: this.autoGenerateKey,
        supportsAutoCreation: this.supportsAutoCreation,
        valueMappings: this.valueMappings,
        meta: this.meta,
        autoGenerateProperties: this.autoGenerateProperties
      };
      return modelDef;
    } else {
      return this.initialDefinition;
    }
  };
  DcuplModel.prototype.applyPropertiesAndReferences = function (modelDefinition) {
    var _this = this;
    var _a, _b;
    (_a = modelDefinition.properties) === null || _a === void 0 ? void 0 : _a.map(function (prop) {
      var error = isValidProperty(prop);
      if (error) {
        error.model = _this.key;
        _this.setError(error);
      } else {
        _this.properties.set(prop.key, prop);
      }
    });
    (_b = modelDefinition.references) === null || _b === void 0 ? void 0 : _b.map(function (reference) {
      var error = isValidReference(reference);
      if (error) {
        error.model = _this.key;
        _this.setError(error);
      } else {
        reference.validity = reference.validity || 'loose';
        _this.references.set(reference.key, reference);
      }
    });
    this.supportsAutoCreation = !!modelDefinition.supportsAutoCreation;
    this.keyProperty = modelDefinition.keyProperty || 'key';
    this.autoGenerateKey = !!modelDefinition.autoGenerateKey;
    this.autoGenerateProperties = !!modelDefinition.autoGenerateProperties;
  };
  DcuplModel.prototype.generatePropertiesFromContainer = function (container) {
    var _this = this;
    var properties = getPropertiesForData(container.data, false);
    properties.map(function (property) {
      if (!_this.properties.has(property.key)) {
        if (property.key === 'key' && (container.keyProperty === 'key' || !container.keyProperty)) {
          return;
        }
        property.filter = true;
        _this.properties.set(property.key, property);
      }
    });
  };
  DcuplModel.prototype.getReferenceKeys = function (referenceValue) {
    return referenceValue.map(function (value) {
      if (typeof value === 'string') {
        return String(value.trim());
      } else if (typeof value === 'number') {
        return String(value);
      } else if (value === null) {
        return 'null';
      } else if (typeof value === 'object' && !!value && value.key) {
        return value.key;
      }
      return undefined;
    });
  };
  DcuplModel.prototype.setError = function (error) {
    var _a;
    if ((_a = this.modelParser.initOptions.errorTracking) === null || _a === void 0 ? void 0 : _a.enabled) {
      error.model = this.key;
      this.errors.push(error);
    }
  };
  DcuplModel.prototype.processBasicProperty = function (property, rawDataEntry, dataEntry) {
    var _this = this;
    var rawValue = rawDataEntry[property.key];
    var value;
    if (typeof rawValue === 'undefined') {
      this.setError({
        errorGroup: 'PropertyDataError',
        errorType: 'UndefinedValue',
        itemKey: rawDataEntry.key,
        attribute: property.key
      });
      return;
    } else {
      if (rawValue === null) {
        value = rawValue;
      } else if (property.type === 'string') {
        value = String(rawValue);
      } else if (property.type === 'int') {
        value = this.processIntProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'float') {
        value = this.processFloatProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'date') {
        value = this.processDateProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'json') {
        value = this.processJSONProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'boolean') {
        value = this.processBooleanProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'any') {
        value = rawValue;
      } else if (property.type === 'Array<string>') {
        if (Array.isArray(rawValue)) {
          value = [];
          rawValue.forEach(function (val) {
            value.push(String(val));
          });
        }
      } else if (property.type === 'Array<int>') {
        if (Array.isArray(rawValue)) {
          value = [];
          rawValue.forEach(function (val) {
            var intValue = _this.processIntProperty(property, val, rawDataEntry);
            if (typeof intValue !== 'undefined') {
              value.push(intValue);
            }
          });
        }
      } else if (property.type === 'Array<float>') {
        if (Array.isArray(rawValue)) {
          value = [];
          rawValue.forEach(function (val) {
            var floatValue = _this.processFloatProperty(property, val, rawDataEntry);
            if (typeof floatValue !== 'undefined') {
              value.push(floatValue);
            }
          });
        }
      } else if (property.type === 'Array<date>') {
        if (Array.isArray(rawValue)) {
          value = [];
          rawValue.forEach(function (val) {
            var dateValue = _this.processDateProperty(property, val, rawDataEntry);
            if (typeof dateValue !== 'undefined') {
              value.push(dateValue);
            }
          });
        }
      }
      dataEntry[property.key] = value;
    }
    if (property.index) {
      this.indicesController.fillInverseIndex(property.key, dataEntry.key, [value]);
      this.indicesController.fillDataMap(property.key, [value]);
    }
  };
  DcuplModel.prototype.processIntProperty = function (property, rawValue, rawDataEntry) {
    var value = parseInt(rawValue, 10);
    if (isNaN(value)) {
      this.setError({
        errorGroup: 'PropertyDataError',
        errorType: 'WrongDataType',
        itemKey: rawDataEntry.key,
        attribute: property.key,
        meta: {
          expected: property.type,
          rawValue: rawValue
        }
      });
      return;
    }
    return value;
  };
  DcuplModel.prototype.processJSONProperty = function (property, rawValue, rawDataEntry) {
    if (typeof rawValue !== 'object') {
      this.setError({
        errorGroup: 'PropertyDataError',
        errorType: 'WrongDataType',
        itemKey: rawDataEntry.key,
        meta: {
          expected: property.type,
          rawValue: rawValue
        }
      });
      return;
    }
    return rawValue;
  };
  DcuplModel.prototype.processBooleanProperty = function (property, rawValue, rawDataEntry) {
    if (rawValue !== true && rawValue !== false) {
      this.setError({
        errorGroup: 'PropertyDataError',
        errorType: 'WrongDataType',
        itemKey: rawDataEntry.key,
        attribute: property.key,
        meta: {
          expected: property.type,
          rawValue: rawValue
        }
      });
      return;
    }
    return rawValue;
  };
  DcuplModel.prototype.processDateProperty = function (property, rawValue, rawDataEntry) {
    if (rawValue === '') {
      this.setError({
        errorGroup: 'PropertyDataError',
        errorType: 'WrongDataType',
        itemKey: rawDataEntry.key,
        meta: {
          expected: property.type,
          rawValue: rawValue
        }
      });
      return;
    }
    var inputFormat = property.inputFormat;
    var date;
    try {
      if (inputFormat) {
        date = parse(rawValue, inputFormat, new Date());
      } else {
        date = new Date(rawValue);
      }
    } catch (err) {
      this.setError({
        errorGroup: 'PropertyDataError',
        errorType: 'WrongDataType',
        itemKey: rawDataEntry.key,
        meta: {
          expected: property.type,
          rawValue: rawValue
        }
      });
      return;
    }
    if (!isValid(date)) {
      return;
    }
    if (property.UTC) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    }
    return date;
  };
  DcuplModel.prototype.processFloatProperty = function (property, rawValue, rawDataEntry) {
    var value;
    var fractionDigits = property.fractionDigits;
    if (typeof fractionDigits === 'undefined') {
      value = parseFloat(rawValue);
    } else {
      value = parseFloat(parseFloat(rawValue).toFixed(fractionDigits));
    }
    if (isNaN(value)) {
      this.setError({
        errorGroup: 'PropertyDataError',
        errorType: 'WrongDataType',
        itemKey: rawDataEntry.key,
        meta: {
          expected: property.type,
          rawValue: rawValue
        }
      });
      return;
    }
    return value;
  };
  DcuplModel.prototype.processSingleValuedReference = function (reference, referenceValue, dataEntry) {
    var remoteModel = this.modelParser.models.get(reference.model);
    if (!remoteModel) {
      this.setError({
        errorGroup: 'ReferenceModelError',
        errorType: 'MissingModel',
        itemKey: dataEntry.key,
        attribute: reference.key,
        description: "The reference \"".concat(reference.key, "\" in the \"").concat(this.key, "\" model can not find the remote model \"").concat(reference.model, "\"")
      });
      return;
    }
    var remoteDataEntry = remoteModel.getEntry(referenceValue);
    // fill undefined indexMap for for single and multi valued
    if (!remoteDataEntry) {
      if (remoteModel.supportsAutoCreation) {
        var remoteValue = this.handleAutoCreationAndReturnKey(remoteModel, referenceValue);
        this.indicesController.fillInverseIndex(reference.key, dataEntry.key, [referenceValue]);
        dataEntry[reference.key] = remoteValue;
        this.setRefValue(remoteValue, 'auto_hit');
        return;
      } else {
        this.indicesController.fillInverseIndex(reference.key, dataEntry.key, [referenceValue]);
        this.setError({
          errorGroup: 'ReferenceDataError',
          errorType: 'RemoteReferenceKeyNotFound',
          itemKey: dataEntry.key,
          attribute: reference.key,
          description: "The reference \"".concat(reference.key, "\" in the \"").concat(this.key, "\" model can not find the value \"").concat(referenceValue, "\" in the remote model \"").concat(reference.model, "\""),
          meta: {
            remoteModel: reference.model,
            remoteKey: referenceValue
          }
        });
        if (reference.validity === 'strict') {
          return;
        }
      }
    }
    this.indicesController.fillInverseIndex(reference.key, dataEntry.key, [referenceValue]);
    var item = {
      key: String(referenceValue)
    };
    dataEntry[reference.key] = item;
    if (!remoteDataEntry && !remoteModel.supportsAutoCreation && reference.validity !== 'strict') {
      this.setRefValue(item, 'miss');
    } else {
      this.setRefValue(item, 'hit');
    }
  };
  DcuplModel.prototype.processMultiValuedReference = function (reference, referenceValue, dataEntry) {
    var _this = this;
    var remoteModel = this.modelParser.models.get(reference.model);
    if (!remoteModel) {
      this.setError({
        errorGroup: 'ReferenceModelError',
        errorType: 'MissingModel',
        itemKey: dataEntry.key,
        attribute: reference.key,
        description: "The reference \"".concat(reference.key, "\" in the \"").concat(this.key, "\" model can not find the remote model \"").concat(reference.model, "\"")
      });
      return;
    }
    var referenceKeys = this.getReferenceKeys(referenceValue);
    var validReferenceKeys = [];
    referenceKeys.forEach(function (referenceKey, index) {
      var remoteDataEntry = remoteModel.getEntry(referenceKey);
      if (!remoteDataEntry) {
        if (remoteModel.supportsAutoCreation) {
          var remoteKey = _this.handleAutoCreationAndReturnKey(remoteModel, referenceValue[index]);
          _this.setRefValue(remoteKey, 'auto_hit');
          validReferenceKeys.push(remoteKey);
          return;
        } else {
          _this.setError({
            errorGroup: 'ReferenceDataError',
            errorType: 'RemoteReferenceKeyNotFound',
            itemKey: dataEntry.key,
            attribute: reference.key,
            description: "The reference \"".concat(reference.key, "\" in the \"").concat(_this.key, "\" model can not find the value \"").concat(referenceKey, "\" in the remote model \"").concat(reference.model, "\""),
            meta: {
              remoteModel: reference.model,
              remoteKey: referenceKey
            }
          });
          if (reference.validity === 'strict') {
            return;
          }
        }
      }
      var item = {
        key: referenceKey
      };
      if (!remoteDataEntry && !remoteModel.supportsAutoCreation && reference.validity !== 'strict') {
        _this.setRefValue(item, 'miss');
      } else {
        _this.setRefValue(item, 'hit');
      }
      validReferenceKeys.push(item);
    });
    this.indicesController.fillInverseIndex(reference.key, dataEntry.key, validReferenceKeys.map(function (val) {
      return val.key;
    }));
    dataEntry[reference.key] = validReferenceKeys;
  };
  DcuplModel.prototype.getViewKeys = function () {
    return this.modelParser['_getViewKeys']({
      modelKey: this.key
    });
  };
  // @trigger({ scope: 'global', name: 'model:addBasicPropertiesAndReferences', mergeAnalytics: true })
  DcuplModel.prototype.addBasicPropertiesAndReferences = function (container) {
    var e_1, _a, e_2, _b, e_3, _c;
    if (!Array.isArray(container.data)) {
      this.setError({
        errorGroup: 'DataContainerError',
        errorType: 'WrongDataType',
        itemKey: container.model,
        model: container.model
      });
      return;
    }
    var properties = this.getBasicProperties();
    var references = this.getBasicReferences();
    try {
      for (var _d = __values(container.data), _e = _d.next(); !_e.done; _e = _d.next()) {
        var rawDataEntry = _e.value;
        var dataEntry = this.data.get(String(rawDataEntry.key));
        if (!dataEntry) {
          dataEntry = {
            key: String(rawDataEntry.key)
          };
        } else {
          if (container.type === 'set') {
            this.setError({
              errorGroup: 'DataContainerError',
              errorType: 'NonUniqueKey',
              itemKey: rawDataEntry.key,
              model: container.model
            });
          }
        }
        var _loop_1 = function (property) {
          if (property.origin) {
            rawDataEntry[property.key] = rawDataEntry[property.origin];
          }
          var valueMapping = this_1.valueMappings.find(function (map) {
            return map.attributes.includes(property.key);
          });
          if (this_1.isValidValueMapping(valueMapping)) {
            rawDataEntry = this_1.mapRawValueUsingValueMapping(rawDataEntry, property.key, valueMapping);
          }
          this_1.processBasicProperty(property, rawDataEntry, dataEntry);
        };
        var this_1 = this;
        try {
          for (var properties_1 = (e_2 = void 0, __values(properties)), properties_1_1 = properties_1.next(); !properties_1_1.done; properties_1_1 = properties_1.next()) {
            var property = properties_1_1.value;
            _loop_1(property);
          }
        } catch (e_2_1) {
          e_2 = {
            error: e_2_1
          };
        } finally {
          try {
            if (properties_1_1 && !properties_1_1.done && (_b = properties_1.return)) _b.call(properties_1);
          } finally {
            if (e_2) throw e_2.error;
          }
        }
        var _loop_2 = function (reference) {
          if (reference.origin) {
            rawDataEntry[reference.key] = rawDataEntry[reference.origin];
          }
          var valueMapping = this_2.valueMappings.find(function (map) {
            return map.attributes.includes(reference.key);
          });
          if (this_2.isValidValueMapping(valueMapping)) {
            rawDataEntry = this_2.mapRawValueUsingValueMapping(rawDataEntry, reference.key, valueMapping);
          }
          var referenceValue = rawDataEntry[reference.key];
          if (typeof referenceValue === 'number') {
            referenceValue = String(referenceValue);
          }
          if (!referenceValue) {
            this_2.indicesController.fillInverseIndex(reference.key, dataEntry.key, [undefined]);
            this_2.setError({
              errorGroup: 'ReferenceDataError',
              errorType: 'UndefinedValue',
              itemKey: rawDataEntry.key,
              attribute: reference.key
            });
            return "continue";
          }
          if (reference.type === 'singleValued') {
            if ((typeof referenceValue === 'object' || typeof referenceValue === 'string') && !Array.isArray(referenceValue)) {
              this_2.processSingleValuedReference(reference, referenceValue, dataEntry);
            } else {
              this_2.setError({
                errorGroup: 'ReferenceDataError',
                errorType: 'WrongDataType',
                itemKey: rawDataEntry.key,
                attribute: reference.key,
                meta: {
                  value: referenceValue
                }
              });
            }
          } else if (reference.type === 'multiValued') {
            if (Array.isArray(referenceValue)) {
              this_2.processMultiValuedReference(reference, referenceValue, dataEntry);
            } else {
              this_2.setError({
                errorGroup: 'ReferenceDataError',
                errorType: 'WrongDataType',
                itemKey: rawDataEntry.key,
                attribute: reference.key,
                meta: {
                  value: referenceValue
                }
              });
            }
          }
        };
        var this_2 = this;
        try {
          for (var references_1 = (e_3 = void 0, __values(references)), references_1_1 = references_1.next(); !references_1_1.done; references_1_1 = references_1.next()) {
            var reference = references_1_1.value;
            _loop_2(reference);
          }
        } catch (e_3_1) {
          e_3 = {
            error: e_3_1
          };
        } finally {
          try {
            if (references_1_1 && !references_1_1.done && (_c = references_1.return)) _c.call(references_1);
          } finally {
            if (e_3) throw e_3.error;
          }
        }
        this.data.set(dataEntry.key, dataEntry);
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
  };
  DcuplModel.prototype.isValidValueMapping = function (valueMapping) {
    if (!valueMapping || !Array.isArray(valueMapping.attributes) || !Array.isArray(valueMapping.values)) {
      return false;
    }
    var invalidFromValues = valueMapping.values.find(function (value) {
      return !Array.isArray(value.from);
    });
    var invalidToValues = valueMapping.values.find(function (value) {
      return !value.to;
    });
    if (invalidFromValues || invalidToValues) {
      return false;
    }
    return true;
  };
  DcuplModel.prototype.mapRawValueUsingValueMapping = function (rawEntry, attribute, valueMappingConfig) {
    var e_4, _a;
    try {
      for (var _b = __values(valueMappingConfig.values), _c = _b.next(); !_c.done; _c = _b.next()) {
        var mappingValue = _c.value;
        if (mappingValue.from.includes('$dcupl_falsy')) {
          if (typeof rawEntry[attribute] === 'undefined' || rawEntry[attribute] === null || rawEntry[attribute] === '') {
            rawEntry[attribute] = mappingValue.to;
            continue;
          }
          continue;
        }
        if (mappingValue.from.includes(rawEntry[attribute])) {
          rawEntry[attribute] = mappingValue.to;
          continue;
        }
      }
    } catch (e_4_1) {
      e_4 = {
        error: e_4_1
      };
    } finally {
      try {
        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
      } finally {
        if (e_4) throw e_4.error;
      }
    }
    return rawEntry;
  };
  DcuplModel.prototype.handleAutoCreationAndReturnKey = function (remoteModel, referenceValue) {
    if (Array.isArray(referenceValue)) {
      return [];
    }
    if (referenceValue === null) {
      return {
        key: 'null'
      };
    }
    if (typeof referenceValue === 'object') {
      var keyProperty = remoteModel.keyProperty;
      var key = remoteModel.autoGenerateKey ? generateKey() : String(referenceValue[keyProperty]);
      remoteModel.data.set(key, {
        key: key
      });
      referenceValue.key = key;
      var container = {
        model: remoteModel.key,
        data: [referenceValue]
      };
      remoteModel.addContainer(container);
      return {
        key: key
      };
    }
    if (typeof referenceValue === 'string') {
      remoteModel.data.set(referenceValue, {
        key: referenceValue
      });
      var container = {
        model: remoteModel.key,
        data: [{
          key: referenceValue
        }]
      };
      remoteModel.addContainer(container);
    }
    return {
      key: String(referenceValue)
    };
  };
  DcuplModel.prototype.addContainer = function (container) {
    this.cdRef.analyticsController.mark({
      name: 'model:autogenerate_data:start',
      context: container.model
    });
    this.modelParser.handleAlternativeKey([container]);
    this.addBasicPropertiesAndReferences(container);
    this.addResolvedReferences(container);
    this.addDerivedReferencesGroupedReferencesDerivedPropertiesAndExpressionProperties(container);
    this.cdRef.analyticsController.mark({
      name: 'model:autogenerate_data:end',
      context: container.model
    });
    this.cdRef.analyticsController.measure({
      name: 'model:autogenerate_data',
      start: 'model:autogenerate_data:start',
      end: 'model:autogenerate_data:end',
      sum: true,
      context: container.model,
      detail: {
        model: container.model,
        origin: this.key
      }
    });
  };
  // @trigger({ scope: 'global', name: 'model:addResolvedReferences', mergeAnalytics: true })
  DcuplModel.prototype.addResolvedReferences = function (container) {
    var e_5, _a, e_6, _b;
    var _this = this;
    var _c;
    if (!Array.isArray(container.data)) {
      this.setError({
        errorGroup: 'DataContainerError',
        errorType: 'WrongDataType',
        itemKey: container.model,
        model: container.model
      });
      return;
    }
    var references = this.getResolvedReferences();
    var resolvedReferencesHelper = references.map(function (reference) {
      var remoteModel = _this.modelParser.models.get(reference.resolve.model);
      if (!remoteModel) {
        return;
      }
      var remoteData = remoteModel.getDataAsArray();
      var inverseKeys = remoteModel.indicesController.getIndex(reference.resolve.reference);
      var obj = {
        reference: reference,
        model: remoteModel,
        remoteData: remoteData,
        inverseKeys: inverseKeys
      };
      return obj;
    }).filter(function (entry) {
      return !!entry;
    });
    try {
      for (var _d = __values(container.data), _e = _d.next(); !_e.done; _e = _d.next()) {
        var rawDataEntry = _e.value;
        var localDataEntryKey = String(rawDataEntry.key);
        var dataEntry = this.data.get(localDataEntryKey);
        try {
          for (var resolvedReferencesHelper_1 = (e_6 = void 0, __values(resolvedReferencesHelper)), resolvedReferencesHelper_1_1 = resolvedReferencesHelper_1.next(); !resolvedReferencesHelper_1_1.done; resolvedReferencesHelper_1_1 = resolvedReferencesHelper_1.next()) {
            var object = resolvedReferencesHelper_1_1.value;
            var localReferenceKey = object.reference.key;
            var value = void 0;
            if (object.inverseKeys) {
              if (object.reference.type === 'multiValued') {
                var v = object.inverseKeys.get(dataEntry.key) || [];
                value = v.map(function (key) {
                  return {
                    key: key
                  };
                });
              } else {
                var v = ((_c = object.inverseKeys.get(dataEntry.key)) === null || _c === void 0 ? void 0 : _c.at(0)) || null;
                if (v) {
                  value = {
                    key: v
                  };
                } else {
                  value = null;
                }
              }
              dataEntry[localReferenceKey] = value;
              this.indicesController.fillInverseIndex(localReferenceKey, localDataEntryKey, dataEntry[localReferenceKey]);
            } else {}
          }
        } catch (e_6_1) {
          e_6 = {
            error: e_6_1
          };
        } finally {
          try {
            if (resolvedReferencesHelper_1_1 && !resolvedReferencesHelper_1_1.done && (_b = resolvedReferencesHelper_1.return)) _b.call(resolvedReferencesHelper_1);
          } finally {
            if (e_6) throw e_6.error;
          }
        }
        this.data.set(dataEntry.key, dataEntry);
      }
    } catch (e_5_1) {
      e_5 = {
        error: e_5_1
      };
    } finally {
      try {
        if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
      } finally {
        if (e_5) throw e_5.error;
      }
    }
  };
  // @trigger({
  //   scope: 'global',
  //   name: 'model:addDerivedReferencesGroupedReferencesDerivedPropertiesAndExpressionProperties',
  //   mergeAnalytics: true,
  // })
  DcuplModel.prototype.addDerivedReferencesGroupedReferencesDerivedPropertiesAndExpressionProperties = function (container) {
    var e_7, _a;
    var _this = this;
    if (!Array.isArray(container.data)) {
      this.setError({
        errorGroup: 'DataContainerError',
        errorType: 'WrongDataType',
        itemKey: container.model,
        model: container.model
      });
      return;
    }
    var derivedProperties = this.getDerivedPropertiesForBasicAndDerivedReferences();
    var expressionProperties = this.getExpressionProperties();
    var derivedReferences = this.getDerivedReferences();
    var groupedReferences = this.getGroupedReferences();
    var _loop_3 = function (rawDataEntry) {
      var dataEntry = this_3.data.get(String(rawDataEntry.key));
      /**
       * 1) Derived References
       */
      derivedReferences.forEach(function (reference) {
        _this.handleDerivedReferences(reference, dataEntry);
      });
      /**
       * 2) Grouped References
       */
      groupedReferences.forEach(function (reference) {
        _this.handleGroupedReferences(reference, dataEntry);
      });
      /**
       * 3) Derived Properties
       */
      derivedProperties.forEach(function (prop) {
        _this.handleDerivedProperties(prop, dataEntry);
      });
      /**
       * 4) Expression Properties
       */
      expressionProperties.forEach(function (prop) {
        var value = evaluateStringTemplate(prop.expression, __assign({}, dataEntry), {});
        dataEntry[prop.key] = value;
      });
      this_3.data.set(dataEntry.key, dataEntry);
    };
    var this_3 = this;
    try {
      for (var _b = __values(container.data), _c = _b.next(); !_c.done; _c = _b.next()) {
        var rawDataEntry = _c.value;
        _loop_3(rawDataEntry);
      }
    } catch (e_7_1) {
      e_7 = {
        error: e_7_1
      };
    } finally {
      try {
        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
      } finally {
        if (e_7) throw e_7.error;
      }
    }
  };
  DcuplModel.prototype.handleDerivedProperties = function (prop, dataEntry) {
    var reference = this.references.get(prop.derive.localReference);
    if (reference) {
      var remoteModel = this.modelParser.models.get(reference.model);
      if (remoteModel) {
        if (reference.type === 'singleValued') {
          this.handleSingleValuedDerivedProperties(remoteModel, dataEntry, reference, prop);
        } else if (reference.type === 'multiValued') {
          this.handleMultiValuedDerivedProperties(remoteModel, dataEntry, reference, prop);
        }
      }
    }
  };
  DcuplModel.prototype.reRunDerivedPropertiesBasedOnResolves = function (container) {
    var e_8, _a;
    var _this = this;
    if (!Array.isArray(container.data)) {
      this.setError({
        errorGroup: 'DataContainerError',
        errorType: 'WrongDataType',
        itemKey: container.model,
        model: container.model
      });
      return;
    }
    var derivedProperties = this.getDerivedProperties();
    var derivedReferences = this.getDerivedReferences();
    var _loop_4 = function (rawDataEntry) {
      var dataEntry = this_4.data.get(String(rawDataEntry.key));
      derivedReferences.forEach(function (reference) {
        _this.handleDerivedReferences(reference, dataEntry);
      });
      derivedProperties.forEach(function (prop) {
        var reference = _this.references.get(prop.derive.localReference);
        if (reference) {
          var remoteModel = _this.modelParser.models.get(reference.model);
          if (remoteModel) {
            if (reference.type === 'singleValued') {
              _this.handleSingleValuedDerivedProperties(remoteModel, dataEntry, reference, prop);
            } else if (reference.type === 'multiValued') {
              _this.handleMultiValuedDerivedProperties(remoteModel, dataEntry, reference, prop);
            }
          }
        }
      });
      this_4.data.set(dataEntry.key, dataEntry);
    };
    var this_4 = this;
    try {
      for (var _b = __values(container.data), _c = _b.next(); !_c.done; _c = _b.next()) {
        var rawDataEntry = _c.value;
        _loop_4(rawDataEntry);
      }
    } catch (e_8_1) {
      e_8 = {
        error: e_8_1
      };
    } finally {
      try {
        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
      } finally {
        if (e_8) throw e_8.error;
      }
    }
  };
  DcuplModel.prototype.handleSingleValuedDerivedProperties = function (remoteModel, dataEntry, reference, property) {
    var localReferenceValue = dataEntry[reference.key];
    if (!localReferenceValue) {
      return;
    }
    var remoteEntry = remoteModel === null || remoteModel === void 0 ? void 0 : remoteModel.getData().get(localReferenceValue.key);
    if (remoteEntry) {
      var remoteValue = remoteEntry[property.derive.remoteProperty];
      if (typeof remoteValue !== 'undefined') {
        dataEntry[property.key] = remoteValue;
      }
      if (property.index) {
        this.indicesController.fillInverseIndex(property.key, dataEntry.key, [remoteValue]);
        // this.indicesController.fillDataMap(property.key, [remoteValue]);
      }
    }
  };
  DcuplModel.prototype.handleMultiValuedDerivedProperties = function (remoteModel, dataEntry, reference, prop) {
    var entryValue = dataEntry[reference.key];
    var entries;
    if (!entryValue) {
      entries = [];
    } else if (Array.isArray(entryValue)) {
      entries = __spreadArray([], __read(entryValue), false);
    } else if (entryValue && typeof entryValue.key === 'string') {
      entries = [{
        key: entryValue.key
      }];
    } else {
      entries = [];
    }
    var separator = prop.derive.separator || ', ';
    var remoteValues = entries.map(function (item) {
      var remoteEntry = remoteModel === null || remoteModel === void 0 ? void 0 : remoteModel.getData().get(item.key);
      if (remoteEntry) {
        return remoteEntry[prop.derive.remoteProperty];
      }
    }).filter(Boolean);
    var value;
    if (prop.type.includes('Array<')) {
      value = remoteValues;
    } else if (prop.type === 'string' || prop.type === 'int' || prop.type === 'float' || prop.type === 'date') {
      value = remoteValues.join(separator);
    } else if (prop.type === 'json') {
      value = remoteValues;
    } else {
      value = remoteValues;
    }
    dataEntry[prop.key] = value;
  };
  DcuplModel.prototype.handleDerivedReferences = function (reference, dataEntry) {
    var localReferenceToDeriveFrom = this.references.get(reference.derive.localReference);
    if (!localReferenceToDeriveFrom) return;
    var remoteModelToDeriveFrom = this.modelParser.models.get(localReferenceToDeriveFrom.model);
    if (!remoteModelToDeriveFrom) return;
    var localKeyToDeriveFrom = dataEntry[localReferenceToDeriveFrom.key];
    if (!localKeyToDeriveFrom) return;
    var value = [];
    if (Array.isArray(localKeyToDeriveFrom)) {
      var resultSet_1 = new Set();
      localKeyToDeriveFrom.forEach(function (item) {
        var dataEntryToDeriveFrom = remoteModelToDeriveFrom === null || remoteModelToDeriveFrom === void 0 ? void 0 : remoteModelToDeriveFrom.data.get(item.key);
        if (dataEntryToDeriveFrom) {
          var valueToDerive = dataEntryToDeriveFrom[reference.derive.remoteReference];
          if (valueToDerive) {
            if (Array.isArray(valueToDerive)) {
              valueToDerive.forEach(function (entry) {
                resultSet_1.add(entry.key);
              });
            } else {
              resultSet_1.add(valueToDerive.key);
            }
            value = Array.from(resultSet_1.values()).flat();
          }
        }
      });
    } else {
      var dataEntryToDeriveFrom = remoteModelToDeriveFrom === null || remoteModelToDeriveFrom === void 0 ? void 0 : remoteModelToDeriveFrom.data.get(localKeyToDeriveFrom.key);
      if (dataEntryToDeriveFrom) {
        var remoteValue = dataEntryToDeriveFrom[reference.derive.remoteReference];
        if (!remoteValue) {
          value = [];
        } else if (Array.isArray(remoteValue)) {
          value = remoteValue.map(function (val) {
            return val.key;
          });
        } else if ('key' in remoteValue) {
          value = [remoteValue.key];
        }
      }
    }
    var valueToAssign;
    if (reference.type === 'singleValued') {
      valueToAssign = {
        key: value[0]
      };
    } else {
      valueToAssign = value.map(function (v) {
        return {
          key: v
        };
      });
    }
    this.indicesController.fillInverseIndex(reference.key, dataEntry.key, value);
    dataEntry[reference.key] = valueToAssign;
  };
  DcuplModel.prototype.asStringArray = function (input) {
    if (!input) {
      return [];
    }
    if (Array.isArray(input)) {
      return input.map(function (i) {
        return i.key;
      });
    } else {
      return [input.key];
    }
  };
  DcuplModel.prototype.handleGroupedReferences = function (reference, dataEntry) {
    var _this = this;
    var valueToAssign = [];
    var referenceToGroupOn = this.references.get(reference.groupBy.reference);
    if (referenceToGroupOn) {
      var valueToGroupOn_1 = this.asStringArray(dataEntry[referenceToGroupOn.key]);
      if (!valueToGroupOn_1.length) {
        return;
      }
      this.data.forEach(function (entry) {
        var entryValue = entry[referenceToGroupOn.key];
        if (!entryValue) {
          return;
        }
        var relevantValues = _this.asStringArray(entryValue);
        if (relevantValues.find(function (val) {
          return valueToGroupOn_1.includes(val);
        })) {
          if (reference.groupBy.includeSelfInGroup) {
            valueToAssign.push({
              key: entry.key
            });
          } else {
            if (dataEntry.key !== entry.key) {
              valueToAssign.push({
                key: entry.key
              });
            }
          }
        }
      });
    }
    dataEntry[reference.key] = valueToAssign;
  };
  DcuplModel.prototype.resetData = function () {
    this.data.clear();
  };
  DcuplModel.prototype.resetIndexMap = function () {
    this.indicesController.reset();
  };
  DcuplModel.prototype.deleteDataEntries = function (data) {
    var e_9, _a;
    try {
      for (var _b = __values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
        var rawDataEntry = _c.value;
        this.data.delete(String(rawDataEntry.key));
      }
    } catch (e_9_1) {
      e_9 = {
        error: e_9_1
      };
    } finally {
      try {
        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
      } finally {
        if (e_9) throw e_9.error;
      }
    }
  };
  DcuplModel.prototype.getAttribute = function (key) {
    return this.references.get(key) || this.properties.get(key);
  };
  DcuplModel.prototype.getBasicReferences = function () {
    return Array.from(this.references.values()).filter(function (reference) {
      return !('derive' in reference) && !('resolve' in reference);
    });
  };
  DcuplModel.prototype.getResolvedReferences = function () {
    return Array.from(this.references.values()).filter(function (reference) {
      return 'resolve' in reference;
    });
  };
  DcuplModel.prototype.getGroupedReferences = function () {
    return Array.from(this.references.values()).filter(function (reference) {
      return 'groupBy' in reference;
    });
  };
  DcuplModel.prototype.getDerivedReferences = function () {
    return Array.from(this.references.values()).filter(function (reference) {
      return 'derive' in reference;
    });
  };
  DcuplModel.prototype.getAggregatedReferences = function () {
    return Array.from(this.references.values()).filter(function (reference) {
      return 'aggregate' in reference;
    });
  };
  DcuplModel.prototype.getBasicProperties = function () {
    return Array.from(this.properties.values()).filter(function (property) {
      return !('derive' in property) && !('expression' in property);
    });
  };
  DcuplModel.prototype.getDerivedProperties = function () {
    return Array.from(this.properties.values()).filter(function (property) {
      return 'derive' in property;
    });
  };
  DcuplModel.prototype.getDerivedPropertiesForBasicAndDerivedReferences = function () {
    var basicReferences = this.getBasicReferences();
    var derivedReferences = this.getDerivedReferences();
    var derivedProperties = this.getDerivedProperties();
    var references = __spreadArray(__spreadArray([], __read(basicReferences), false), __read(derivedReferences), false);
    var derivedPropertiesBasedOnBasicReferences = derivedProperties.filter(function (property) {
      return references.find(function (reference) {
        return reference.key === property.derive.localReference;
      });
    });
    return derivedPropertiesBasedOnBasicReferences;
  };
  DcuplModel.prototype.getDerivedPropertiesForResolvedReferences = function () {
    var resolvedReferences = this.getResolvedReferences();
    var derivedProperties = this.getDerivedProperties();
    var derivedPropertiesBasedOnResolvedReferences = derivedProperties.filter(function (property) {
      return resolvedReferences.find(function (reference) {
        return reference.key === property.derive.localReference;
      });
    });
    return derivedPropertiesBasedOnResolvedReferences;
  };
  DcuplModel.prototype.getDerivedReferencesForResolvedReferences = function () {
    var resolvedReferences = this.getResolvedReferences();
    var derivedReferences = this.getDerivedReferences();
    var derivedPropertiesBasedOnResolvedReferences = derivedReferences.filter(function (ref) {
      return resolvedReferences.find(function (reference) {
        return reference.key === ref.derive.localReference;
      });
    });
    return derivedPropertiesBasedOnResolvedReferences;
  };
  DcuplModel.prototype.getExpressionProperties = function () {
    return Array.from(this.properties.values()).filter(function (property) {
      return 'expression' in property;
    });
  };
  DcuplModel.prototype.getAggregatedProperties = function () {
    return Array.from(this.properties.values()).filter(function (property) {
      return 'aggregate' in property;
    });
  };
  DcuplModel.prototype.hasBasicReferences = function () {
    return this.getBasicReferences().length > 0;
  };
  DcuplModel.prototype.hasResolvedReferences = function () {
    return this.getResolvedReferences().length > 0;
  };
  DcuplModel.prototype.hasGroupedReferences = function () {
    return this.getGroupedReferences().length > 0;
  };
  DcuplModel.prototype.hasDerivedReferences = function () {
    return this.getDerivedReferences().length > 0;
  };
  DcuplModel.prototype.hasBasicProperties = function () {
    return this.getBasicProperties().length > 0;
  };
  DcuplModel.prototype.hasDerivedProperties = function () {
    return this.getDerivedProperties().length > 0;
  };
  DcuplModel.prototype.hasDerivedPropertiesForBasicReferences = function () {
    return this.getDerivedPropertiesForBasicAndDerivedReferences().length > 0;
  };
  DcuplModel.prototype.hasDerivedPropertiesBasedOnResolvedReferences = function () {
    return this.getDerivedPropertiesForResolvedReferences().length > 0;
  };
  DcuplModel.prototype.hasDerivedReferencesBasedOnResolvedReferences = function () {
    return this.getDerivedReferencesForResolvedReferences().length > 0;
  };
  DcuplModel.prototype.hasExpressionProperties = function () {
    return this.getExpressionProperties().length > 0;
  };
  DcuplModel.prototype.hasAggregatedProperties = function () {
    return this.getAggregatedProperties().length > 0;
  };
  DcuplModel.prototype.getData = function (options) {
    var _this = this;
    if (options === null || options === void 0 ? void 0 : options.itemKeys) {
      var filteredData_1 = new Map();
      options.itemKeys.forEach(function (key) {
        var entry = _this.data.get(key);
        if (entry) {
          filteredData_1.set(entry.key, entry);
        }
      });
      return filteredData_1;
    }
    return this.data;
  };
  DcuplModel.prototype.getDataAsArray = function () {
    return Array.from(this.data.values());
  };
  DcuplModel.prototype.getEntry = function (key) {
    return this.data.get(key);
  };
  DcuplModel.prototype.setRefValue = function (item, value) {
    var _a;
    if ((_a = this.modelParser.initOptions.referenceMetadata) === null || _a === void 0 ? void 0 : _a.enabled) {
      Object.defineProperty(item, EntryMetadataValues.REF_VALUE, {
        value: value,
        enumerable: true,
        writable: false,
        configurable: false
      });
    }
  };
  return DcuplModel;
}();
export { DcuplModel };
