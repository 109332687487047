var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import objectHash from 'object-hash';
var IndicesController = /** @class */function () {
  function IndicesController() {
    this.indexMap = new Map();
    // Possible performance gain*
    this.dataMap = new Map();
  }
  IndicesController.prototype.reset = function (attributeKey) {
    if (attributeKey) {
      this.indexMap.delete(attributeKey);
      this.dataMap.delete(attributeKey);
    } else {
      this.indexMap.clear();
      this.dataMap.clear();
    }
  };
  IndicesController.prototype.fillDataMap = function (attributeKey, attributeValue) {
    var e_1, _a, e_2, _b;
    var _c;
    var valueMap = this.dataMap.get(attributeKey);
    if (!valueMap) {
      valueMap = new Map();
      this.dataMap.set(attributeKey, valueMap);
    }
    try {
      for (var attributeValue_1 = __values(attributeValue), attributeValue_1_1 = attributeValue_1.next(); !attributeValue_1_1.done; attributeValue_1_1 = attributeValue_1.next()) {
        var value = attributeValue_1_1.value;
        var keyAsString = void 0;
        if (typeof value === 'object' && value && value.key) {
          keyAsString = value.key;
        } else if (Array.isArray(value)) {
          try {
            for (var value_1 = (e_2 = void 0, __values(value)), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
              var item = value_1_1.value;
              this.fillDataMap(attributeKey, [item]);
            }
          } catch (e_2_1) {
            e_2 = {
              error: e_2_1
            };
          } finally {
            try {
              if (value_1_1 && !value_1_1.done && (_b = value_1.return)) _b.call(value_1);
            } finally {
              if (e_2) throw e_2.error;
            }
          }
          return;
        } else {
          keyAsString = value;
        }
        var listItem = valueMap.get(keyAsString);
        if (!listItem) {
          listItem = {
            key: keyAsString
          };
          (_c = this.dataMap.get(attributeKey)) === null || _c === void 0 ? void 0 : _c.set(keyAsString, listItem);
        }
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (attributeValue_1_1 && !attributeValue_1_1.done && (_a = attributeValue_1.return)) _a.call(attributeValue_1);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
  };
  IndicesController.prototype.fillInverseIndex = function (attributeKey, originKey, foreignKeys) {
    var e_3, _a, e_4, _b;
    if (!attributeKey || !originKey || !foreignKeys || !Array.isArray(foreignKeys)) {
      return;
    }
    var valueMap = this.indexMap.get(attributeKey) || new Map();
    try {
      for (var foreignKeys_1 = __values(foreignKeys), foreignKeys_1_1 = foreignKeys_1.next(); !foreignKeys_1_1.done; foreignKeys_1_1 = foreignKeys_1.next()) {
        var value = foreignKeys_1_1.value;
        var keyAsString = void 0;
        if (typeof value === 'object' && value && value.key) {
          keyAsString = value.key;
        } else if (Array.isArray(value)) {
          try {
            // keyAsString = JSON.stringify(value);
            for (var value_2 = (e_4 = void 0, __values(value)), value_2_1 = value_2.next(); !value_2_1.done; value_2_1 = value_2.next()) {
              var item = value_2_1.value;
              this.fillInverseIndex(attributeKey, originKey, [item]);
            }
          } catch (e_4_1) {
            e_4 = {
              error: e_4_1
            };
          } finally {
            try {
              if (value_2_1 && !value_2_1.done && (_b = value_2.return)) _b.call(value_2);
            } finally {
              if (e_4) throw e_4.error;
            }
          }
          return;
        } else {
          keyAsString = value;
        }
        var valueArray = valueMap.get(keyAsString) || [];
        valueArray.push(originKey);
        valueMap.set(keyAsString, valueArray);
      }
    } catch (e_3_1) {
      e_3 = {
        error: e_3_1
      };
    } finally {
      try {
        if (foreignKeys_1_1 && !foreignKeys_1_1.done && (_a = foreignKeys_1.return)) _a.call(foreignKeys_1);
      } finally {
        if (e_3) throw e_3.error;
      }
    }
    this.indexMap.set(attributeKey, valueMap);
  };
  IndicesController.prototype.getIndex = function (attributeKey, context) {
    var indexName = this.getIndexName(attributeKey, context);
    return this.indexMap.get(indexName);
  };
  IndicesController.prototype.getData = function (attributeKey, context) {
    var indexName = this.getIndexName(attributeKey, context);
    return this.dataMap.get(indexName);
  };
  IndicesController.prototype.getOrCreateIndex = function (attributeKey, relevantData, context) {
    var e_5, _a;
    var indexName = this.getIndexName(attributeKey, context);
    var index = this.getIndex(indexName);
    if (!index) {
      this.reset(indexName);
      this.indexMap.set(attributeKey, new Map());
      try {
        for (var _b = __values(relevantData.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
          var entry = _c.value;
          var attributeValue = entry[attributeKey];
          this.fillInverseIndex(indexName, entry.key, [attributeValue]);
          this.fillDataMap(indexName, [attributeValue]);
        }
      } catch (e_5_1) {
        e_5 = {
          error: e_5_1
        };
      } finally {
        try {
          if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        } finally {
          if (e_5) throw e_5.error;
        }
      }
      index = this.getIndex(indexName);
    }
    return index;
  };
  IndicesController.prototype.getIndexName = function (attributeKey, context) {
    return context ? attributeKey + '_' + objectHash(context) : attributeKey;
  };
  return IndicesController;
}();
export { IndicesController };
