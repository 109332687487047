var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import objectHash from 'object-hash';
import { perf } from './performance';
var AnalyticsController = /** @class */function () {
  function AnalyticsController() {
    this.enabled = false;
  }
  AnalyticsController.prototype.init = function (enabled) {
    this.data = new Map();
    this.enabled = enabled;
  };
  AnalyticsController.prototype.set = function (options) {
    if (!this.enabled) return options.value;
    var uniqueKey = this.getKey(options.value.name, options.context);
    this.data.set(uniqueKey, options.value);
    return this.data.get(uniqueKey);
  };
  AnalyticsController.prototype.mark = function (options) {
    if (!this.enabled) return undefined;
    var uniqueKey = this.getKey(options.name, options.context);
    var entry = {
      name: options.name,
      type: 'mark',
      value: perf.now(),
      createdAt: Date.now()
    };
    this.data.set(uniqueKey, entry);
    return entry;
  };
  AnalyticsController.prototype.measure = function (options) {
    if (!this.enabled) return undefined;
    var uniqueKey = this.getKey(options.name, options.context);
    var startMark = this.data.get(this.getKey(options.start, options.context));
    var endMark = this.data.get(this.getKey(options.end, options.context));
    if (!startMark || !endMark) {
      return undefined;
    }
    var duration = endMark.value - startMark.value;
    var roundedDuration = Math.round(duration * 100) / 100;
    var existingEntry = this.data.get(uniqueKey);
    if (existingEntry && options.sum) {
      var newDuration = existingEntry.value + roundedDuration;
      var newEntry = __assign(__assign({}, existingEntry), {
        value: newDuration
      });
      this.data.set(uniqueKey, newEntry);
      return newEntry;
    }
    var entry = {
      name: options.name,
      type: 'measure',
      value: roundedDuration,
      createdAt: Date.now()
    };
    if (options.detail) {
      entry.detail = options.detail;
    }
    this.data.set(uniqueKey, entry);
    return entry;
  };
  AnalyticsController.prototype.update = function (options) {
    if (!this.enabled) return options.value;
    var uniqueKey = this.getKey(options.value.name, options.context);
    var currentItem = this.data.get(uniqueKey);
    if (currentItem) {
      var newItem = __assign(__assign({}, currentItem), options.value);
      this.data.set(uniqueKey, newItem);
    } else {
      this.data.set(uniqueKey, options.value);
    }
    return this.data.get(uniqueKey);
  };
  AnalyticsController.prototype.getResourceInfosFromPerformanceAPI = function () {
    if (typeof performance === 'undefined') return [];
    var entries = perf.getEntriesByType('resource');
    return entries;
  };
  AnalyticsController.prototype.get = function (options) {
    var uniqueKey = this.getKey(options.name, options.context);
    return this.data.get(uniqueKey);
  };
  AnalyticsController.prototype.getByType = function (type) {
    return Array.from(this.data.values()).filter(function (entry) {
      return entry.type === type;
    });
  };
  AnalyticsController.prototype.getByName = function (name) {
    return Array.from(this.data.values()).filter(function (entry) {
      return entry.name === name;
    });
  };
  AnalyticsController.prototype.clear = function () {
    this.data.clear();
  };
  AnalyticsController.prototype.getKey = function (key, context) {
    if (!context) {
      return key;
    }
    var hash = objectHash(context);
    return key + '_' + hash;
  };
  return AnalyticsController;
}();
export { AnalyticsController };
