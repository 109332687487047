var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { Decimal } from 'decimal.js-light';
export var getAggregation = function (options, relevantData, indexMap, attribute) {
  if (!attribute) {
    throw new Error('Attribute not found');
  }
  var aggregation = {
    attribute: attribute,
    types: options.types
  };
  var numberAgg;
  if (aggregation.types.includes('sum') || aggregation.types.includes('avg') || aggregation.types.includes('count')) {
    numberAgg = getNumberAggregation(attribute, relevantData);
  }
  var minMax;
  if (aggregation.types.includes('min') || aggregation.types.includes('max')) {
    minMax = getMinMax(options.attribute, relevantData);
  }
  options.types.forEach(function (type) {
    if (type === 'sum') {
      aggregation.sum = numberAgg === null || numberAgg === void 0 ? void 0 : numberAgg.sum;
    } else if (type === 'avg') {
      aggregation.avg = numberAgg === null || numberAgg === void 0 ? void 0 : numberAgg.average;
    } else if (type === 'count') {
      aggregation.count = numberAgg === null || numberAgg === void 0 ? void 0 : numberAgg.count;
    } else if (type === 'min') {
      aggregation.min = minMax && minMax[0];
    } else if (type === 'max') {
      aggregation.max = minMax && minMax[1];
    } else if (type === 'distinct') {
      aggregation.distinct = getDistinct(options, relevantData, indexMap);
    } else if (type === 'group') {
      aggregation.group = getGroups(options, relevantData);
    }
  });
  return aggregation;
};
var getMinMax = function (attribute, data) {
  var e_1, _a;
  var minV = undefined;
  var maxV = undefined;
  try {
    for (var _b = __values(data.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
      var item = _c.value;
      if (item) {
        var value = item[attribute];
        if (minV === undefined || value < minV) minV = value;
        if (maxV === undefined || value > maxV) maxV = value;
      }
    }
  } catch (e_1_1) {
    e_1 = {
      error: e_1_1
    };
  } finally {
    try {
      if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
    } finally {
      if (e_1) throw e_1.error;
    }
  }
  if (minV === undefined || maxV === undefined) {
    return undefined;
  }
  return [minV, maxV];
};
var getGroups = function (options, data) {
  var e_2, _a, e_3, _b;
  var _c, _d, _e, _f;
  var values = [];
  (_d = (_c = options.groupOptions) === null || _c === void 0 ? void 0 : _c.groups) === null || _d === void 0 ? void 0 : _d.forEach(function (group) {
    values.push({
      option: group,
      count: 0,
      keys: []
    });
  });
  var notFound = {
    option: {
      value: ['$dcupl_not_found']
    },
    count: 0,
    keys: []
  };
  try {
    for (var _g = __values(data.values()), _h = _g.next(); !_h.done; _h = _g.next()) {
      var item = _h.value;
      var relevantEntry = item[options.attribute];
      try {
        for (var values_1 = (e_3 = void 0, __values(values)), values_1_1 = values_1.next(); !values_1_1.done; values_1_1 = values_1.next()) {
          var group = values_1_1.value;
          if (typeof group.option.value !== 'undefined' && group.option.value.includes(relevantEntry) || typeof group.option.from !== 'undefined' && group.option.from < relevantEntry && typeof group.option.to !== 'undefined' && group.option.to > relevantEntry) {
            group.count++;
            group.keys.push(item.key);
          } else {
            if ((_e = options === null || options === void 0 ? void 0 : options.groupOptions) === null || _e === void 0 ? void 0 : _e.calculateNotFound) {
              notFound.count++;
              notFound.keys.push(item.key);
            }
          }
        }
      } catch (e_3_1) {
        e_3 = {
          error: e_3_1
        };
      } finally {
        try {
          if (values_1_1 && !values_1_1.done && (_b = values_1.return)) _b.call(values_1);
        } finally {
          if (e_3) throw e_3.error;
        }
      }
    }
  } catch (e_2_1) {
    e_2 = {
      error: e_2_1
    };
  } finally {
    try {
      if (_h && !_h.done && (_a = _g.return)) _a.call(_g);
    } finally {
      if (e_2) throw e_2.error;
    }
  }
  if ((_f = options === null || options === void 0 ? void 0 : options.groupOptions) === null || _f === void 0 ? void 0 : _f.calculateNotFound) {
    values.push(notFound);
  }
  return values;
};
var getNumberAggregation = function (attribute, data) {
  var e_4, _a, e_5, _b;
  var sum = new Decimal(0);
  var count = 0;
  try {
    for (var _c = __values(data.values()), _d = _c.next(); !_d.done; _d = _c.next()) {
      var item = _d.value;
      if (!item) continue;
      var value = item[attribute];
      if (Array.isArray(value)) {
        try {
          for (var value_1 = (e_5 = void 0, __values(value)), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
            var val = value_1_1.value;
            if (typeof val === 'number') {
              sum = sum.add(val);
              count++;
            }
          }
        } catch (e_5_1) {
          e_5 = {
            error: e_5_1
          };
        } finally {
          try {
            if (value_1_1 && !value_1_1.done && (_b = value_1.return)) _b.call(value_1);
          } finally {
            if (e_5) throw e_5.error;
          }
        }
      } else if (typeof value === 'number') {
        sum = sum.add(value);
        count++;
      }
    }
  } catch (e_4_1) {
    e_4 = {
      error: e_4_1
    };
  } finally {
    try {
      if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
    } finally {
      if (e_4) throw e_4.error;
    }
  }
  if (count === 0) {
    return undefined;
  }
  var average = sum.dividedBy(count).toNumber();
  var agg = {
    average: average,
    count: data.size,
    sum: sum.toNumber()
  };
  return agg;
};
export var getDistinct = function (options, data, indexMap) {
  var e_6, _a;
  var _b, _c;
  var values = [];
  try {
    for (var indexMap_1 = __values(indexMap), indexMap_1_1 = indexMap_1.next(); !indexMap_1_1.done; indexMap_1_1 = indexMap_1.next()) {
      var _d = __read(indexMap_1_1.value, 2),
        attributeValue = _d[0],
        itemKeys = _d[1];
      if (options.excludeUndefineds && typeof attributeValue === 'undefined') {
        continue;
      }
      if (itemKeys.find(function (key) {
        return data.has(key);
      })) {
        var value = {
          value: attributeValue,
          count: itemKeys.length
        };
        if (!((_b = options.distinctOptions) === null || _b === void 0 ? void 0 : _b.skipKeys)) {
          value.keys = itemKeys;
        }
        values.push(value);
        if (((_c = options.distinctOptions) === null || _c === void 0 ? void 0 : _c.limit) && values.length >= options.distinctOptions.limit) {
          break;
        }
      }
    }
  } catch (e_6_1) {
    e_6 = {
      error: e_6_1
    };
  } finally {
    try {
      if (indexMap_1_1 && !indexMap_1_1.done && (_a = indexMap_1.return)) _a.call(indexMap_1);
    } finally {
      if (e_6) throw e_6.error;
    }
  }
  return values;
};
