import { Injectable } from '@angular/core';
import { Auth, User, user, authState } from '@angular/fire/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DbService {
  public user$!: Observable<User | null>;
  public user: User | null = null;
  public authState$!: Observable<any | null>;

  constructor(private auth: Auth) {}

  public async init() {
    this.user$ = user(this.auth);
    this.authState$ = authState(this.auth);
    this.user$.subscribe((user) => {
      this.user = user;
    });
  }
}
