var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { evaluateStringTemplate } from 'string-template-parser';
import { ChangeDetector, trigger, generateKey, perf } from '@dcupl/common';
import { CsvParser } from '../../csv-parser/csv-parser';
import { AppLoaderProgressHandler } from './app-loader-progress';
import { fetch } from 'cross-fetch';
import { cloneDeep, intersection } from 'lodash';
var defaultOptions = {
  missingModelHandling: {
    autoGenerateProperties: true
  },
  defaultDataContainerUpdateType: 'set',
  csvParserOptions: {
    delimiter: '',
    quoteChar: '"',
    escapeChar: '"'
  }
};
var DcuplAppLoader = /** @class */function () {
  function DcuplAppLoader() {
    var _this = this;
    this.cdRef = new ChangeDetector();
    this.registeredTransformers = [];
    this.options = defaultOptions;
    this.csvParser = new CsvParser(this);
    this.globalVariables = new Map();
    this.defaultVariables = new Map();
    this.progressListeners = new Set();
    this.currentContext = '';
    this.progressHandler = new AppLoaderProgressHandler();
    this.resources = {
      get: function (key) {
        var _a;
        return (_a = _this.currentConfig) === null || _a === void 0 ? void 0 : _a.resources.find(function (r) {
          return r.key === key;
        });
      },
      getAll: function () {
        var _a;
        return ((_a = _this.currentConfig) === null || _a === void 0 ? void 0 : _a.resources) || [];
      },
      getWithTag: function (tag) {
        var _a;
        return ((_a = _this.currentConfig) === null || _a === void 0 ? void 0 : _a.resources.filter(function (r) {
          var _a;
          return (_a = r.tags) === null || _a === void 0 ? void 0 : _a.includes(tag);
        })) || [];
      },
      set: function (resources) {
        _this.currentConfig.resources = resources;
      },
      add: function (resource) {
        _this.currentConfig.resources.push(resource);
      },
      delete: function (key) {
        var foundIndex = _this.currentConfig.resources.findIndex(function (r) {
          return r.key === key;
        });
        if (foundIndex > -1) {
          _this.currentConfig.resources.splice(foundIndex, 1);
        }
      }
    };
    this.variables = {
      get: function (key, env) {
        var _a, _b;
        var relevantEnv = (_b = (_a = _this.currentConfig) === null || _a === void 0 ? void 0 : _a.environments) === null || _b === void 0 ? void 0 : _b.filter(function (e) {
          return e.key === env;
        });
        var variables = _this.evaluateVariables(relevantEnv, _this.currentProcessOptions);
        return variables.find(function (v) {
          return v.key === key;
        });
      },
      getAll: function (env) {
        var _a, _b;
        var relevantEnv = (_b = (_a = _this.currentConfig) === null || _a === void 0 ? void 0 : _a.environments) === null || _b === void 0 ? void 0 : _b.filter(function (e) {
          return e.key === env;
        });
        var variables = _this.evaluateVariables(relevantEnv, _this.currentProcessOptions);
        return variables;
      },
      getAllAsObject: function (env) {
        var _a, _b;
        var relevantEnv = (_b = (_a = _this.currentConfig) === null || _a === void 0 ? void 0 : _a.environments) === null || _b === void 0 ? void 0 : _b.filter(function (e) {
          return e.key === env;
        });
        var variables = _this.getEvaluatedVariablesAsRecord(relevantEnv, _this.currentProcessOptions);
        return variables;
      },
      default: {
        get: function (key) {
          return _this.defaultVariables.get(key);
        },
        getAll: function () {
          return Array.from(_this.defaultVariables.values());
        }
      },
      environment: {
        get: function (env, key) {
          var _a, _b, _c;
          if (!env) {
            return;
          }
          var relevantEnv = (_b = (_a = _this.currentConfig) === null || _a === void 0 ? void 0 : _a.environments) === null || _b === void 0 ? void 0 : _b.find(function (e) {
            return e.key === env;
          });
          if (relevantEnv) {
            return (_c = relevantEnv.variables) === null || _c === void 0 ? void 0 : _c.find(function (v) {
              return v.key === key;
            });
          }
          return;
        },
        getAll: function (env) {
          var _a, _b;
          var relevantEnv = (_b = (_a = _this.currentConfig) === null || _a === void 0 ? void 0 : _a.environments) === null || _b === void 0 ? void 0 : _b.find(function (e) {
            return e.key === env;
          });
          return (relevantEnv === null || relevantEnv === void 0 ? void 0 : relevantEnv.variables) || [];
        }
      },
      process: {
        get: function (key) {
          var _a, _b;
          return (_b = (_a = _this.currentProcessOptions) === null || _a === void 0 ? void 0 : _a.variables) === null || _b === void 0 ? void 0 : _b.find(function (v) {
            return v.key === key;
          });
        },
        getAll: function () {
          var _a;
          return ((_a = _this.currentProcessOptions) === null || _a === void 0 ? void 0 : _a.variables) || [];
        }
      },
      global: {
        get: function (key) {
          return _this.globalVariables.get(key);
        },
        getAll: function () {
          return Array.from(_this.globalVariables.values());
        },
        set: function (key, value) {
          _this.globalVariables.set(key, {
            key: key,
            value: value
          });
        },
        delete: function (key) {
          _this.globalVariables.delete(key);
        }
      }
    };
    this.transformers = {
      get: function (options) {
        return _this.registeredTransformers.filter(function (t) {
          return intersection(t.applyTo, options.appyTo).length > 0;
        });
      },
      getAll: function () {
        return _this.registeredTransformers;
      },
      set: function (transformer) {
        _this.transformers.remove(transformer.key);
        _this.registeredTransformers.push(transformer);
      },
      remove: function (key) {
        var foundIndex = _this.registeredTransformers.findIndex(function (t) {
          return t.key === key;
        });
        if (foundIndex > -1) {
          _this.registeredTransformers.splice(foundIndex, 1);
        }
      }
    };
    this.config = {
      get: function () {
        return cloneDeep(_this.currentConfig);
      },
      set: function (config) {
        _this.currentConfig = cloneDeep(config);
      },
      fetch: function (options) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2 /*return*/, this.fetchConfig(options)];
          });
        });
      }
    };
    this.initDefaultVariables();
  }
  DcuplAppLoader.prototype.destroy = function () {
    this.core = null;
    this.cdRef = null;
    this.csvParser = null;
    this.progressListeners = null;
    this.progressHandler = null;
  };
  DcuplAppLoader.prototype.initDefaultVariables = function () {
    this.defaultVariables.set('projectId', {
      key: 'projectId',
      value: undefined,
      description: 'Your dcupl console project id'
    });
    this.defaultVariables.set('apiKey', {
      key: 'apiKey',
      value: undefined,
      description: 'Your dcupl console project API key'
    });
    this.defaultVariables.set('version', {
      key: 'version',
      value: 'draft',
      description: 'The version of uploaded files to the dcupl CDN - draft/published/...'
    });
    this.defaultVariables.set('cdnBaseUrl', {
      key: 'cdnBaseUrl',
      value: 'https://cdn.dcupl.com/files/projects',
      description: 'The url to your dcupl folder - might be the dcupl CDN or any other '
    });
    this.defaultVariables.set('baseUrl', {
      key: 'baseUrl',
      value: '${cdnBaseUrl}/${projectId}/${version}',
      description: 'The base url to your hosted models and data files'
    });
    this.defaultVariables.set('modelUrl', {
      key: 'modelUrl',
      value: '${baseUrl}/models',
      description: 'The url to the your model files'
    });
    this.defaultVariables.set('dataUrl', {
      key: 'dataUrl',
      value: '${baseUrl}/data',
      description: 'The url to the your data files'
    });
    this.defaultVariables.set('transformerUrl', {
      key: 'transformerUrl',
      value: '${baseUrl}/transformers',
      description: 'The url to the your data files'
    });
    this.defaultVariables.set('loaderFileName', {
      key: 'loaderFileName',
      value: 'dcupl.lc.json',
      description: 'The file name of the loader json'
    });
    this.defaultVariables.set('cdnVersion', {
      key: 'cdnVersion',
      value: undefined,
      description: 'The file-version of the CDN data'
    });
    this.defaultVariables.set('consoleApiUrl', {
      key: 'consoleApiUrl',
      value: 'https://api.dcupl.com',
      description: 'The dcupl console API Url'
    });
  };
  DcuplAppLoader.prototype.fetchConfig = function (options) {
    return __awaiter(this, void 0, void 0, function () {
      var config, url, cdnVersion;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!this.core) {
              console.error('dcupl not connected. Did you add your loader to dcupl? -> dcupl.addRunner(loader)');
              throw new Error();
            }
            if (!((options === null || options === void 0 ? void 0 : options.baseUrl) && options.loaderFileName)) return [3 /*break*/, 2];
            url = "".concat(options.baseUrl, "/").concat(options.loaderFileName);
            return [4 /*yield*/, this.loadConfigFromUrl(url, options.headers)];
          case 1:
            config = _a.sent();
            this.defaultVariables.set('baseUrl', {
              key: 'baseUrl',
              value: String(options.baseUrl)
            });
            return [3 /*break*/, 5];
          case 2:
            return [4 /*yield*/, this.loadCdnVersionFromApi()];
          case 3:
            cdnVersion = _a.sent();
            if (cdnVersion && cdnVersion.changedAt) {
              this.defaultVariables.set('cdnVersion', {
                key: 'cdnVersion',
                value: String(cdnVersion.changedAt)
              });
            }
            return [4 /*yield*/, this.loadConfigFromApi(cdnVersion === null || cdnVersion === void 0 ? void 0 : cdnVersion.changedAt)];
          case 4:
            config = _a.sent();
            _a.label = 5;
          case 5:
            if (this.isValidConfig(config)) {
              if (!(options === null || options === void 0 ? void 0 : options.skipApply)) {
                this.config.set(config);
              }
              return [2 /*return*/, config];
            } else {
              throw new Error('Invalid Config');
            }
            return [2 /*return*/];
        }
      });
    });
  };
  DcuplAppLoader.prototype.isValidConfig = function (config) {
    if (config && config.resources && config.resources.length) {
      return true;
    }
    return false;
  };
  DcuplAppLoader.prototype.loadConfigFromUrl = function (url, headers) {
    return __awaiter(this, void 0, void 0, function () {
      var options, response, err_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            options = {};
            if (headers) {
              options.headers = headers.reduce(function (acc, header) {
                acc[header.key] = header.value;
                return acc;
              }, {});
            }
            return [4 /*yield*/, fetch(url, options)];
          case 1:
            response = _a.sent();
            return [2 /*return*/, response.json()];
          case 2:
            err_1 = _a.sent();
            console.error('Could not fetch loader configuration');
            return [2 /*return*/];
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  DcuplAppLoader.prototype.loadConfigFromApi = function (cdnVersion) {
    return __awaiter(this, void 0, void 0, function () {
      var variables, url, v, response, err_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            variables = this.getEvaluatedVariablesAsRecord([], {});
            url = "".concat(variables.baseUrl, "/").concat(variables.loaderFileName);
            v = cdnVersion || variables.cdnVersion;
            if (v) {
              url += "?v=".concat(v);
            }
            return [4 /*yield*/, fetch(url)];
          case 1:
            response = _a.sent();
            return [2 /*return*/, response.json()];
          case 2:
            err_2 = _a.sent();
            console.error('Could not fetch loader configuration');
            console.error('Are you sure your projectId + apiKey are correct?');
            return [2 /*return*/];
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  DcuplAppLoader.prototype.loadCdnVersionFromApi = function () {
    return __awaiter(this, void 0, void 0, function () {
      var variables, url, response, err_3, defaultStatus;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            variables = this.getEvaluatedVariablesAsRecord([], {});
            url = "".concat(variables.consoleApiUrl, "/projects/").concat(variables.projectId, "/files/versions/").concat(variables.version, "/status");
            return [4 /*yield*/, fetch(url)];
          case 1:
            response = _a.sent();
            return [2 /*return*/, response.json()];
          case 2:
            err_3 = _a.sent();
            console.error('Could not fetch loader configuration');
            defaultStatus = {
              changedAt: new Date().getTime()
            };
            return [2 /*return*/, defaultStatus];
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  DcuplAppLoader.prototype.process = function (_options) {
    return __awaiter(this, void 0, void 0, function () {
      var options, config, appPreset, environments, variables, headerConfigs, queryParamConfigs, resources, loaderOptions, result;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!this.config) {
              throw new Error('There is no config present. Make sure to call loader.setConfig(..) or loader.fetchConfig(...)');
            }
            this.currentContext = generateKey();
            options = cloneDeep(_options);
            config = cloneDeep(this.currentConfig);
            if (options === null || options === void 0 ? void 0 : options.applicationKey) {
              appPreset = this.getAppPreset(config, options);
              if (!appPreset) {
                throw new Error("The applicationKey ".concat(options.applicationKey, " does not exist"));
              }
              options.resourceTags = options.resourceTags || (appPreset === null || appPreset === void 0 ? void 0 : appPreset.resourceTags);
              options.variables = options.variables || (appPreset === null || appPreset === void 0 ? void 0 : appPreset.variables);
            }
            environments = this.getEnvironments(config, options);
            variables = this.getEvaluatedVariablesAsRecord(environments, options);
            headerConfigs = this.getHeaderConfigs(environments, options);
            queryParamConfigs = this.getQueryParamConfigs(environments, options);
            resources = this.getResourcesForConfigOptions(config, variables, options);
            loaderOptions = this.getLoaderOptions(config, options);
            return [4 /*yield*/, this.fetchResources(resources, variables, loaderOptions, headerConfigs, queryParamConfigs)];
          case 1:
            result = _a.sent();
            this.currentProcessOptions = options;
            return [2 /*return*/, result];
        }
      });
    });
  };
  DcuplAppLoader.prototype.getHeaderConfigs = function (environments, options) {
    if (!environments) {
      return [];
    }
    var relevantEnvironments = [];
    if (!(options === null || options === void 0 ? void 0 : options.environmentKeys)) {
      relevantEnvironments = environments;
    } else {
      relevantEnvironments = environments.filter(function (env) {
        var _a;
        return (_a = options.environmentKeys) === null || _a === void 0 ? void 0 : _a.includes(env.key);
      });
    }
    var relevantHttpHeaderconfigs = [];
    relevantEnvironments.forEach(function (env) {
      if (env.headers) {
        relevantHttpHeaderconfigs.push.apply(relevantHttpHeaderconfigs, __spreadArray([], __read(env.headers), false));
      }
    });
    return relevantHttpHeaderconfigs;
  };
  DcuplAppLoader.prototype.getQueryParamConfigs = function (environments, options) {
    if (!environments) {
      return [];
    }
    var relevantEnvironments = [];
    if (!(options === null || options === void 0 ? void 0 : options.environmentKeys)) {
      relevantEnvironments = environments;
    } else {
      relevantEnvironments = environments.filter(function (env) {
        var _a;
        return (_a = options.environmentKeys) === null || _a === void 0 ? void 0 : _a.includes(env.key);
      });
    }
    var relevantQueryParamConfigs = [];
    relevantEnvironments.forEach(function (env) {
      if (env.queryParams) {
        relevantQueryParamConfigs.push.apply(relevantQueryParamConfigs, __spreadArray([], __read(env.queryParams), false));
      }
    });
    return relevantQueryParamConfigs;
  };
  DcuplAppLoader.prototype.getLoaderOptions = function (config, processOptions) {
    if (processOptions === null || processOptions === void 0 ? void 0 : processOptions.options) {
      return Object.assign({}, this.options, processOptions.options);
    }
    if (config === null || config === void 0 ? void 0 : config.options) {
      return Object.assign({}, this.options, config.options);
    }
    return Object.assign({}, this.options);
  };
  DcuplAppLoader.prototype.getResourcesForConfigOptions = function (config, variables, options) {
    var _a;
    var resources = [];
    if (!config.resources) {
      return resources;
    }
    if ((_a = options === null || options === void 0 ? void 0 : options.resourceTags) === null || _a === void 0 ? void 0 : _a.length) {
      resources = config.resources.filter(function (resource) {
        var _a;
        return (_a = options.resourceTags) === null || _a === void 0 ? void 0 : _a.find(function (optionsTag) {
          var _a;
          return optionsTag === ((_a = resource.tags) === null || _a === void 0 ? void 0 : _a.find(function (resourceTag) {
            return resourceTag === optionsTag;
          }));
        });
      });
    } else {
      resources = __spreadArray([], __read(config.resources), false);
    }
    return resources.map(function (resource) {
      if (variables) {
        var value = evaluateStringTemplate(resource.url, __assign({}, variables), {});
        resource.url = value;
        return resource;
      } else {
        return resource;
      }
    });
  };
  DcuplAppLoader.prototype.evaluateVariables = function (environments, options) {
    var e_1, _a, e_2, _b;
    var variableMap = new Map();
    if (this.defaultVariables) {
      this.defaultVariables.forEach(function (variable) {
        variableMap.set(variable.key, variable);
      });
    }
    environments === null || environments === void 0 ? void 0 : environments.forEach(function (environment) {
      if (environment === null || environment === void 0 ? void 0 : environment.variables) {
        environment.variables.forEach(function (variable) {
          variableMap.set(variable.key, variable);
        });
      }
    });
    if (options === null || options === void 0 ? void 0 : options.variables) {
      options.variables.forEach(function (variable) {
        variableMap.set(variable.key, variable);
      });
    }
    if (this.globalVariables) {
      this.globalVariables.forEach(function (variable) {
        variableMap.set(variable.key, variable);
      });
    }
    var variablesAsArray = Array.from(variableMap.values());
    var variableAsMap = {};
    try {
      for (var variablesAsArray_1 = __values(variablesAsArray), variablesAsArray_1_1 = variablesAsArray_1.next(); !variablesAsArray_1_1.done; variablesAsArray_1_1 = variablesAsArray_1.next()) {
        var variable = variablesAsArray_1_1.value;
        variableAsMap[variable.key] = variable.value || '';
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (variablesAsArray_1_1 && !variablesAsArray_1_1.done && (_a = variablesAsArray_1.return)) _a.call(variablesAsArray_1);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
    try {
      // evaluate variables in variables
      for (var variablesAsArray_2 = __values(variablesAsArray), variablesAsArray_2_1 = variablesAsArray_2.next(); !variablesAsArray_2_1.done; variablesAsArray_2_1 = variablesAsArray_2.next()) {
        var variable = variablesAsArray_2_1.value;
        variable.value = evaluateStringTemplate(variable.value, variableAsMap, {});
      }
    } catch (e_2_1) {
      e_2 = {
        error: e_2_1
      };
    } finally {
      try {
        if (variablesAsArray_2_1 && !variablesAsArray_2_1.done && (_b = variablesAsArray_2.return)) _b.call(variablesAsArray_2);
      } finally {
        if (e_2) throw e_2.error;
      }
    }
    return variablesAsArray;
  };
  DcuplAppLoader.prototype.getEvaluatedVariablesAsRecord = function (environments, options) {
    var e_3, _a;
    var variables = this.evaluateVariables(environments, options);
    var variableAsMap = {};
    try {
      for (var variables_1 = __values(variables), variables_1_1 = variables_1.next(); !variables_1_1.done; variables_1_1 = variables_1.next()) {
        var variable = variables_1_1.value;
        variableAsMap[variable.key] = variable.value || '';
      }
    } catch (e_3_1) {
      e_3 = {
        error: e_3_1
      };
    } finally {
      try {
        if (variables_1_1 && !variables_1_1.done && (_a = variables_1.return)) _a.call(variables_1);
      } finally {
        if (e_3) throw e_3.error;
      }
    }
    return variableAsMap;
  };
  DcuplAppLoader.prototype.getEnvironments = function (config, options) {
    if (!config.environments || !config.environments[0]) {
      return;
    }
    if (options && options.environmentKeys && options.environmentKeys.length > 0) {
      return config.environments.filter(function (env) {
        return options.environmentKeys.indexOf(env.key) > -1;
      });
    }
    return;
  };
  DcuplAppLoader.prototype.getAppPreset = function (config, options) {
    var _a;
    if (!config.applications || !config.applications[0]) {
      return;
    }
    if (options === null || options === void 0 ? void 0 : options.applicationKey) {
      return (_a = config.applications) === null || _a === void 0 ? void 0 : _a.find(function (application) {
        return application.key === options.applicationKey;
      });
    }
    return;
  };
  DcuplAppLoader.prototype.getHeaderForUrl = function (resource, variables, headerConfigs) {
    var _this = this;
    var _a;
    if (!headerConfigs || !headerConfigs.length) {
      return;
    }
    if (!((_a = resource.tags) === null || _a === void 0 ? void 0 : _a.length)) {
      return;
    }
    var relevantHeader = [];
    headerConfigs === null || headerConfigs === void 0 ? void 0 : headerConfigs.forEach(function (header) {
      var _a;
      if (!((_a = header.tags) === null || _a === void 0 ? void 0 : _a.length)) {
        if (_this.isValidCondition(header, variables)) {
          relevantHeader.push(header);
        }
        return;
      }
      var foundHeader = header.tags.find(function (headerTag) {
        var _a;
        return (_a = resource.tags) === null || _a === void 0 ? void 0 : _a.includes(headerTag);
      });
      if (foundHeader) {
        if (_this.isValidCondition(header, variables)) {
          relevantHeader.push(header);
        }
      }
    });
    var headerAsRecord = {};
    relevantHeader.forEach(function (header) {
      var value = evaluateStringTemplate(header.value, __assign({}, variables), {});
      headerAsRecord[header.key] = value;
    });
    return headerAsRecord;
  };
  DcuplAppLoader.prototype.getQueryParamsForUrl = function (resource, variables, queryParamConfig) {
    var _this = this;
    var relevantQueryParams = [];
    queryParamConfig === null || queryParamConfig === void 0 ? void 0 : queryParamConfig.forEach(function (queryParam) {
      var _a;
      if (!((_a = queryParam.tags) === null || _a === void 0 ? void 0 : _a.length)) {
        if (_this.isValidCondition(queryParam, variables)) {
          relevantQueryParams.push(queryParam);
        }
        return;
      }
      var foundQueryParam = queryParam.tags.find(function (headerTag) {
        var _a;
        return (_a = resource.tags) === null || _a === void 0 ? void 0 : _a.includes(headerTag);
      });
      if (foundQueryParam) {
        if (_this.isValidCondition(queryParam, variables)) {
          relevantQueryParams.push(queryParam);
        }
      }
    });
    var v = variables.cdnVersion;
    if (v && resource.url.includes('cdn.dcupl.com')) {
      relevantQueryParams.push({
        key: 'v',
        value: v
      });
    }
    return relevantQueryParams.map(function (param) {
      param.value = evaluateStringTemplate(param.value, __assign({}, variables), {});
      return param;
    });
  };
  DcuplAppLoader.prototype.isValidCondition = function (header, variables) {
    var _a, _b, _c;
    if ((_a = header.condition) === null || _a === void 0 ? void 0 : _a.applyIfVariableHasValue) {
      if (typeof variables[(_b = header.condition) === null || _b === void 0 ? void 0 : _b.applyIfVariableHasValue] !== 'undefined' && variables[(_c = header.condition) === null || _c === void 0 ? void 0 : _c.applyIfVariableHasValue] !== '') {
        return true;
      }
      return false;
    }
    return true;
  };
  DcuplAppLoader.prototype.handleScriptResources = function (resources, variables, headerConfigs, queryParamConfigs) {
    return __awaiter(this, void 0, void 0, function () {
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, Promise.all(resources.map(function (resource) {
              return __awaiter(_this, void 0, void 0, function () {
                var key, progress_1, scriptContent, progress;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      key = resource.scriptKey;
                      if (this.core['scriptController'].hasScript(key)) {
                        progress_1 = this.progressHandler.update(resource, true, 'script');
                        this.updateProgressListeners(progress_1);
                        return [2 /*return*/, null];
                      }
                      return [4 /*yield*/, this.fetchScript(resource, variables, headerConfigs, queryParamConfigs)];
                    case 1:
                      scriptContent = _a.sent();
                      this.core['scriptController'].setScript(key, scriptContent);
                      progress = this.progressHandler.update(resource, true, 'operator');
                      this.updateProgressListeners(progress);
                      return [2 /*return*/];
                  }
                });
              });
            }))];
          case 1:
            _a.sent();
            return [2 /*return*/, true];
        }
      });
    });
  };
  DcuplAppLoader.prototype.handleOperatorResources = function (resources, variables, headerConfigs, queryParamConfigs) {
    return __awaiter(this, void 0, void 0, function () {
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, Promise.all(resources.map(function (resource) {
              return __awaiter(_this, void 0, void 0, function () {
                var key, progress_2, operatorFn, progress;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      key = resource.operator;
                      if (this.core['queryManager']['registeredCustomOperator'].has(key)) {
                        progress_2 = this.progressHandler.update(resource, true, 'operator');
                        this.updateProgressListeners(progress_2);
                        return [2 /*return*/, null];
                      }
                      return [4 /*yield*/, this.fetchJavascriptFile(resource, variables, headerConfigs, queryParamConfigs)];
                    case 1:
                      operatorFn = _a.sent();
                      this.core.query.registerCustomOperator(key, operatorFn);
                      progress = this.progressHandler.update(resource, true, 'operator');
                      this.updateProgressListeners(progress);
                      return [2 /*return*/];
                  }
                });
              });
            }))];
          case 1:
            _a.sent();
            return [2 /*return*/, true];
        }
      });
    });
  };
  DcuplAppLoader.prototype.handleTransformerResources = function (resources, variables, headerConfigs, queryParamConfigs) {
    return __awaiter(this, void 0, void 0, function () {
      var relevantTransformers;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            relevantTransformers = [];
            return [4 /*yield*/, Promise.all(resources.map(function (resource) {
              return __awaiter(_this, void 0, void 0, function () {
                var alreadyLoadedTransformer, progress_3, operatorFn, transformerConfigObject, progress;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      alreadyLoadedTransformer = this.registeredTransformers.find(function (t) {
                        return t.key === resource.url;
                      });
                      if (alreadyLoadedTransformer) {
                        relevantTransformers.push(alreadyLoadedTransformer);
                        progress_3 = this.progressHandler.update(resource, true, 'transformer');
                        this.updateProgressListeners(progress_3);
                        return [2 /*return*/];
                      }
                      return [4 /*yield*/, this.fetchJavascriptFile(resource, variables, headerConfigs, queryParamConfigs)];
                    case 1:
                      operatorFn = _a.sent();
                      transformerConfigObject = {
                        key: resource.url,
                        applyTo: resource.applyTo || [],
                        type: resource.transformerType || 'parsedFileTransformer',
                        transformer: operatorFn
                      };
                      this.registeredTransformers.push(transformerConfigObject);
                      relevantTransformers.push(transformerConfigObject);
                      progress = this.progressHandler.update(resource, true, 'transformer');
                      this.updateProgressListeners(progress);
                      return [2 /*return*/];
                  }
                });
              });
            }))];
          case 1:
            _a.sent();
            return [2 /*return*/, relevantTransformers];
        }
      });
    });
  };
  DcuplAppLoader.prototype.fetchResource = function (resource, variables, headerConfigs, queryParamConfigs) {
    var fetchHeaders = this.getHeaderForUrl(resource, variables, headerConfigs);
    var fetchQueryParams = this.getQueryParamsForUrl(resource, variables, queryParamConfigs);
    var url = this.appendQueryParamsToUrl(resource.url, fetchQueryParams);
    return fetch(url.toString(), {
      headers: fetchHeaders
    });
  };
  DcuplAppLoader.prototype.fetchScript = function (resource, variables, headerConfigs, queryParamConfigs) {
    return __awaiter(this, void 0, void 0, function () {
      var response, text, err_4;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 3,, 4]);
            return [4 /*yield*/, this.fetchResource(resource, variables, headerConfigs, queryParamConfigs)];
          case 1:
            response = _a.sent();
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }
            return [4 /*yield*/, response.text()];
          case 2:
            text = _a.sent();
            return [2 /*return*/, text];
          case 3:
            err_4 = _a.sent();
            console.log('could not fetch file', resource.url);
            console.log(err_4);
            return [2 /*return*/, null];
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  DcuplAppLoader.prototype.fetchJavascriptFile = function (resource, variables, headerConfigs, queryParamConfigs) {
    return __awaiter(this, void 0, void 0, function () {
      var response, text, fn, err_5;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 3,, 4]);
            return [4 /*yield*/, this.fetchResource(resource, variables, headerConfigs, queryParamConfigs)];
          case 1:
            response = _a.sent();
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }
            return [4 /*yield*/, response.text()];
          case 2:
            text = _a.sent();
            fn = new Function(text)();
            return [2 /*return*/, fn];
          case 3:
            err_5 = _a.sent();
            console.log('could not fetch file', resource.url);
            console.log(err_5);
            return [2 /*return*/, null];
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  DcuplAppLoader.prototype.handleModelResources = function (resources, variables, headerConfigs, queryParamConfigs) {
    return __awaiter(this, void 0, void 0, function () {
      var modelDownloads, downloadedModels;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            modelDownloads = resources.map(function (resource) {
              return __awaiter(_this, void 0, void 0, function () {
                var start, fetchHeaders, fetchQueryParams, url, response, startTimeParse, jsonResponse, endTimeParse, modelName, performanceEntry, perfEntry, entry, progress, err_6, progress;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      start = new Date().getTime();
                      _a.label = 1;
                    case 1:
                      _a.trys.push([1, 4,, 5]);
                      fetchHeaders = this.getHeaderForUrl(resource, variables, headerConfigs);
                      fetchQueryParams = this.getQueryParamsForUrl(resource, variables, queryParamConfigs);
                      url = this.appendQueryParamsToUrl(resource.url, fetchQueryParams);
                      return [4 /*yield*/, fetch(url.toString(), {
                        headers: fetchHeaders
                      })];
                    case 2:
                      response = _a.sent();
                      startTimeParse = perf.now();
                      return [4 /*yield*/, response.json()];
                    case 3:
                      jsonResponse = _a.sent();
                      endTimeParse = perf.now();
                      modelName = '';
                      if (Array.isArray(jsonResponse)) {
                        modelName = jsonResponse.map(function (definition) {
                          return definition === null || definition === void 0 ? void 0 : definition.key;
                        }).join('__');
                      } else {
                        modelName = jsonResponse.key;
                      }
                      performanceEntry = perf.getEntriesByName(url.toString());
                      if (Array.isArray(performanceEntry) && performanceEntry[0]) {
                        perfEntry = performanceEntry[0];
                        entry = {
                          name: resource.url,
                          type: 'resource',
                          createdAt: new Date().getTime(),
                          value: {
                            model: modelName,
                            type: 'model',
                            start: start,
                            startTime: perfEntry.startTime,
                            requestStart: perfEntry.requestStart,
                            responseEnd: perfEntry.responseEnd,
                            responseStart: perfEntry.responseStart,
                            duration: perfEntry.duration,
                            parseEnd: endTimeParse,
                            parseStart: startTimeParse,
                            decodedBodySize: perfEntry.decodedBodySize,
                            encodedBodySize: perfEntry.encodedBodySize,
                            end: endTimeParse
                          }
                        };
                        this.core['analyticsController'].set({
                          value: entry,
                          context: this.currentContext
                        });
                      }
                      progress = this.progressHandler.update(resource, true, 'model');
                      this.updateProgressListeners(progress);
                      return [2 /*return*/, Promise.resolve(jsonResponse)];
                    case 4:
                      err_6 = _a.sent();
                      console.log('Error fetching the url: ', resource.url);
                      progress = this.progressHandler.update(resource, false, 'model');
                      this.updateProgressListeners(progress);
                      return [2 /*return*/, Promise.resolve(null)];
                    case 5:
                      return [2 /*return*/];
                  }
                });
              });
            });
            return [4 /*yield*/, Promise.all(modelDownloads)];
          case 1:
            downloadedModels = _a.sent();
            downloadedModels.filter(Boolean).map(function (downloadedModelResponse) {
              if (Array.isArray(downloadedModelResponse)) {
                downloadedModelResponse === null || downloadedModelResponse === void 0 ? void 0 : downloadedModelResponse.map(function (responseEntry) {
                  return _this.addModelOrView(responseEntry);
                });
              } else {
                _this.addModelOrView(downloadedModelResponse);
              }
            });
            return [2 /*return*/, true];
        }
      });
    });
  };
  DcuplAppLoader.prototype.appendQueryParamsToUrl = function (resourceUrl, fetchQueryParams) {
    // Check if the URL already has query parameters
    var hasQueryParams = resourceUrl.includes('?');
    var newUrl = resourceUrl;
    if (fetchQueryParams) {
      fetchQueryParams.forEach(function (param, index) {
        // For the first parameter or if the URL already has query parameters, use '?', otherwise use '&'
        var separator = index === 0 && !hasQueryParams ? '?' : '&';
        newUrl += "".concat(separator).concat(encodeURIComponent(param.key), "=").concat(encodeURIComponent(param.value));
      });
    }
    return newUrl;
  };
  DcuplAppLoader.prototype.handleDataResources = function (resources, variables, options, headerConfigs, queryParamConfigs, transformerObjects) {
    return __awaiter(this, void 0, void 0, function () {
      var dataDownloads, downloadedDataContainer;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            dataDownloads = resources.map(function (resource) {
              return __awaiter(_this, void 0, void 0, function () {
                var start, fetchHeaders, fetchQueryParams, url, response, contentTypeResponseHeader, startTimeParse, data, endTimeParse, startRawTransform, transformedData, endRawTransform, items, modelDef, startCsvToJson, endCsvToJson, startParsedTransform, endParsedTransform, container, performanceEntry, perfEntry, entry, progress, err_7, progress;
                var _a, _b, _c, _d, _e;
                return __generator(this, function (_f) {
                  switch (_f.label) {
                    case 0:
                      start = new Date().getTime();
                      _f.label = 1;
                    case 1:
                      _f.trys.push([1, 7,, 8]);
                      fetchHeaders = this.getHeaderForUrl(resource, variables, headerConfigs);
                      fetchQueryParams = this.getQueryParamsForUrl(resource, variables, queryParamConfigs);
                      url = this.appendQueryParamsToUrl(resource.url, fetchQueryParams);
                      return [4 /*yield*/, fetch(url.toString(), {
                        headers: fetchHeaders
                      })];
                    case 2:
                      response = _f.sent();
                      contentTypeResponseHeader = response.headers.get('content-type');
                      startTimeParse = perf.now();
                      data = void 0;
                      if (!(resource.url.endsWith('.csv') || (contentTypeResponseHeader === null || contentTypeResponseHeader === void 0 ? void 0 : contentTypeResponseHeader.includes('text/csv')))) return [3 /*break*/, 4];
                      return [4 /*yield*/, response.text()];
                    case 3:
                      data = _f.sent();
                      return [3 /*break*/, 6];
                    case 4:
                      return [4 /*yield*/, response.json()];
                    case 5:
                      data = _f.sent();
                      _f.label = 6;
                    case 6:
                      endTimeParse = perf.now();
                      startRawTransform = perf.now();
                      transformedData = this.handleTransformerLogic(data, resource, 'rawFileTransformer', transformerObjects || []);
                      endRawTransform = perf.now();
                      items = [];
                      modelDef = this.core['modelParser'].unprocessedModelDescriptions.get(resource.model);
                      if (!modelDef && (((_a = options === null || options === void 0 ? void 0 : options.missingModelHandling) === null || _a === void 0 ? void 0 : _a.autoGenerateProperties) || ((_b = resource.options) === null || _b === void 0 ? void 0 : _b.autoGenerateProperties))) {
                        this.addModelOrView({
                          key: resource.model,
                          autoGenerateProperties: true,
                          autoGenerateKey: ((_c = resource.options) === null || _c === void 0 ? void 0 : _c.autoGenerateKey) || false,
                          keyProperty: ((_d = resource === null || resource === void 0 ? void 0 : resource.options) === null || _d === void 0 ? void 0 : _d.keyProperty) || 'key'
                        });
                        modelDef = this.core['modelParser'].unprocessedModelDescriptions.get(resource.model);
                      }
                      startCsvToJson = perf.now();
                      if (resource.url.endsWith('.csv') || (contentTypeResponseHeader === null || contentTypeResponseHeader === void 0 ? void 0 : contentTypeResponseHeader.includes('text/csv'))) {
                        if (modelDef) {
                          items = this.csvParser.getJsonFromCSVString(transformedData, modelDef, resource);
                        }
                      } else {
                        items = transformedData;
                      }
                      endCsvToJson = perf.now();
                      startParsedTransform = perf.now();
                      try {
                        items = this.handleTransformerLogic(items, resource, 'parsedFileTransformer', transformerObjects || []);
                      } catch (err) {
                        console.log('Transformer Error');
                        console.log(err);
                      }
                      endParsedTransform = perf.now();
                      container = {
                        model: resource.model,
                        data: items,
                        type: ((_e = resource.options) === null || _e === void 0 ? void 0 : _e.updateType) || options.defaultDataContainerUpdateType
                      };
                      performanceEntry = perf.getEntriesByName(url.toString());
                      if (Array.isArray(performanceEntry) && performanceEntry[0]) {
                        perfEntry = performanceEntry[0];
                        entry = {
                          name: resource.url,
                          type: 'resource',
                          createdAt: new Date().getTime(),
                          value: {
                            model: resource.model,
                            type: 'data',
                            start: start,
                            startTime: perfEntry.startTime,
                            requestStart: perfEntry.requestStart,
                            responseEnd: perfEntry.responseEnd,
                            responseStart: perfEntry.responseStart,
                            duration: perfEntry.duration,
                            parseEnd: endTimeParse,
                            rawTransformStart: startRawTransform,
                            rawTransformEnd: endRawTransform,
                            parseStart: startTimeParse,
                            csvToJsonStart: startCsvToJson,
                            csvToJsonEnd: endCsvToJson,
                            parsedTransformStart: startParsedTransform,
                            parsedTransformEnd: endParsedTransform,
                            decodedBodySize: perfEntry.decodedBodySize,
                            encodedBodySize: perfEntry.encodedBodySize,
                            end: endTimeParse
                          }
                        };
                        this.core['analyticsController'].set({
                          value: entry,
                          context: this.currentContext
                        });
                      }
                      progress = this.progressHandler.update(resource, true, 'data');
                      this.updateProgressListeners(progress);
                      return [2 /*return*/, Promise.resolve(container)];
                    case 7:
                      err_7 = _f.sent();
                      console.log('Error fetching the url: ', resource.url);
                      console.log(err_7);
                      progress = this.progressHandler.update(resource, false, 'data');
                      this.updateProgressListeners(progress);
                      return [2 /*return*/, Promise.resolve()];
                    case 8:
                      return [2 /*return*/];
                  }
                });
              });
            });
            return [4 /*yield*/, Promise.all(dataDownloads)];
          case 1:
            downloadedDataContainer = _a.sent();
            downloadedDataContainer.filter(Boolean).map(function (container) {
              return _this.core.data.apply(container);
            });
            return [2 /*return*/, true];
        }
      });
    });
  };
  DcuplAppLoader.prototype.fetchResources = function (resources, variables, options, headerConfigs, queryParamConfigs) {
    return __awaiter(this, void 0, void 0, function () {
      var scripts, operators, transformers, relevantTransformers, models, dataResources, err_8;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.progressHandler = new AppLoaderProgressHandler();
            this.progressHandler.init(resources);
            this.updateProgressListeners(this.progressHandler.getProgress());
            _a.label = 1;
          case 1:
            _a.trys.push([1, 7,, 8]);
            if (!this.core) {
              console.error('dcupl not connected. Did you add your loader to dcupl? -> dcupl.addRunner(loader)');
              throw new Error();
            }
            scripts = resources.filter(function (resource) {
              return resource.type === 'script';
            });
            return [4 /*yield*/, this.handleScriptResources(scripts, variables, headerConfigs, queryParamConfigs)];
          case 2:
            _a.sent();
            operators = resources.filter(function (resource) {
              return resource.type === 'operator';
            });
            return [4 /*yield*/, this.handleOperatorResources(operators, variables, headerConfigs, queryParamConfigs)];
          case 3:
            _a.sent();
            transformers = resources.filter(function (resource) {
              return resource.type === 'transformer';
            });
            return [4 /*yield*/, this.handleTransformerResources(transformers, variables, headerConfigs, queryParamConfigs)];
          case 4:
            relevantTransformers = _a.sent();
            models = resources.filter(function (resource) {
              return resource.type === 'model';
            });
            return [4 /*yield*/, this.handleModelResources(models, variables, headerConfigs, queryParamConfigs)];
          case 5:
            _a.sent();
            dataResources = resources.filter(function (resource) {
              return resource.type === 'data';
            });
            return [4 /*yield*/, this.handleDataResources(dataResources, variables, options, headerConfigs, queryParamConfigs, relevantTransformers)];
          case 6:
            _a.sent();
            return [2 /*return*/, true];
          case 7:
            err_8 = _a.sent();
            console.log(err_8);
            return [2 /*return*/, false];
          case 8:
            return [2 /*return*/];
        }
      });
    });
  };
  DcuplAppLoader.prototype.addModelOrView = function (modelOrView) {
    try {
      if ('model' in modelOrView) {
        this.core.views.set(modelOrView);
      } else {
        this.core.models.set(modelOrView);
      }
    } catch (err) {
      console.log(err);
    }
  };
  DcuplAppLoader.prototype.handleTransformerLogic = function (data, resource, type, transformers) {
    var e_4, _a;
    var _b;
    try {
      for (var transformers_1 = __values(transformers), transformers_1_1 = transformers_1.next(); !transformers_1_1.done; transformers_1_1 = transformers_1.next()) {
        var transformerConfig = transformers_1_1.value;
        if (transformerConfig.type !== type) {
          continue;
        }
        if (!transformerConfig.transformer) {
          continue;
        }
        if (!transformerConfig.applyTo.length && !((_b = resource.tags) === null || _b === void 0 ? void 0 : _b.length)) {
          return transformerConfig.transformer(resource, data);
        }
        if (intersection(transformerConfig.applyTo, resource.tags || []).length) {
          return transformerConfig.transformer(resource, data);
        }
      }
    } catch (e_4_1) {
      e_4 = {
        error: e_4_1
      };
    } finally {
      try {
        if (transformers_1_1 && !transformers_1_1.done && (_a = transformers_1.return)) _a.call(transformers_1);
      } finally {
        if (e_4) throw e_4.error;
      }
    }
    return data;
  };
  DcuplAppLoader.prototype.updateProgressListeners = function (progress) {
    this.progressListeners.forEach(function (cb) {
      return cb(progress);
    });
  };
  DcuplAppLoader.prototype.on = function (cb) {
    var _this = this;
    this.progressListeners.add(cb);
    return function () {
      _this.progressListeners.delete(cb);
    };
  };
  __decorate([trigger({
    scope: 'global',
    origin: 'loader',
    name: 'loader:process',
    type: 'loader_fetch_finished'
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Promise)], DcuplAppLoader.prototype, "process", null);
  return DcuplAppLoader;
}();
export { DcuplAppLoader };
