var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import difference from 'lodash/difference';
import orderBy from 'lodash/orderBy';
var ItemService = /** @class */function () {
  function ItemService(modelParser, scriptController) {
    this.modelParser = modelParser;
    this.scriptController = scriptController;
  }
  ItemService.prototype.getItem = function (itemId, dataAsMap, model, options) {
    var projection = (options === null || options === void 0 ? void 0 : options.projection) || {
      $: true
    };
    var dataEntry = dataAsMap.get(itemId);
    if (!dataEntry) {
      return null;
    }
    var objToReturn = this.getProjectedData(projection, model, dataEntry);
    return objToReturn;
  };
  ItemService.prototype.getManyItems = function (itemKeys, dataAsMap, model, options) {
    var e_1, _a;
    var items = [];
    try {
      for (var itemKeys_1 = __values(itemKeys), itemKeys_1_1 = itemKeys_1.next(); !itemKeys_1_1.done; itemKeys_1_1 = itemKeys_1.next()) {
        var key = itemKeys_1_1.value;
        var projection = (options === null || options === void 0 ? void 0 : options.projection) || {
          $: true
        };
        var dataEntry = dataAsMap.get(key);
        if (!dataEntry) {
          continue;
        }
        var objToReturn = this.getProjectedData(projection, model, dataEntry);
        items.push(objToReturn);
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (itemKeys_1_1 && !itemKeys_1_1.done && (_a = itemKeys_1.return)) _a.call(itemKeys_1);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
    return items;
  };
  ItemService.prototype.getItems = function (dataAsMap, model, options) {
    var result = this.getItemsAsMap(dataAsMap, model, options);
    return Array.from(result.values());
  };
  ItemService.prototype.getItemsAsMap = function (dataAsMap, model, options) {
    var start = (options === null || options === void 0 ? void 0 : options.start) || 0;
    var count = (options === null || options === void 0 ? void 0 : options.count) || dataAsMap.size;
    var projection = (options === null || options === void 0 ? void 0 : options.projection) || {
      $: true
    };
    if (this.isValidSortProjection(options === null || options === void 0 ? void 0 : options.sort)) {
      dataAsMap = this.getSortedItems(dataAsMap, options, model);
    }
    if (this.isValidScriptSorting(options === null || options === void 0 ? void 0 : options.sort)) {
      dataAsMap = this.getSortedItemsBasedOnScript(dataAsMap, options);
    }
    var result = this.getPagedData(dataAsMap, start, count, projection, model);
    return result;
  };
  ItemService.prototype.getSortedItemsBasedOnScript = function (data, options) {
    var _a, _b, _c, _d;
    var dataAsArray = Array.from(data.values());
    var scriptName = ((_b = (_a = options === null || options === void 0 ? void 0 : options.sort) === null || _a === void 0 ? void 0 : _a.script) === null || _b === void 0 ? void 0 : _b.key) || '';
    var sortOptions = ((_d = (_c = options === null || options === void 0 ? void 0 : options.sort) === null || _c === void 0 ? void 0 : _c.script) === null || _d === void 0 ? void 0 : _d.options) || undefined;
    if (!this.scriptController.hasScript(scriptName)) {
      return data;
    }
    var sortedArray = this.scriptController.executeScript(scriptName, 'sort', {
      options: sortOptions,
      items: dataAsArray
    });
    return new Map(sortedArray.map(function (object) {
      return [object.key, object];
    }));
  };
  ItemService.prototype.getSortedItems = function (data, options, model) {
    var _this = this;
    var attributes = __spreadArray([], __read(options.sort.attributes), false);
    var dataAsArray = Array.from(data.values());
    var dataTransformer = function (listItem, attr, undefinedValue) {
      var value = listItem[attr];
      if (typeof value === 'undefined' || value === null) {
        return undefinedValue;
      }
      if (typeof value === 'string') {
        return value.toLowerCase();
      }
      if (typeof value === 'object') {
        if (typeof (value === null || value === void 0 ? void 0 : value.key) === 'string') {
          return value.key.toLowerCase();
        }
      }
      return value;
    };
    var transformerFn = attributes.map(function (attribute, index) {
      return function (entry) {
        var relevantOrder = options.sort.order.at(index);
        var undefinedValue = _this.getUndefinedValueForSorting(attribute, relevantOrder, model);
        return dataTransformer(entry, attribute, undefinedValue);
      };
    });
    var order = __spreadArray([], __read(options.sort.order), false).map(function (o) {
      return o.toLowerCase();
    });
    var sortedArray = orderBy(dataAsArray, __spreadArray([], __read(transformerFn), false), order);
    return new Map(sortedArray.map(function (object) {
      return [object.key, object];
    }));
  };
  ItemService.prototype.getUndefinedValueForSorting = function (attribute, direction, model) {
    var property = model === null || model === void 0 ? void 0 : model.properties.get(attribute);
    if (property) {
      if (property.type === 'string') {
        if (direction == 'ASC') {
          return undefined;
        }
        if (direction == 'DESC') {
          return '';
        }
      }
      if (property.type === 'int' || property.type === 'float' || property.type === 'date' || property.type === 'boolean') {
        if (direction == 'ASC') {
          return Infinity;
        }
        if (direction == 'DESC') {
          return -Infinity;
        }
      }
    }
  };
  ItemService.prototype.isValidProjection = function (projection) {
    if (!projection) {
      return false;
    }
    if (projection.$ === true && Object.keys(projection).length === 1) {
      return false;
    }
    return true;
  };
  ItemService.prototype.getPagedData = function (data, start, count, projection, model) {
    var it = data.entries();
    var pagedData = new Map();
    // fast return - if we want to return the full default-projected dataset
    if (start === 0 && count === data.size && (projection === null || projection === void 0 ? void 0 : projection.$) === true && Object.keys(projection).length === 1) {
      return data;
    }
    for (var i = 0; i < start + count; i++) {
      if (i < start) {
        it.next();
        continue;
      }
      if (i > start + count) {
        break;
      }
      var item = it.next().value;
      if (item) {
        if (model && projection && this.isValidProjection(projection)) {
          var objToReturn = this.getProjectedData(projection, model, item[1]);
          pagedData.set(item[0], objToReturn);
        } else {
          pagedData.set(item[0], item[1]);
        }
      }
    }
    return pagedData;
  };
  ItemService.prototype.isValidScriptSorting = function (sort) {
    if (!sort) {
      return false;
    }
    if (!sort.script) {
      return false;
    }
    return true;
  };
  ItemService.prototype.isValidSortProjection = function (sort) {
    var validOrderValues = ['ASC', 'DESC'];
    if (!sort || !Array.isArray(sort.attributes) || sort.attributes.length === 0 || !Array.isArray(sort.order) || sort.attributes.length !== sort.order.length) {
      return false;
    }
    if (difference(sort.order, validOrderValues).length > 0) {
      return false;
    }
    return true;
  };
  ItemService.prototype.getProjectedData = function (projection, model, dataEntry) {
    var _this = this;
    var objToReturn = {
      key: dataEntry.key
    };
    if (projection.$ === true) {
      objToReturn = Object.assign({}, dataEntry);
    }
    if (projection.$$ === true) {
      objToReturn = Object.assign({}, dataEntry);
      model.references.forEach(function (ref) {
        if (!projection[ref.key]) {
          projection[ref.key] = {
            $: true
          };
        }
      });
    }
    var keys = Object.keys(projection);
    keys.filter(function (key) {
      return key !== '$' && key !== '$$';
    }).forEach(function (key) {
      var projectionValue = projection[key];
      if (projectionValue === false) {
        delete objToReturn[key];
        return;
      }
      if (projectionValue === true) {
        objToReturn[key] = dataEntry[key];
        return;
      }
      if (typeof projectionValue === 'object') {
        var reference = model.references.get(key);
        if (reference) {
          var remoteModel_1 = _this.modelParser.models.get(reference.model);
          if (remoteModel_1) {
            if (reference.type === 'multiValued') {
              var value = dataEntry[key] || [];
              objToReturn[key] = value.map(function (entryKey) {
                var entry = remoteModel_1.getEntry(entryKey.key);
                if (entry) {
                  return _this.getProjectedData(projectionValue, remoteModel_1, entry);
                }
                return null;
              }).filter(Boolean);
            } else if (reference.type === 'singleValued') {
              var value = dataEntry[key];
              if (value) {
                var entry = remoteModel_1 === null || remoteModel_1 === void 0 ? void 0 : remoteModel_1.getEntry(value.key);
                if (entry) {
                  objToReturn[key] = _this.getProjectedData(projectionValue, remoteModel_1, entry);
                }
              }
            }
          }
        }
        // todo: if a property is projected using $:true it should be returned...
      }
    });
    return objToReturn;
  };
  return ItemService;
}();
export { ItemService };
