var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { DepGraph } from 'dependency-graph';
import { generateKey, trigger } from '@dcupl/common';
import { DcuplModel } from './model/model';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import merge from 'lodash/merge';
import { validateModels } from './model/model.helper';
var ModelParser = /** @class */function () {
  function ModelParser(cdRef) {
    this.cdRef = cdRef;
    this.unprocessedModelDescriptions = new Map();
    this.unprocessedData = [];
    this.views = new Map();
    this.models = new Map();
    this.calculationTimings = [];
    this.errors = [];
  }
  ModelParser.prototype.getSortedCalculationTimings = function () {
    return orderBy(this.calculationTimings, 'value');
  };
  ModelParser.prototype.addModel = function (model) {
    if (!model || !model.key) {
      console.log(model);
      throw new Error('Invalid Model');
    }
    if (model.data) {
      this.addData({
        model: model.key,
        data: model.data
      });
    }
    if (this.unprocessedModelDescriptions.has(model.key)) {
      var existingModel = this.unprocessedModelDescriptions.get(model.key);
      var newModel = this.mergeModels(existingModel, model);
      this.unprocessedModelDescriptions.set(model.key, newModel);
    } else {
      this.unprocessedModelDescriptions.set(model.key, model);
    }
  };
  ModelParser.prototype.mergeModels = function (modelA, modelB) {
    var _a, _b;
    var newModel = Object.assign({}, modelA);
    if (Array.isArray(modelA.properties)) {
      newModel.properties = __spreadArray([], __read(modelA.properties), false);
    }
    if (Array.isArray(modelA.references)) {
      newModel.references = __spreadArray([], __read(modelA.references), false);
    }
    if (Array.isArray(modelB.properties)) {
      (_a = newModel.properties) === null || _a === void 0 ? void 0 : _a.push.apply(_a, __spreadArray([], __read(modelB.properties), false));
    }
    if (Array.isArray(modelB.references)) {
      (_b = newModel.references) === null || _b === void 0 ? void 0 : _b.push.apply(_b, __spreadArray([], __read(modelB.references), false));
    }
    return newModel;
  };
  ModelParser.prototype.addData = function (container) {
    if (!container || !container.model || !container.data) {
      console.log(container);
      throw new Error('Invalid Data Container');
    }
    if (!container.type) {
      container.type = 'set';
    }
    if (container.autoGenerateProperties) {
      this.addModel({
        key: container.model,
        autoGenerateProperties: true,
        autoGenerateKey: container.autoGenerateKey,
        keyProperty: container.keyProperty || 'key'
      });
    }
    this.unprocessedData.push(container);
  };
  ModelParser.prototype.addView = function (view) {
    this.views.set(view.key, view);
  };
  ModelParser.prototype.init = function () {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var relevantData;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            this.prepareModels();
            this.initDependencyGraph();
            relevantData = this.getMergedContainers(this.unprocessedData);
            return [4 /*yield*/, this.connectDataWithModels(relevantData)];
          case 1:
            _b.sent();
            if (!((_a = this.initOptions.errorTracking) === null || _a === void 0 ? void 0 : _a.enabled)) return [3 /*break*/, 3];
            return [4 /*yield*/, this.handleErrors()];
          case 2:
            _b.sent();
            _b.label = 3;
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  ModelParser.prototype.update = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.init()];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  ModelParser.prototype.reprocessData = function () {
    return __awaiter(this, void 0, void 0, function () {
      var relevantData;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            relevantData = this.getMergedContainers(this.unprocessedData);
            return [4 /*yield*/, this.connectDataWithModels(relevantData)];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  ModelParser.prototype.prepareModels = function () {
    var _this = this;
    var _a, _b;
    if ((_a = this.initOptions.errorTracking) === null || _a === void 0 ? void 0 : _a.enabled) {
      this.initErrorTrackingModel();
    }
    this.unprocessedModelDescriptions.forEach(function (modelDescription) {
      if (_this.models.has(modelDescription.key)) {
        _this.models.get(modelDescription.key).applyPropertiesAndReferences(modelDescription);
      } else {
        var model = new DcuplModel(_this);
        model.init(modelDescription);
        _this.models.set(model.key, model);
      }
    });
    if ((_b = this.initOptions.errorTracking) === null || _b === void 0 ? void 0 : _b.enabled) {
      this.models.forEach(function (model) {
        var _a;
        var errors = validateModels(model, _this.models);
        (_a = _this.errors).push.apply(_a, __spreadArray([], __read(errors), false));
      });
    }
  };
  /**
   * initDependencyGraph
   * The dependencies are only affected by references. Derived properties/references do not count since they
   * are only possible if references are already present to derive from. Same goes for resolved references.
   */
  ModelParser.prototype.initDependencyGraph = function () {
    var _this = this;
    this.dependencyGraph = new DepGraph({
      circular: false
    });
    this.models.forEach(function (model) {
      _this.dependencyGraph.addNode(model.key);
    });
    this.models.forEach(function (model) {
      model.getBasicReferences().forEach(function (reference) {
        if (model.key === reference.model) {
          return;
        }
        _this.dependencyGraph.addNode(reference.model);
        _this.dependencyGraph.addDependency(model.key, reference.model);
      });
    });
  };
  ModelParser.prototype.getMergedContainers = function (containers) {
    var newContainers = this.handleAlternativeKey(containers);
    var mergedContainers = [];
    var groupedContainers = groupBy(newContainers, 'model');
    for (var key in groupedContainers) {
      var containerGroup = groupedContainers[key];
      if (containerGroup.length > 1) {
        var container = this.mergeContainers(containerGroup);
        mergedContainers.push(container);
      } else {
        mergedContainers.push(containerGroup[0]);
      }
    }
    return mergedContainers;
  };
  ModelParser.prototype.mergeContainers = function (containers) {
    var dataset;
    containers === null || containers === void 0 ? void 0 : containers.forEach(function (newContainer) {
      if (!dataset) {
        if (newContainer.type === 'remove') {
          return;
        }
        dataset = new Map(newContainer.data.map(function (object) {
          return [object.key, object];
        }));
        return;
      }
      switch (newContainer.type) {
        case 'upsert':
          newContainer.data.forEach(function (entry) {
            var itemExists = dataset.has(entry.key);
            if (itemExists) {
              var item = dataset.get(entry.key);
              item = merge(item, entry);
              dataset.set(entry.key, item);
            } else {
              dataset.set(entry.key, entry);
            }
          });
          break;
        case 'update':
          newContainer.data.forEach(function (entry) {
            var itemExists = dataset.has(entry.key);
            if (itemExists) {
              var item = dataset.get(entry.key);
              item = merge(item, entry);
              dataset.set(entry.key, item);
            }
          });
          break;
        case 'remove':
          newContainer.data.forEach(function (entry) {
            dataset.delete(entry.key);
          });
          break;
        case 'set':
          dataset = new Map(newContainer.data.map(function (object) {
            return [object.key, object];
          }));
          break;
        default:
          dataset = new Map(newContainer.data.map(function (object) {
            return [object.key, object];
          }));
          break;
      }
    });
    var container = {
      model: containers[0].model,
      data: Array.from(dataset.values()),
      type: 'set'
    };
    return container;
  };
  ModelParser.prototype.connectDataWithModels = function (containers) {
    return __awaiter(this, void 0, void 0, function () {
      var dependencyError, orderedModelKeys;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            try {
              this.dependencyGraph['circular'] = false;
              this.dependencyGraph.overallOrder();
            } catch (error) {
              dependencyError = error;
              console.warn('Your models have circular dependencies which might lead to errors. Please consider changing your models');
              console.error(error);
              console.log(dependencyError.cyclePath);
            }
            this.dependencyGraph['circular'] = true;
            orderedModelKeys = this.dependencyGraph.overallOrder();
            containers.sort(function (a, b) {
              return orderedModelKeys.indexOf(a.model) - orderedModelKeys.indexOf(b.model);
            });
            return [4 /*yield*/, this.handleSets(containers)];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  ModelParser.prototype.handleAlternativeKey = function (containers) {
    var _this = this;
    containers.forEach(function (container) {
      var model = _this.models.get(container.model);
      var keyProperty = container.keyProperty || (model === null || model === void 0 ? void 0 : model.keyProperty);
      var autoGenerateKey = container.autoGenerateKey || (model === null || model === void 0 ? void 0 : model.autoGenerateKey);
      if (keyProperty && keyProperty !== 'key') {
        container.data.forEach(function (item) {
          item.key = String(item[keyProperty]);
        });
      } else if (autoGenerateKey) {
        container.data.forEach(function (item) {
          if (!item.key) {
            item.key = generateKey();
          }
        });
      }
    });
    return containers;
  };
  ModelParser.prototype.handleSets = function (containers) {
    return __awaiter(this, void 0, void 0, function () {
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            containers.forEach(function (container) {
              var model = _this.models.get(container.model);
              model === null || model === void 0 ? void 0 : model.resetData();
              model === null || model === void 0 ? void 0 : model.resetIndexMap();
            });
            if (!(containers.length > 0)) return [3 /*break*/, 2];
            return [4 /*yield*/, this.handleDataContainer(containers)];
          case 1:
            _a.sent();
            _a.label = 2;
          case 2:
            return [2 /*return*/];
        }
      });
    });
  };
  ModelParser.prototype.handleErrors = function () {
    return __awaiter(this, void 0, void 0, function () {
      var errorContainers;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            errorContainers = [];
            this.models.forEach(function (model) {
              var errors = __spreadArray([], __read(model['errors']), false);
              errors.unshift.apply(errors, __spreadArray([], __read(_this.errors), false));
              errorContainers.push({
                model: '$DcuplErrorTrackingErrors',
                data: errors
              });
            });
            this.handleAlternativeKey(errorContainers);
            return [4 /*yield*/, this.handleSets(errorContainers)];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  ModelParser.prototype.handleMissingData = function (model, dataContainer, detail) {
    var modelErrors = model['errors'];
    var undefinedValues = modelErrors.filter(function (error) {
      return error.errorType === detail;
    });
    if (undefinedValues) {
      var groupedByAttribute = groupBy(undefinedValues, 'attribute');
      var _loop_1 = function (key) {
        if (groupedByAttribute[key] && groupedByAttribute[key].length === dataContainer.data.length) {
          modelErrors = modelErrors.filter(function (error) {
            if (error.attribute === key && error.errorType === detail) {
              return false;
            }
            return true;
          });
          modelErrors.push({
            errorGroup: 'DataContainerError',
            errorType: 'UndefinedAttribute',
            itemKey: dataContainer.model,
            model: dataContainer.model,
            attribute: key,
            description: "The Attribute \"".concat(key, "\" is not present in the dataset for model \"").concat(dataContainer.model, "\"")
          });
        }
      };
      /**
       * Filter out "UndefinedValue" errors if the attribute is not in the data at all.
       * Add an general "DataContainerError" instead
       */
      for (var key in groupedByAttribute) {
        _loop_1(key);
      }
    }
    model['errors'] = modelErrors;
  };
  ModelParser.prototype.initErrorTrackingModel = function () {
    this.addModel({
      key: '$DcuplErrorTrackingModel',
      supportsAutoCreation: true
    });
    this.addModel({
      key: '$DcuplErrorTrackingType',
      supportsAutoCreation: true,
      data: [{
        key: 'UndefinedAttribute'
      }, {
        key: 'UndefinedValue'
      }, {
        key: 'WrongDataType'
      }, {
        key: 'MissingModel'
      }, {
        key: 'RemoteReferenceKeyNotFound'
      }, {
        key: 'InvalidModelDescription'
      }]
    });
    this.addModel({
      key: '$DcuplErrorTrackingGroup',
      supportsAutoCreation: true,
      data: [{
        key: 'ReferenceDataError'
      }, {
        key: 'PropertyDataError'
      }, {
        key: 'ReferenceModelError'
      }, {
        key: 'DataContainerError'
      }, {
        key: 'ModelDescriptionError'
      }]
    });
    this.addModel({
      key: '$DcuplErrorTrackingErrors',
      autoGenerateKey: true,
      supportsAutoCreation: true,
      references: [{
        key: 'model',
        type: 'singleValued',
        model: '$DcuplErrorTrackingModel',
        filter: {
          calculateFacets: true
        }
      }, {
        key: 'errorGroup',
        type: 'singleValued',
        model: '$DcuplErrorTrackingGroup',
        filter: {
          calculateFacets: true
        }
      }, {
        key: 'errorType',
        type: 'singleValued',
        model: '$DcuplErrorTrackingType',
        filter: {
          calculateFacets: true
        }
      }],
      properties: [{
        key: 'itemKey',
        type: 'string',
        filter: true
      }, {
        key: 'attribute',
        type: 'string',
        filter: true
      }, {
        key: 'description',
        type: 'string'
      }, {
        key: 'meta',
        type: 'json'
      }]
    });
  };
  ModelParser.prototype.cleanUpErrors = function (model, dataContainer) {
    this.handleMissingData(model, dataContainer, 'UndefinedValue');
    this.handleMissingData(model, dataContainer, 'RemoteReferenceKeyNotFound');
  };
  ModelParser.prototype.handleDataContainer = function (containers) {
    return __awaiter(this, void 0, void 0, function () {
      var containers_1, containers_1_1, dataContainer, model, _loop_2, containers_2, containers_2_1, dataContainer, e_1_1, _loop_3, containers_3, containers_3_1, dataContainer, e_2_1, _loop_4, containers_4, containers_4_1, dataContainer, e_3_1, _loop_5, containers_5, containers_5_1, dataContainer, e_4_1;
      var e_5, _a, e_1, _b, e_2, _c, e_3, _d, e_4, _e;
      var _this = this;
      return __generator(this, function (_f) {
        switch (_f.label) {
          case 0:
            try {
              for (containers_1 = __values(containers), containers_1_1 = containers_1.next(); !containers_1_1.done; containers_1_1 = containers_1.next()) {
                dataContainer = containers_1_1.value;
                model = this.models.get(dataContainer.model);
                if (model === null || model === void 0 ? void 0 : model.autoGenerateProperties) {
                  model.generatePropertiesFromContainer(dataContainer);
                }
              }
            } catch (e_5_1) {
              e_5 = {
                error: e_5_1
              };
            } finally {
              try {
                if (containers_1_1 && !containers_1_1.done && (_a = containers_1.return)) _a.call(containers_1);
              } finally {
                if (e_5) throw e_5.error;
              }
            }
            _loop_2 = function (dataContainer) {
              return __generator(this, function (_g) {
                switch (_g.label) {
                  case 0:
                    return [4 /*yield*/, new Promise(function (resolve) {
                      setTimeout(function () {
                        var _a;
                        var model = _this.models.get(dataContainer.model);
                        var context = generateKey();
                        _this.cdRef.analyticsController.mark({
                          name: "dcupl:process_data:step_1:start",
                          context: context
                        });
                        if (model) {
                          model.addBasicPropertiesAndReferences(dataContainer);
                          if ((_a = _this.initOptions.errorTracking) === null || _a === void 0 ? void 0 : _a.enabled) {
                            _this.cleanUpErrors(model, dataContainer);
                          }
                        }
                        _this.cdRef.analyticsController.mark({
                          name: "dcupl:process_data:step_1:end",
                          context: context
                        });
                        _this.cdRef.analyticsController.measure({
                          name: "dcupl:process_data:step_1",
                          start: "dcupl:process_data:step_1:start",
                          end: "dcupl:process_data:step_1:end",
                          context: context,
                          detail: {
                            model: dataContainer.model
                          }
                        });
                        return resolve(true);
                      }, 0);
                    })];
                  case 1:
                    _g.sent();
                    return [2 /*return*/];
                }
              });
            };
            _f.label = 1;
          case 1:
            _f.trys.push([1, 6, 7, 8]);
            containers_2 = __values(containers), containers_2_1 = containers_2.next();
            _f.label = 2;
          case 2:
            if (!!containers_2_1.done) return [3 /*break*/, 5];
            dataContainer = containers_2_1.value;
            return [5 /*yield**/, _loop_2(dataContainer)];
          case 3:
            _f.sent();
            _f.label = 4;
          case 4:
            containers_2_1 = containers_2.next();
            return [3 /*break*/, 2];
          case 5:
            return [3 /*break*/, 8];
          case 6:
            e_1_1 = _f.sent();
            e_1 = {
              error: e_1_1
            };
            return [3 /*break*/, 8];
          case 7:
            try {
              if (containers_2_1 && !containers_2_1.done && (_b = containers_2.return)) _b.call(containers_2);
            } finally {
              if (e_1) throw e_1.error;
            }
            return [7 /*endfinally*/];
          case 8:
            _loop_3 = function (dataContainer) {
              return __generator(this, function (_h) {
                switch (_h.label) {
                  case 0:
                    return [4 /*yield*/, new Promise(function (resolve) {
                      setTimeout(function () {
                        var model = _this.models.get(dataContainer.model);
                        var context = generateKey();
                        _this.cdRef.analyticsController.mark({
                          name: "dcupl:process_data:step_2:start",
                          context: context
                        });
                        if (model && model.hasResolvedReferences()) {
                          model.addResolvedReferences(dataContainer);
                        }
                        _this.cdRef.analyticsController.mark({
                          name: "dcupl:process_data:step_2:end",
                          context: context
                        });
                        _this.cdRef.analyticsController.measure({
                          name: "dcupl:process_data:step_2",
                          start: "dcupl:process_data:step_2:start",
                          end: "dcupl:process_data:step_2:end",
                          context: context,
                          detail: {
                            model: dataContainer.model
                          }
                        });
                        return resolve(true);
                      }, 0);
                    })];
                  case 1:
                    _h.sent();
                    return [2 /*return*/];
                }
              });
            };
            _f.label = 9;
          case 9:
            _f.trys.push([9, 14, 15, 16]);
            containers_3 = __values(containers), containers_3_1 = containers_3.next();
            _f.label = 10;
          case 10:
            if (!!containers_3_1.done) return [3 /*break*/, 13];
            dataContainer = containers_3_1.value;
            return [5 /*yield**/, _loop_3(dataContainer)];
          case 11:
            _f.sent();
            _f.label = 12;
          case 12:
            containers_3_1 = containers_3.next();
            return [3 /*break*/, 10];
          case 13:
            return [3 /*break*/, 16];
          case 14:
            e_2_1 = _f.sent();
            e_2 = {
              error: e_2_1
            };
            return [3 /*break*/, 16];
          case 15:
            try {
              if (containers_3_1 && !containers_3_1.done && (_c = containers_3.return)) _c.call(containers_3);
            } finally {
              if (e_2) throw e_2.error;
            }
            return [7 /*endfinally*/];
          case 16:
            _loop_4 = function (dataContainer) {
              return __generator(this, function (_j) {
                switch (_j.label) {
                  case 0:
                    return [4 /*yield*/, new Promise(function (resolve) {
                      setTimeout(function () {
                        var model = _this.models.get(dataContainer.model);
                        var context = generateKey();
                        _this.cdRef.analyticsController.mark({
                          name: "dcupl:process_data:step_3:start",
                          context: context
                        });
                        if (model && (model.hasDerivedPropertiesForBasicReferences() || (model === null || model === void 0 ? void 0 : model.hasDerivedReferences()) || (model === null || model === void 0 ? void 0 : model.hasGroupedReferences()) || (model === null || model === void 0 ? void 0 : model.hasExpressionProperties()))) {
                          model.addDerivedReferencesGroupedReferencesDerivedPropertiesAndExpressionProperties(dataContainer);
                        }
                        _this.cdRef.analyticsController.mark({
                          name: "dcupl:process_data:step_3:end",
                          context: context
                        });
                        _this.cdRef.analyticsController.measure({
                          name: "dcupl:process_data:step_3",
                          start: "dcupl:process_data:step_3:start",
                          end: "dcupl:process_data:step_3:end",
                          context: context,
                          detail: {
                            model: dataContainer.model
                          }
                        });
                        return resolve(true);
                      }, 0);
                    })];
                  case 1:
                    _j.sent();
                    return [2 /*return*/];
                }
              });
            };
            _f.label = 17;
          case 17:
            _f.trys.push([17, 22, 23, 24]);
            containers_4 = __values(containers), containers_4_1 = containers_4.next();
            _f.label = 18;
          case 18:
            if (!!containers_4_1.done) return [3 /*break*/, 21];
            dataContainer = containers_4_1.value;
            return [5 /*yield**/, _loop_4(dataContainer)];
          case 19:
            _f.sent();
            _f.label = 20;
          case 20:
            containers_4_1 = containers_4.next();
            return [3 /*break*/, 18];
          case 21:
            return [3 /*break*/, 24];
          case 22:
            e_3_1 = _f.sent();
            e_3 = {
              error: e_3_1
            };
            return [3 /*break*/, 24];
          case 23:
            try {
              if (containers_4_1 && !containers_4_1.done && (_d = containers_4.return)) _d.call(containers_4);
            } finally {
              if (e_3) throw e_3.error;
            }
            return [7 /*endfinally*/];
          case 24:
            _loop_5 = function (dataContainer) {
              return __generator(this, function (_k) {
                switch (_k.label) {
                  case 0:
                    return [4 /*yield*/, new Promise(function (resolve) {
                      setTimeout(function () {
                        var model = _this.models.get(dataContainer.model);
                        var context = generateKey();
                        _this.cdRef.analyticsController.mark({
                          name: "dcupl:process_data:step_4:start",
                          context: context
                        });
                        if (model && (model.hasDerivedPropertiesBasedOnResolvedReferences() || (model === null || model === void 0 ? void 0 : model.hasDerivedReferencesBasedOnResolvedReferences()))) {
                          model.reRunDerivedPropertiesBasedOnResolves(dataContainer);
                        }
                        _this.cdRef.analyticsController.mark({
                          name: "dcupl:process_data:step_4:end",
                          context: context
                        });
                        _this.cdRef.analyticsController.measure({
                          name: "dcupl:process_data:step_4",
                          start: "dcupl:process_data:step_4:start",
                          end: "dcupl:process_data:step_4:end",
                          context: context,
                          detail: {
                            model: dataContainer.model
                          }
                        });
                        return resolve(true);
                      }, 0);
                    })];
                  case 1:
                    _k.sent();
                    return [2 /*return*/];
                }
              });
            };
            _f.label = 25;
          case 25:
            _f.trys.push([25, 30, 31, 32]);
            containers_5 = __values(containers), containers_5_1 = containers_5.next();
            _f.label = 26;
          case 26:
            if (!!containers_5_1.done) return [3 /*break*/, 29];
            dataContainer = containers_5_1.value;
            return [5 /*yield**/, _loop_5(dataContainer)];
          case 27:
            _f.sent();
            _f.label = 28;
          case 28:
            containers_5_1 = containers_5.next();
            return [3 /*break*/, 26];
          case 29:
            return [3 /*break*/, 32];
          case 30:
            e_4_1 = _f.sent();
            e_4 = {
              error: e_4_1
            };
            return [3 /*break*/, 32];
          case 31:
            try {
              if (containers_5_1 && !containers_5_1.done && (_e = containers_5.return)) _e.call(containers_5);
            } finally {
              if (e_4) throw e_4.error;
            }
            return [7 /*endfinally*/];
          case 32:
            return [2 /*return*/];
        }
      });
    });
  };
  ModelParser.prototype._getModelKeys = function () {
    return Array.from(this.models.values()).map(function (model) {
      return model.key;
    }).filter(function (key) {
      return !key.startsWith('$Dcupl');
    });
  };
  ModelParser.prototype._getViewKeys = function (options) {
    var modelKey = options === null || options === void 0 ? void 0 : options.modelKey;
    return Array.from(this.views.values()).filter(function (view) {
      if (modelKey) {
        return view.model === modelKey;
      }
      return true;
    }).map(function (view) {
      return view.key;
    });
  };
  __decorate([trigger({
    scope: 'global',
    name: 'dcupl:process_data'
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Array]), __metadata("design:returntype", Promise)], ModelParser.prototype, "handleDataContainer", null);
  return ModelParser;
}();
export { ModelParser };
