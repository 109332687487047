var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { ChangeDetector, IndicesController, generateKey } from '@dcupl/common';
import { Catalog } from '../catalog';
import { cloneDeep } from 'lodash';
var DcuplList = /** @class */function () {
  function DcuplList(dcuplApi) {
    this.dcuplApi = dcuplApi;
  }
  DcuplList.prototype.setup = function (options) {
    /**
     * 1) Get the corresponding model
     */
    var model = this.dcuplApi['modelParser'].models.get(options.modelKey);
    if (!model) {
      throw new Error("Model with key ".concat(options.modelKey, " not found"));
    }
    /**
     * 2) Set data based on listItemKeys or query
     */
    var listItemKeys = this.getListItemKeys(options);
    var data = this.getRelevantData(model, listItemKeys);
    /**
     * 3) Create indicesController depdending on listItemKys
     */
    var indicesController = this.initIndicesController(listItemKeys, model, data);
    /**
     * 4) Create catalog
     */
    if (!this.catalog) {
      this.catalog = new Catalog(this.dcuplApi, this.cdRef, indicesController);
      this.catalog['init'](this.modelKey, model, data);
    } else {
      this.catalog['filter'].update(model, data, indicesController);
    }
  };
  DcuplList.prototype.initIndicesController = function (listItemKeys, model, data) {
    var e_1, _a;
    var indicesController;
    if (listItemKeys) {
      indicesController = new IndicesController();
      try {
        for (var _b = __values(model.indicesController['indexMap'].keys()), _c = _b.next(); !_c.done; _c = _b.next()) {
          var key = _c.value;
          indicesController.getOrCreateIndex(key, data);
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        } finally {
          if (e_1) throw e_1.error;
        }
      }
    } else {
      indicesController = model.indicesController;
    }
    return indicesController;
  };
  DcuplList.prototype.getListItemKeys = function (options) {
    var listItemKeys;
    if (options.listItemKeys) {
      listItemKeys = options.listItemKeys;
    } else if (options.query) {
      var items = this.dcuplApi.query.execute(options.query);
      listItemKeys = items.map(function (item) {
        return item.key;
      });
    }
    return listItemKeys;
  };
  DcuplList.prototype.getRelevantData = function (model, listItemKeys) {
    var modelData = model.getData();
    if (listItemKeys) {
      return this.initFromItemKeys(modelData, listItemKeys);
    } else {
      return modelData;
    }
  };
  DcuplList.prototype.initCdRef = function (options) {
    var rootCdRef = this.dcuplApi['cdRef'];
    this.cdRef = new ChangeDetector();
    this.cdRef._init({
      cdRefKey: options.listKey,
      parentCd: rootCdRef,
      dcuplInitOptions: this.dcuplApi.initOptions,
      analyticsController: this.dcuplApi['analyticsController']
    });
  };
  DcuplList.prototype.init = function (_initialOptions) {
    /**
     * Initialize default options
     */
    _initialOptions.listKey = _initialOptions.listKey || generateKey();
    this.options = cloneDeep(_initialOptions);
    this.key = this.options.listKey;
    this.modelKey = this.options.modelKey;
    this.initCdRef(this.options);
    this.setup(this.options);
  };
  DcuplList.prototype.initFromItemKeys = function (modelData, itemKeys) {
    var data = new Map();
    itemKeys.forEach(function (key) {
      if (modelData.has(key)) {
        var item = modelData.get(key);
        if (item) {
          data.set(key, item);
        }
      }
    });
    return data;
  };
  DcuplList.prototype.destroy = function () {
    var _a;
    try {
      (_a = this.catalog['filter'].cdRef) === null || _a === void 0 ? void 0 : _a.destroy();
      this.dcuplApi.lists.destroy({
        listKey: this.key
      });
    } catch (err) {}
  };
  DcuplList.prototype.update = function (options) {
    if (options && options.reprocessData || !options) {
      this.setup(this.options);
    }
    this.cdRef.trigger({
      action: 'update',
      scope: 'list',
      modelKey: this.modelKey
    });
  };
  DcuplList.prototype.on = function (cb) {
    return this.cdRef.on(cb);
  };
  return DcuplList;
}();
export { DcuplList };
