import { parse } from 'papaparse';
var CsvParser = /** @class */function () {
  function CsvParser(loader) {
    this.loader = loader;
  }
  CsvParser.prototype.getParserConfig = function (modelDef, resourceOptions) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var loaderOptions = (_a = this.loader.config.get()) === null || _a === void 0 ? void 0 : _a.options;
    var defaultOptions = this.loader['options'];
    var options = {};
    if (typeof ((_b = resourceOptions === null || resourceOptions === void 0 ? void 0 : resourceOptions.csvParserOptions) === null || _b === void 0 ? void 0 : _b.quoteChar) !== 'undefined') {
      options.quoteChar = (_c = resourceOptions === null || resourceOptions === void 0 ? void 0 : resourceOptions.csvParserOptions) === null || _c === void 0 ? void 0 : _c.quoteChar;
    } else if (typeof ((_d = loaderOptions === null || loaderOptions === void 0 ? void 0 : loaderOptions.csvParserOptions) === null || _d === void 0 ? void 0 : _d.quoteChar) !== 'undefined') {
      options.quoteChar = (_e = loaderOptions === null || loaderOptions === void 0 ? void 0 : loaderOptions.csvParserOptions) === null || _e === void 0 ? void 0 : _e.quoteChar;
    } else {
      options.quoteChar = (_f = defaultOptions.csvParserOptions) === null || _f === void 0 ? void 0 : _f.quoteChar;
    }
    if (typeof ((_g = resourceOptions === null || resourceOptions === void 0 ? void 0 : resourceOptions.csvParserOptions) === null || _g === void 0 ? void 0 : _g.delimiter) !== 'undefined') {
      options.delimiter = (_h = resourceOptions === null || resourceOptions === void 0 ? void 0 : resourceOptions.csvParserOptions) === null || _h === void 0 ? void 0 : _h.delimiter;
    } else if (typeof ((_j = loaderOptions === null || loaderOptions === void 0 ? void 0 : loaderOptions.csvParserOptions) === null || _j === void 0 ? void 0 : _j.delimiter) !== 'undefined') {
      options.delimiter = (_k = loaderOptions === null || loaderOptions === void 0 ? void 0 : loaderOptions.csvParserOptions) === null || _k === void 0 ? void 0 : _k.delimiter;
    } else {
      options.delimiter = (_l = defaultOptions.csvParserOptions) === null || _l === void 0 ? void 0 : _l.delimiter;
    }
    if (typeof ((_m = resourceOptions === null || resourceOptions === void 0 ? void 0 : resourceOptions.csvParserOptions) === null || _m === void 0 ? void 0 : _m.escapeChar) !== 'undefined') {
      options.escapeChar = (_o = resourceOptions === null || resourceOptions === void 0 ? void 0 : resourceOptions.csvParserOptions) === null || _o === void 0 ? void 0 : _o.escapeChar;
    } else if (typeof ((_p = loaderOptions === null || loaderOptions === void 0 ? void 0 : loaderOptions.csvParserOptions) === null || _p === void 0 ? void 0 : _p.escapeChar) !== 'undefined') {
      options.escapeChar = (_q = loaderOptions === null || loaderOptions === void 0 ? void 0 : loaderOptions.csvParserOptions) === null || _q === void 0 ? void 0 : _q.escapeChar;
    } else {
      options.escapeChar = (_r = defaultOptions.csvParserOptions) === null || _r === void 0 ? void 0 : _r.escapeChar;
    }
    var config = {
      delimiter: options === null || options === void 0 ? void 0 : options.delimiter,
      quoteChar: options === null || options === void 0 ? void 0 : options.quoteChar,
      escapeChar: options === null || options === void 0 ? void 0 : options.escapeChar,
      comments: '#',
      dynamicTyping: false,
      header: true,
      skipEmptyLines: true,
      transformHeader: function (h) {
        return h.trim().replace(/"/g, '');
      },
      transform: function (value, header) {
        var _a, _b, _c, _d;
        if (value === 'null') {
          return null;
        }
        if (value === '') {
          return undefined;
        }
        if (typeof value === 'undefined') {
          return undefined;
        }
        var reference = (_a = modelDef.references) === null || _a === void 0 ? void 0 : _a.find(function (reference) {
          return reference.key === header || reference.origin === header;
        });
        if (modelDef.keyProperty && modelDef.keyProperty === header) {
          return value;
        }
        if (!modelDef.keyProperty && header === 'key') {
          return value;
        }
        if (reference) {
          if (value[0] === '{' || value[0] === '[') {
            try {
              return JSON.parse(value);
            } catch (err) {
              console.log(err);
              return;
            }
          } else if (reference.type === 'singleValued') {
            return value;
          } else {
            if ((value === null || value === void 0 ? void 0 : value.length) > 0 && reference.type === 'multiValued') {
              return (_b = value === null || value === void 0 ? void 0 : value.split(',')) === null || _b === void 0 ? void 0 : _b.map(function (val) {
                return val === null || val === void 0 ? void 0 : val.trim();
              });
            } else {
              return value;
            }
          }
        }
        var property = (_c = modelDef.properties) === null || _c === void 0 ? void 0 : _c.find(function (prop) {
          return prop.key === header || prop.origin === header;
        });
        if (!modelDef.autoGenerateProperties && !property) {
          return;
        }
        switch (property === null || property === void 0 ? void 0 : property.type) {
          case 'string':
            return value;
          case 'int':
            var val = parseInt(value, 10);
            if (isNaN(val)) {
              return;
            }
            return val;
          case 'float':
            var val2 = parseFloat(value);
            if (isNaN(val2)) {
              return;
            }
            return val2;
          case 'boolean':
            if (value === 'true') {
              return true;
            }
            if (value === 'false') {
              return false;
            }
            return;
          case 'json':
            try {
              return JSON.parse(value);
            } catch (err) {
              console.log(err);
              return;
            }
          case 'Array<string>':
          case 'Array<int>':
          case 'Array<float>':
          case 'Array<date>':
            if (value && typeof value === 'string' && value[0] === '[') {
              try {
                return JSON.parse(value);
              } catch (err) {
                return;
              }
            }
            if (value && typeof value === 'string') {
              return (_d = value === null || value === void 0 ? void 0 : value.split(',')) === null || _d === void 0 ? void 0 : _d.map(function (val) {
                return val === null || val === void 0 ? void 0 : val.trim();
              });
            }
            return;
          default:
            return value;
        }
        return value;
      }
    };
    return config;
  };
  CsvParser.prototype.getJsonFromCSVString = function (csv, modelDef, resource) {
    var config = this.getParserConfig(modelDef, resource.options);
    var result = parse(csv, config);
    return result.data;
  };
  return CsvParser;
}();
export { CsvParser };
