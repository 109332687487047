var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { max as dateMax, min as dateMin } from 'date-fns';
import min from 'lodash/min';
import max from 'lodash/max';
import { DcuplQueryBuilder, getFacets, getAggregation, CacheController } from '@dcupl/common';
var Filter = /** @class */function () {
  function Filter(modelParser, cdRef, item, indicesController, queryManager) {
    var _a, _b;
    this.modelParser = modelParser;
    this.cdRef = cdRef;
    this.item = item;
    this.indicesController = indicesController;
    this.queryManager = queryManager;
    this.filterItems = new Map();
    this.cacheCtrl = new CacheController(!!((_b = (_a = this.cdRef.dcuplInitOptions.performance) === null || _a === void 0 ? void 0 : _a.cache) === null || _b === void 0 ? void 0 : _b.enabled));
  }
  Filter.prototype.init = function (model, data) {
    this.model = model;
    this.initialData = data;
    this.filteredData = this.initialData;
    this.queryBuilder = new DcuplQueryBuilder();
    this.queryBuilder.init({
      modelKey: model.key
    });
    this.initFilterItems({
      skipProcessing: true
    });
  };
  Filter.prototype.update = function (model, data, indicesController) {
    this.model = model;
    this.initialData = data;
    this.indicesController = indicesController;
    this.cacheCtrl.clear();
    this.filteredData = this.getFilteredData(this.initialData, false);
    this.initFilterItems({
      skipProcessing: true
    });
  };
  Filter.prototype.resetCache = function () {
    this.cacheCtrl.clear();
  };
  Filter.prototype.initFilterItems = function (options) {
    var _this = this;
    var attributes = __spreadArray(__spreadArray([], __read(this.model.properties.values()), false), __read(this.model.references.values()), false);
    attributes.forEach(function (attribute) {
      if (attribute.filter) {
        if (Array.isArray(options === null || options === void 0 ? void 0 : options.filterKeys)) {
          if (options === null || options === void 0 ? void 0 : options.filterKeys.includes(attribute.key)) {
            _this.updateFilterItem(attribute.key, options);
          }
        } else {
          _this.updateFilterItem(attribute.key, options);
        }
      }
    });
  };
  Filter.prototype.getAggregate = function (options, relevantData, indexMap) {
    var attribute = this.model.getAttribute(options.attribute);
    if (!attribute) {
      throw new Error('Attribute not found');
    }
    if (!relevantData) {
      relevantData = this.filteredData;
    }
    if (!indexMap) {
      indexMap = this.indicesController.getOrCreateIndex(options.attribute, relevantData, this.queryBuilder.getQuery());
    }
    return getAggregation(options, relevantData, indexMap, attribute.key);
  };
  Filter.prototype.getSections = function (options) {
    var e_1, _a;
    var _this = this;
    var _b;
    var response = {
      _meta: {
        currentSize: 0,
        initialSize: 0
      },
      items: []
    };
    var attribute = this.model.getAttribute(options.attribute);
    if (!attribute) {
      throw new Error('Attribute not found');
    }
    var sectionItems = new Map();
    var remoteModel;
    if (attribute.type === 'singleValued' || attribute.type === 'multiValued') {
      remoteModel = this.modelParser.models.get(attribute.model);
    }
    var childLevelOptions = {};
    var childLevelSorting = this.getSortingProjectionForModel(options, this.model);
    childLevelOptions.sort = childLevelSorting;
    // 1) PREPARE INITIAL DATA
    // get the filteredData only SORTED
    var relevantData = this.item.getItemsAsMap(this.filteredData, this.model, childLevelOptions);
    var indexMap = undefined;
    indexMap = this.indicesController.getOrCreateIndex("".concat(options.attribute), relevantData, childLevelSorting);
    // 2) get the distinct values for the attribute
    // todo distinctoptions
    var agg = this.getAggregate({
      attribute: options.attribute,
      types: ['distinct'],
      excludeUndefineds: true
    }, relevantData, indexMap);
    if (agg.distinct) {
      try {
        for (var _c = __values(agg.distinct), _d = _c.next(); !_d.done; _d = _c.next()) {
          var distinctAggValue = _d.value;
          var item = {
            key: distinctAggValue.value
          };
          var values = distinctAggValue.keys.filter(function (key) {
            return relevantData.has(key);
          });
          if (remoteModel) {
            var remoteItem = remoteModel['data'].get(distinctAggValue.value);
            if (remoteItem) {
              item = remoteItem;
            }
          }
          var sectionItem = __assign(__assign({}, item), {
            key: distinctAggValue.value,
            _items: [],
            _aggregates: [],
            _meta: {
              size: values.length
            }
          });
          sectionItems.set(distinctAggValue.value, sectionItem);
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        } finally {
          if (e_1) throw e_1.error;
        }
      }
    }
    // // 3) Page, Sort and project the section data
    var topLevelOptions = {
      count: options.count,
      start: options.start,
      projection: options.projection
    };
    if (remoteModel) {
      var topLevelSorting = this.getSortingProjectionForModel(options, remoteModel);
      topLevelOptions.sort = topLevelSorting;
    }
    response._meta.currentSize = sectionItems.size;
    response._meta.initialSize = agg.distinct.length;
    sectionItems = this.item.getItemsAsMap(sectionItems, remoteModel, topLevelOptions);
    if (Array.isArray(options === null || options === void 0 ? void 0 : options.aggregates)) {
      sectionItems.forEach(function (sectionItem) {
        var initialAggregate = agg.distinct.find(function (d) {
          return d.value === sectionItem.key;
        });
        var filteredData = _this.getDataByKeys(relevantData, initialAggregate.keys);
        sectionItem._aggregates = options.aggregates.map(function (aggOption) {
          return _this.getAggregate(aggOption, filteredData);
        });
      });
    }
    if (options === null || options === void 0 ? void 0 : options.items) {
      var wildCardItem_1 = (_b = options.items) === null || _b === void 0 ? void 0 : _b.find(function (i) {
        return i.key === '*';
      });
      if (wildCardItem_1) {
        sectionItems.forEach(function (sectionItem) {
          var initialAggregate = agg.distinct.find(function (d) {
            return d.value === sectionItem.key;
          });
          var filteredData = _this.getDataByKeys(relevantData, initialAggregate.keys);
          var items = _this.item.getItems(filteredData, _this.model, wildCardItem_1);
          sectionItem._items = items;
        });
      } else {
        sectionItems.forEach(function (sectionItem) {
          var _a;
          var optionItem = (_a = options.items) === null || _a === void 0 ? void 0 : _a.find(function (i) {
            return i.key === sectionItem.key;
          });
          if (optionItem) {
            var initialAggregate = agg.distinct.find(function (d) {
              return d.value === sectionItem.key;
            });
            var filteredData = _this.getDataByKeys(relevantData, initialAggregate.keys);
            var items = _this.item.getItems(filteredData, _this.model, optionItem);
            sectionItem._items = items;
          }
        });
      }
    }
    response.items = Array.from(sectionItems.values());
    return response;
  };
  Filter.prototype.getSortingProjectionForModel = function (options, model) {
    var _a;
    var topLevelSorting = {
      attributes: [],
      order: []
    };
    (_a = options === null || options === void 0 ? void 0 : options.sort) === null || _a === void 0 ? void 0 : _a.attributes.forEach(function (attribute, idx) {
      var _a;
      if (model.properties.has(attribute)) {
        topLevelSorting.attributes.push(attribute);
        var orders = (_a = options.sort) === null || _a === void 0 ? void 0 : _a.order;
        topLevelSorting.order.push(orders[idx]);
      }
    });
    return topLevelSorting;
  };
  Filter.prototype.getReferenceKeyForFilterKey = function (filterKey) {
    var filterItem = this.filterItems.get(filterKey);
    return (filterItem === null || filterItem === void 0 ? void 0 : filterItem.attributeKey) || (filterItem === null || filterItem === void 0 ? void 0 : filterItem.key) || filterKey;
  };
  Filter.prototype.updateFilterItem = function (filterKey, options) {
    var _this = this;
    var _a;
    if (options === void 0) {
      options = {};
    }
    var defaultOptions = {
      calculateFacets: false,
      excludeZeros: true,
      excludeUndefineds: true,
      excludeUnresolved: true,
      skipProcessing: false
    };
    var referenceKey = this.getReferenceKeyForFilterKey(filterKey);
    if (!referenceKey) {
      return;
    }
    var reference = this.model.references.get(referenceKey);
    var relevantOptions = {};
    if (typeof (reference === null || reference === void 0 ? void 0 : reference.filter) === 'object') {
      relevantOptions = Object.assign(defaultOptions, reference.filter, options);
    } else {
      relevantOptions = Object.assign(defaultOptions, options);
    }
    if (reference) {
      this.updateFilterItemReference(reference.key, reference.key, relevantOptions);
      return;
    }
    var property = this.model.properties.get(referenceKey);
    if (property) {
      if (Array.isArray(property.filter)) {
        property.filter.forEach(function (filter) {
          var comparisonType = (filter === null || filter === void 0 ? void 0 : filter.comparisonType) || 'containsIgnoreCase';
          _this.updateFilterItemProperty(filter.key, property.key, property.type, comparisonType, relevantOptions);
        });
        return;
      } else {
        var comparisonType = ((_a = property.filter) === null || _a === void 0 ? void 0 : _a.comparisonType) || 'containsIgnoreCase';
        this.updateFilterItemProperty(property.key, property.key, property.type, comparisonType, relevantOptions);
        return;
      }
    }
    if (filterKey === 'key') {
      var comparisonType = 'containsIgnoreCase';
      this.updateFilterItemProperty('key', 'key', 'string', comparisonType, relevantOptions);
      return;
    }
  };
  Filter.prototype.updateFilterItemReference = function (filterKey, attributeKey, options) {
    var entries = [];
    var reference = this.model.references.get(attributeKey);
    if (reference && !options.skipProcessing) {
      var remoteModel = this.modelParser.models.get(reference.model);
      entries = this.getFacets(reference.key, remoteModel, options);
    }
    var filterItem = {
      key: filterKey,
      attributeKey: attributeKey,
      enabled: !!entries.find(function (entry) {
        return entry.enabled;
      }),
      selected: !!entries.find(function (entry) {
        return entry.selected;
      }),
      type: 'reference',
      entries: entries
    };
    this.filterItems.set(filterItem.key, filterItem);
  };
  Filter.prototype.updateFilterItemProperty = function (filterKey, attributeKey, type, comparisonType, options) {
    var filterType = this.getFilterTypeForPropertyType(type);
    var hasFilterEntry = this.queryBuilder.hasQueryGroup(filterKey);
    var filterItem = {
      key: filterKey,
      attributeKey: attributeKey,
      enabled: true,
      selected: !!hasFilterEntry,
      type: filterType
    };
    if (filterItem.type === 'text') {
      filterItem.comparisonType = comparisonType;
    }
    if (filterItem.type === 'boolean') {
      var entries = this.getFacets(filterItem.attributeKey, undefined, options);
      filterItem.entries = entries;
    }
    if (!options.skipProcessing) {
      if (filterItem.type === 'number') {
        filterItem.range = this.getNumberRangesForFilterItem(filterItem);
      }
      if (filterItem.type === 'date') {
        filterItem.range = this.getDateRangesForFilterItem(filterItem);
      }
    }
    this.filterItems.set(filterItem.key, filterItem);
  };
  Filter.prototype.getNumberRange = function (propertyKey, filterKey) {
    if (filterKey === void 0) {
      filterKey = propertyKey;
    }
    var filteredData = Array.from(this.filteredData.values());
    var filteredValues = filteredData.map(function (item) {
      return item[propertyKey];
    }).filter(function (item) {
      return typeof item !== 'undefined' && item !== null;
    });
    var flatFilteredValues = filteredValues.flat();
    var filteredMin = min(flatFilteredValues);
    var filteredMax = max(flatFilteredValues);
    var initialData = Array.from(this.initialData.values());
    var initialValues = initialData.map(function (item) {
      return item[propertyKey];
    }).filter(function (item) {
      return typeof item !== 'undefined' && item !== null;
    });
    var flatInitialValues = initialValues.flat();
    var initialMin = min(flatInitialValues);
    var initialMax = max(flatInitialValues);
    var ranges = {
      available: {
        min: filteredMin,
        max: filteredMax
      },
      initial: {
        min: initialMin,
        max: initialMax
      },
      relatedQuery: this.queryBuilder.getQueryGroup(filterKey)
    };
    return ranges;
  };
  Filter.prototype.getNumberRangesForFilterItem = function (filterItem) {
    var ranges = this.getNumberRange(filterItem.attributeKey, filterItem.key);
    return ranges;
  };
  Filter.prototype.getDateRangesForFilterItem = function (filterItem) {
    var ranges = this.getDateRange(filterItem.attributeKey, filterItem.key);
    return ranges;
  };
  Filter.prototype.getDateRange = function (propertyKey, filterKey) {
    if (filterKey === void 0) {
      filterKey = propertyKey;
    }
    var filteredData = Array.from(this.filteredData.values());
    var filteredValues = filteredData.map(function (item) {
      return item[propertyKey];
    }).filter(function (item) {
      return typeof item !== 'undefined' && item !== null;
    });
    var flatFilteredValues = filteredValues.flat();
    var filteredMin = dateMin(flatFilteredValues);
    var filteredMax = dateMax(flatFilteredValues);
    var initialData = Array.from(this.initialData.values());
    var initialValues = initialData.map(function (item) {
      return item[propertyKey];
    }).filter(function (item) {
      return typeof item !== 'undefined' && item !== null;
    });
    var flatInitialValues = initialValues.flat();
    var initialMin = dateMin(flatInitialValues);
    var initialMax = dateMax(flatInitialValues);
    var ranges = {
      available: {
        min: filteredMin,
        max: filteredMax
      },
      initial: {
        min: initialMin,
        max: initialMax
      },
      relatedQuery: this.queryBuilder.getQueryGroup(filterKey)
    };
    return ranges;
  };
  Filter.prototype.getFacets = function (attributeKey, remoteModel, options) {
    var _this = this;
    var indexMap;
    var cacheContext = {
      attributeKey: attributeKey,
      query: this.queryBuilder.getQuery(),
      options: options
    };
    var cachedValue = this.cacheCtrl.get('facets', cacheContext);
    if (cachedValue) {
      return cachedValue;
    }
    var remoteData = new Map();
    indexMap = this.indicesController.getIndex(attributeKey);
    if (remoteModel) {
      remoteData = remoteModel.getData();
    } else {
      remoteData = this.indicesController.getData(attributeKey);
    }
    // Autogenerate index if not present
    if (!indexMap || !remoteData) {
      this.indicesController.reset(attributeKey);
      this.initialData.forEach(function (entry) {
        var attributeValue = entry[attributeKey];
        _this.indicesController.fillInverseIndex(attributeKey, entry.key, [attributeValue]);
        _this.indicesController.fillDataMap(attributeKey, [attributeValue]);
      });
      indexMap = this.indicesController.getIndex(attributeKey);
      remoteData = this.indicesController.getData(attributeKey);
    }
    var relevantModelData = this.initialData;
    var facets = getFacets(relevantModelData, remoteData, options, this.queryBuilder.getQuery(), attributeKey, this.indicesController['indexMap'], this.queryManager);
    this.cacheCtrl.set('facets', cacheContext, facets);
    return facets;
  };
  Filter.prototype.getDataByKeys = function (data, itemKeys) {
    if (itemKeys) {
      var filteredData_1 = new Map();
      itemKeys.forEach(function (key) {
        var entry = data.get(key);
        if (entry) {
          filteredData_1.set(entry.key, entry);
        }
      });
      return filteredData_1;
    }
    return data;
  };
  Filter.prototype.getFilterTypeForPropertyType = function (type) {
    switch (type) {
      case 'string':
        return 'text';
      case 'int':
        return 'number';
      case 'float':
        return 'number';
      case 'date':
        return 'date';
      case 'boolean':
        return 'boolean';
      case 'json':
        return 'text';
      case 'any':
        return 'text';
      case 'Array<int>':
        return 'number';
      case 'Array<string>':
        return 'text';
      case 'Array<int>':
        return 'number';
      case 'Array<float>':
        return 'number';
      case 'Array<date>':
        return 'date';
    }
  };
  Filter.prototype.updateFilteredData = function (options) {
    if (!(options === null || options === void 0 ? void 0 : options.skipProcessing)) {
      this.filteredData = this.getFilteredData(this.initialData, false);
      this.cdRef.trigger({
        scope: 'list',
        name: 'list:result:updated',
        type: 'result_updated',
        action: 'update'
      });
    }
  };
  Filter.prototype.getFilteredData = function (initialData, earlyReturn) {
    var cacheContext = {
      query: this.queryBuilder.getQuery(),
      earlyReturn: earlyReturn
    };
    var cachedValue = this.cacheCtrl.get('data', cacheContext);
    if (cachedValue) {
      return cachedValue;
    }
    var query = this.queryBuilder.getQuery();
    var value = this.queryManager.queryData(initialData, query, earlyReturn, this.indicesController['indexMap']);
    this.cacheCtrl.set('data', cacheContext, value);
    return value;
  };
  Filter.prototype.getFilterItems = function (options) {
    var _this = this;
    if (Array.isArray(options === null || options === void 0 ? void 0 : options.filterKeys)) {
      var items_1 = [];
      options === null || options === void 0 ? void 0 : options.filterKeys.forEach(function (filterKey) {
        var item = _this.getFilterItem(filterKey, options);
        if (item) {
          items_1.push(item);
        }
      });
      return items_1;
    } else {
      if (!(options === null || options === void 0 ? void 0 : options.skipProcessing)) {
        this.initFilterItems(options);
      }
      return Array.from(this.filterItems.values());
    }
  };
  Filter.prototype.getFilterItem = function (filterItemId, options) {
    if (this.filterItems.has(filterItemId)) {
      if (!(options === null || options === void 0 ? void 0 : options.skipProcessing)) {
        this.updateFilterItem(filterItemId, options);
      }
    }
    return this.filterItems.get(filterItemId);
  };
  return Filter;
}();
export { Filter };
