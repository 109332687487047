var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { DcuplQueryBuilder } from './query-builder';
import { cloneDeep } from 'lodash';
var queryBuilder = new DcuplQueryBuilder();
export function getFacets(modelData, remoteModelData, options, query, attribute, modelIndexMap, queryManager) {
  var e_1, _a, e_2, _b, e_3, _c;
  var filterItemEntries = [];
  var queryWithoutCurrentFilter = queryBuilder.removeQuery({
    groupKey: attribute
  }, cloneDeep(query));
  var currentFilteredData = queryManager.queryData(modelData, queryWithoutCurrentFilter, false, modelIndexMap);
  var remoteModelDataKeys = remoteModelData ? __spreadArray([], __read(remoteModelData === null || remoteModelData === void 0 ? void 0 : remoteModelData.keys()), false) : [];
  var validFacetKeys = new Set(remoteModelDataKeys);
  var indexMap = modelIndexMap.get(attribute);
  if (!options.excludeUnresolved) {
    try {
      for (var _d = __values(indexMap.keys()), _e = _d.next(); !_e.done; _e = _d.next()) {
        var key = _e.value;
        validFacetKeys.add(key);
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
  }
  if (options.excludeUndefineds) {
    validFacetKeys.delete(undefined);
  } else {
    validFacetKeys.add(undefined);
  }
  try {
    for (var _f = __values(validFacetKeys.values()), _g = _f.next(); !_g.done; _g = _f.next()) {
      var validFacetKey = _g.value;
      var result = new Map();
      var indexedValueKeys = indexMap.get(validFacetKey) || [];
      try {
        for (var indexedValueKeys_1 = (e_3 = void 0, __values(indexedValueKeys)), indexedValueKeys_1_1 = indexedValueKeys_1.next(); !indexedValueKeys_1_1.done; indexedValueKeys_1_1 = indexedValueKeys_1.next()) {
          var key = indexedValueKeys_1_1.value;
          var value = currentFilteredData.get(key);
          if (value) {
            result.set(key, value);
            if (!(options === null || options === void 0 ? void 0 : options.calculateFacets)) {
              break;
            }
          }
        }
      } catch (e_3_1) {
        e_3 = {
          error: e_3_1
        };
      } finally {
        try {
          if (indexedValueKeys_1_1 && !indexedValueKeys_1_1.done && (_c = indexedValueKeys_1.return)) _c.call(indexedValueKeys_1);
        } finally {
          if (e_3) throw e_3.error;
        }
      }
      var facetEntry = {
        key: String(validFacetKey),
        value: validFacetKey,
        selected: isFilterItemEntrySelected(attribute, validFacetKey, query),
        enabled: result.size > 0,
        size: result.size,
        entry: remoteModelData.get(validFacetKey),
        resultKeys: Array.from(result.keys())
      };
      if ((options === null || options === void 0 ? void 0 : options.excludeZeros) && facetEntry.size === 0) {
        continue;
      }
      if ((options === null || options === void 0 ? void 0 : options.count) && filterItemEntries.length === (options === null || options === void 0 ? void 0 : options.count)) {
        return filterItemEntries;
      }
      filterItemEntries.push(facetEntry);
    }
  } catch (e_2_1) {
    e_2 = {
      error: e_2_1
    };
  } finally {
    try {
      if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
    } finally {
      if (e_2) throw e_2.error;
    }
  }
  return filterItemEntries;
}
var isFilterItemEntrySelected = function (groupKey, facetKey, query) {
  var idx = query.queries.findIndex(function (query) {
    return queryBuilder.isQueryGroup(query) && query.groupKey === groupKey;
  });
  if (idx > -1) {
    var queryGroup = query.queries[idx];
    return !!queryGroup.queries.find(function (query) {
      var _a;
      if (queryBuilder.isQuery(query)) {
        var key = ((_a = query.value) === null || _a === void 0 ? void 0 : _a.key) || query.value;
        return query.attribute === groupKey && key === facetKey;
      }
      return false;
    });
  }
  return false;
};
