var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { ItemService } from '../catalog';
import { ModelParser } from '../model-parser';
import { ChangeDetector, trigger, DcuplQueryBuilder, generateKey, AnalyticsController, QueryManager, CacheController, ScriptController } from '@dcupl/common';
import { ListsController } from './dcupl-list-controller';
import { merge } from 'lodash';
var DcuplApi = /** @class */function () {
  function DcuplApi(options) {
    var _this = this;
    var _a, _b, _c;
    this.cdRef = new ChangeDetector();
    this.modelParser = new ModelParser(this.cdRef);
    this.analyticsController = new AnalyticsController();
    this.scriptController = new ScriptController();
    this.globalItemService = new ItemService(this.modelParser, this.scriptController);
    this.cacheCtrl = new CacheController(false);
    this.queryManager = new QueryManager(this.scriptController);
    this._loaders = new Map();
    this.rootQueryBuilder = new DcuplQueryBuilder();
    this._defaultInitOptions = {
      config: {
        projectId: undefined,
        apiKey: undefined,
        version: 'draft'
      },
      errorTracking: {
        enabled: false
      },
      analytics: {
        enabled: false
      },
      performance: {
        cache: {
          enabled: true
        },
        clone: {
          enabled: true
        }
      }
    };
    this.lists = new ListsController(this);
    this.models = {
      get: function (modelKey) {
        var _a;
        return (_a = _this.modelParser.models.get(modelKey)) === null || _a === void 0 ? void 0 : _a.initialDefinition;
      },
      set: function (model) {
        _this.modelParser.models.delete(model.key);
        return _this.modelParser.addModel(model);
      },
      update: function (model) {
        return _this.modelParser.addModel(model);
      },
      remove: function (modelKey) {
        _this.modelParser.models.delete(modelKey);
      },
      keys: function () {
        return _this.modelParser['_getModelKeys']();
      }
    };
    this.views = {
      get: function (viewKey) {
        return _this.modelParser.views.get(viewKey);
      },
      keys: function (options) {
        return _this.modelParser['_getViewKeys'](options);
      },
      set: function (view) {
        return _this.modelParser.addView(view);
      }
    };
    this.data = {
      update: function (data, options) {
        _this.modelParser.addData({
          data: data,
          model: options.model,
          type: 'update',
          keyProperty: options.keyProperty,
          autoGenerateKey: options.autoGenerateKey
        });
      },
      upsert: function (data, options) {
        _this.modelParser.addData({
          data: data,
          model: options.model,
          type: 'upsert',
          keyProperty: options.keyProperty,
          autoGenerateKey: options.autoGenerateKey
        });
      },
      set: function (data, options) {
        _this.modelParser.addData({
          data: data,
          model: options.model,
          type: 'set',
          keyProperty: options.keyProperty,
          autoGenerateKey: options.autoGenerateKey
        });
      },
      remove: function (data, options) {
        _this.modelParser.addData({
          data: data,
          model: options.model,
          type: 'remove',
          keyProperty: options.keyProperty,
          autoGenerateKey: options.autoGenerateKey
        });
      },
      reset: function (options) {
        var _a;
        if (options.model) {
          (_a = _this.modelParser.models.get(options.model)) === null || _a === void 0 ? void 0 : _a.resetData();
        }
      },
      apply: function (container) {
        return _this.modelParser.addData(container);
      }
    };
    this.loaders = {
      add: function (loader, key) {
        _this.addLoader(loader, key);
      },
      remove: function (key) {
        _this._loaders.delete(key);
      },
      get: function (key) {
        return _this._loaders.get(key);
      }
    };
    this.scripts = {
      set: function (key, script) {
        _this.scriptController.setScript(key, script);
      },
      remove: function (key) {
        _this.scriptController.removeScript(key);
      },
      get: function (key) {
        _this.scriptController.getScript(key);
      },
      keys: function () {
        return _this.scriptController.keys();
      },
      execute: function (key, options) {
        var args = {
          dcupl: _this,
          options: options
        };
        return _this.scriptController.executeScript(key, 'script', args);
      },
      executeAsync: function (key, options) {
        return new Promise(function (resolve, reject) {
          var args = {
            dcupl: _this,
            options: options,
            resolve: resolve,
            reject: reject
          };
          return _this.scriptController.executeScript(key, 'async_script', args);
        });
      }
    };
    this.fn = {
      pivot: function (options) {
        var list = _this.getFnList(options);
        var result = list.catalog.fn.pivot(options.options);
        return result;
      },
      aggregate: function (options) {
        var list = _this.getFnList(options);
        var result = list.catalog.fn.aggregate(options.options);
        return result;
      },
      suggest: function (options) {
        var list = _this.getFnList(options);
        var result = list.catalog.fn.suggest(options.options);
        return result;
      },
      groupBy: function (options) {
        var list = _this.getFnList(options);
        var result = list.catalog.fn.groupBy(options.options);
        return result;
      },
      facets: function (options) {
        var list = _this.getFnList(options);
        var result = list.catalog.fn.facets(options.options);
        return result;
      },
      metadata: function (options) {
        var list = _this.getFnList(options);
        var result = list.catalog.fn.metadata();
        return result;
      }
    };
    this.query = {
      one: function (options) {
        return _this.getItem(options);
      },
      many: function (options) {
        return _this._getManyItems(options);
      },
      execute: function (options) {
        return _this.executeQuery(options);
      },
      generate: function (modelKey, query) {
        _this.rootQueryBuilder.init({
          modelKey: modelKey
        });
        return _this.rootQueryBuilder.applyQuery(query);
      },
      registerCustomOperator: function (operator, fn) {
        _this.queryManager.registerCustomOperator(operator, fn);
      }
    };
    this.analyticsController.init(!!((_a = options === null || options === void 0 ? void 0 : options.analytics) === null || _a === void 0 ? void 0 : _a.enabled));
    this.analyticsController.mark({
      name: 'dcupl:constructor'
    });
    this.initOptions = merge({}, this._defaultInitOptions, options);
    this.modelParser.initOptions = Object.assign({}, this.initOptions);
    this.cdRef._init({
      cdRefKey: 'root',
      dcuplInitOptions: this.initOptions,
      analyticsController: this.analyticsController
    });
    this.cacheCtrl.enabled = !!((_c = (_b = this.initOptions.performance) === null || _b === void 0 ? void 0 : _b.cache) === null || _c === void 0 ? void 0 : _c.enabled);
  }
  DcuplApi.prototype.getFnList = function (options) {
    var list = this.lists.get("__global_list__".concat(options.modelKey));
    if (!list) {
      list = this.lists.create({
        modelKey: options.modelKey,
        listKey: "__global_list__".concat(options.modelKey)
      });
    }
    if (options.query) {
      list.catalog.query.apply(options.query, {
        mode: 'set'
      });
    } else {
      list.catalog.query.reset();
    }
    return list;
  };
  DcuplApi.prototype.updateFnLists = function () {
    var e_1, _a;
    try {
      for (var _b = __values(this.lists.getAll()), _c = _b.next(); !_c.done; _c = _b.next()) {
        var list = _c.value;
        if (list.key.startsWith('__global_list__')) {
          list.update();
        }
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
  };
  DcuplApi.prototype.on = function (cb) {
    return this.cdRef.on(cb);
  };
  DcuplApi.prototype.init = function () {
    return __awaiter(this, void 0, void 0, function () {
      var err_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            return [4 /*yield*/, this.modelParser.init()];
          case 1:
            _a.sent();
            return [2 /*return*/, true];
          case 2:
            err_1 = _a.sent();
            return [2 /*return*/, false];
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  DcuplApi.prototype.update = function () {
    return __awaiter(this, void 0, void 0, function () {
      var err_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            return [4 /*yield*/, this.modelParser.update()];
          case 1:
            _a.sent();
            this.cacheCtrl.clear();
            this.updateFnLists();
            return [2 /*return*/, true];
          case 2:
            err_2 = _a.sent();
            console.log(err_2);
            return [2 /*return*/, false];
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  DcuplApi.prototype.destroy = function () {
    try {
      this.lists.destroy();
      this._loaders.forEach(function (loader) {
        loader.destroy();
      });
    } catch (err) {}
  };
  DcuplApi.prototype.getItem = function (options) {
    var model = this.modelParser.models.get(options.modelKey);
    if (!model) {
      throw new Error();
    }
    return this.globalItemService.getItem(options.itemKey, model['data'], model, options);
  };
  DcuplApi.prototype._getManyItems = function (options) {
    var model = this.modelParser.models.get(options.modelKey);
    if (!model) {
      throw new Error();
    }
    var items = this.globalItemService.getManyItems(options.itemKeys, model['data'], model, options);
    return items;
  };
  DcuplApi.prototype.executeQuery = function (options) {
    if (!(options === null || options === void 0 ? void 0 : options.modelKey)) {
      throw new Error("No modelKey provided");
    }
    var model = this.modelParser.models.get(options === null || options === void 0 ? void 0 : options.modelKey);
    if (!model) {
      throw new Error("Model not found: ".concat(options.modelKey));
    }
    var cacheContext = {
      query: options
    };
    var hasCache = this.cacheCtrl.has('query', cacheContext);
    if (hasCache) {
      return this.cacheCtrl.get('query', cacheContext);
    }
    var queriedData = this.queryManager.queryData(model['data'], options, false, model.indicesController['indexMap']);
    var items = this.globalItemService.getItems(queriedData, model, options);
    this.cacheCtrl.set('query', cacheContext, items);
    return items;
  };
  DcuplApi.prototype.addLoader = function (loader, key) {
    var _a, _b, _c, _d, _e, _f;
    var loaderKey = key || generateKey();
    this._loaders.set(loaderKey, loader);
    loader.core = this;
    loader.key = loaderKey;
    var loaderCd = new ChangeDetector();
    loaderCd._init({
      cdRefKey: loaderKey,
      parentCd: this.cdRef,
      dcuplInitOptions: this.initOptions,
      analyticsController: this.analyticsController
    });
    loader.cdRef = loaderCd;
    if ((_a = this.initOptions.config) === null || _a === void 0 ? void 0 : _a.projectId) {
      loader.defaultVariables.set('projectId', {
        key: 'projectId',
        value: (_b = this.initOptions.config) === null || _b === void 0 ? void 0 : _b.projectId
      });
    }
    if ((_c = this.initOptions.config) === null || _c === void 0 ? void 0 : _c.apiKey) {
      loader.defaultVariables.set('apiKey', {
        key: 'apiKey',
        value: (_d = this.initOptions.config) === null || _d === void 0 ? void 0 : _d.apiKey
      });
    }
    if ((_e = this.initOptions.config) === null || _e === void 0 ? void 0 : _e.version) {
      loader.defaultVariables.set('version', {
        key: 'version',
        value: (_f = this.initOptions.config) === null || _f === void 0 ? void 0 : _f.version
      });
    }
  };
  var _a;
  __decorate([trigger({
    scope: 'global',
    name: 'dcupl:init',
    type: 'dcupl_initialized'
  }), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", Promise)], DcuplApi.prototype, "init", null);
  __decorate([trigger({
    scope: 'global',
    name: 'dcupl:update',
    type: 'dcupl_updated_manually',
    action: 'update'
  }), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", Promise)], DcuplApi.prototype, "update", null);
  __decorate([trigger({
    scope: 'global',
    name: 'dcupl:destroy'
  }), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", void 0)], DcuplApi.prototype, "destroy", null);
  __decorate([trigger({
    scope: 'global',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], DcuplApi.prototype, "getItem", null);
  __decorate([trigger({
    scope: 'global',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], DcuplApi.prototype, "_getManyItems", null);
  __decorate([trigger({
    scope: 'global',
    cloneResult: true
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], DcuplApi.prototype, "executeQuery", null);
  __decorate([trigger({
    scope: 'global',
    name: 'dcupl:loaders:add'
  }), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, String]), __metadata("design:returntype", void 0)], DcuplApi.prototype, "addLoader", null);
  return DcuplApi;
}();
export { DcuplApi };
