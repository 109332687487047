import objectHash from 'object-hash';
var CacheController = /** @class */function () {
  function CacheController(enabled) {
    this.enabled = enabled;
    this.cache = new Map();
  }
  CacheController.prototype.get = function (key, context) {
    if (!this.enabled) return undefined;
    return this.cache.get(this.getCacheName(key, context));
  };
  CacheController.prototype.set = function (key, context, value) {
    if (!this.enabled) return undefined;
    this.cache.set(this.getCacheName(key, context), value);
  };
  CacheController.prototype.has = function (key, context) {
    return this.cache.has(this.getCacheName(key, context));
  };
  CacheController.prototype.clear = function () {
    this.cache.clear();
  };
  CacheController.prototype.getCacheName = function (key, context) {
    var hash = objectHash(context);
    return key + '_' + hash;
  };
  return CacheController;
}();
export { CacheController };
