import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { AfterViewInit, Directive, Injector, Input, OnDestroy, Renderer2, Type, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { Subscription, first } from 'rxjs';

import { InjectionToken } from '@angular/core';
import { LayoutConfigCardItem } from '../types/config/layout-config.types';
// import { PropertyFormReturnType } from '../../projects/embert-admin/src/app/shared/components/set-property-form/set-property-form.component';
import { AbstractConfigService } from '../types/config/config.service.types';

export const FORM_COMPONENT = new InjectionToken('FormComponent');
export const CONFIG_SERVICE = new InjectionToken('ConfigServicex');

@Directive({
  selector: '[emadEditable]',
  standalone: true,
})
export class EditButtonDirective implements AfterViewInit, OnDestroy {
  @Input('emadEditable') emadEditable: string | undefined;
  @Input('data') data: string | undefined;
  @Input('emadPlaceholder') placeholder: string | undefined;

  public hasIcon = false;

  private configService!: AbstractConfigService;
  public sub: Subscription | undefined;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private dialog: Dialog,
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  ngAfterViewInit(): void {
    this.configService = this.injector.get(CONFIG_SERVICE) as AbstractConfigService;
    this.init();
    this.sub = this.configService.config$.subscribe((config: any) => {
      this.init();
    });
  }

  private init() {
    if (this.isEditMode()) {
      this.addEditButton();
      this.setValue();
    } else {
      this.setKey();
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private isEditMode() {
    const component = this.injector.get(FORM_COMPONENT) as Type<any>;
    return !!component;
  }

  private setKey() {
    const existingConfigItem = this.getConfigForPosition(this.emadEditable!);
    if (this.viewContainerRef.element.nativeElement.tagName !== 'IMG') {
      if (existingConfigItem) {
        this.viewContainerRef.element.nativeElement.innerHTML = existingConfigItem?.attribute?.key;
      }
    }
  }

  private setValue() {
    const existingConfigItem = this.getConfigForPosition(this.emadEditable!);
    if (this.viewContainerRef.element.nativeElement.tagName !== 'IMG') {
      if (existingConfigItem && this.data) {
        this.viewContainerRef.element.nativeElement.innerHTML = (this.data as any)[existingConfigItem!.attribute!.key!];
      } else {
        this.viewContainerRef.element.nativeElement.innerHTML = this.placeholder;
      }
    }
  }

  private addEditButton() {
    if (this.hasIcon) {
      return;
    }

    const existingConfigItem = this.getConfigForPosition(this.emadEditable!);

    if (this.viewContainerRef.element.nativeElement.tagName === 'IMG') {
      // if the element is an image we need we male the image clickable
      this.renderer.setStyle(this.viewContainerRef.element.nativeElement, 'cursor', 'pointer');
      this.renderer.listen(this.viewContainerRef.element.nativeElement, 'click', () => {
        this.openAttributeForm(this.emadEditable!, existingConfigItem);
      });
    } else {
      const matIconComponent = this.viewContainerRef.createComponent(MatIcon);
      matIconComponent.setInput('svgIcon', 'Icon Pen');
      matIconComponent.instance._elementRef.nativeElement.classList.add('!h-4', '!w-4', 'cursor-pointer', 'ml-2');
      matIconComponent.instance._elementRef.nativeElement.addEventListener('click', () => {
        this.openAttributeForm(this.emadEditable!, existingConfigItem);
      });
      this.renderer.appendChild(
        this.viewContainerRef.element.nativeElement,
        matIconComponent.instance._elementRef.nativeElement,
      );
    }
    this.hasIcon = true;
  }

  public getConfigForPosition(position: string): LayoutConfigCardItem | undefined {
    return this.configService.config$.getValue().layout.cardConfig.items.find((item) => item.position === position);
  }

  public openAttributeForm(position: string, item: LayoutConfigCardItem | undefined) {
    const component = this.injector.get(FORM_COMPONENT) as Type<any>;
    const ref = this.dialog.open(component, {
      panelClass: ['side-menu-dialog'],
      positionStrategy: this.overlay.position().global().right().centerVertically(),
    });
    ref.componentInstance?.init(position, item);
    ref.closed.pipe(first()).subscribe((_result) => {
      const value = _result as any | undefined;

      if (value) {
        if (value.type === 'delete') {
          const currentDataConfig = this.configService.config$.getValue().layout;
          this.configService.updateConfig({
            layout: {
              ...currentDataConfig,
              cardConfig: {
                items: currentDataConfig.cardConfig.items.filter((item) => item.position !== value.item.position),
              },
            },
          });
        } else if (value.type === 'set') {
          const currentDataConfig = this.configService.config$.getValue().layout;

          const itemIndex = currentDataConfig.cardConfig.items.findIndex(
            (item) => item.position === value.item.position,
          );
          if (itemIndex !== -1) {
            currentDataConfig.cardConfig.items[itemIndex] = value.item;
          } else {
            currentDataConfig.cardConfig.items.push(value.item);
          }

          this.configService.updateConfig({
            layout: {
              ...currentDataConfig,
              cardConfig: {
                items: [...currentDataConfig.cardConfig.items, value.item],
              },
            },
          });
        }
      }
    });
  }
}
