import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { EmbertApplicationState } from '../../types/application.types';
import { EmbertConfig } from '../../../../../shared/types/config/config.types';
import { AbstractConfigService } from '../../../../../shared/types/config/config.service.types';

@Injectable({
  providedIn: 'root',
})
export class ConfigService extends AbstractConfigService {
  private STORAGE_KEYS = {
    embertConfig: 'embert_embertConfig',
    embertApplicationState: 'embert_applicationState',
  };

  private defaultConfig: EmbertConfig = {
    data: {
      type: 'server',
      title: 'My Data Source',
      autoGenerateKey: true,
      keyProperty: '',
      file: {
        url: '',
      },
      spreadsheet: {
        url: '',
        autoFetch: false,
        fetchInterval: 'daily',
      },
      server: {
        url: '',
        autoFetch: false,
        fetchInterval: 'daily',
      },
      availableProperties: [],
    },
    layout: {
      baseLayout: 'cards',
      cardsLayout: 'grid',
      gridItemStyle: 'card_1',
      tableItemStyle: 'table_row_1',
      cardsGridColumnsMax: '4',
      pagination: 'paging',
      cardConfig: {
        items: [],
      },
      tableConfig: {
        coverImage: {
          propertyKey: '',
        },
        properties: [
          {
            propertyKey: 'key',
            active: true,
            order: 0,
            type: 'string',
          },
          {
            propertyKey: 'price',
            active: false,
            order: 1,
            type: 'int',
          },
        ],
      },
    },
    design: {
      colorScheme: 'color_scheme_1',
      fontFamily: 'Onest',
      fontSize: '16px',
      size: 'compact',
      styling: 'edgy',
      animation: 'off',
    },
    filters: {
      properties: [
        {
          key: 'name',
          filterElement: 'text',
        },
      ],
    },
    settings: {
      title: 'My Project',
      url: 'https://go.embert.io/my-project-id',
      private: false,
    },
  };

  private defaultApplicationState: EmbertApplicationState = {
    activeTab: 'data',
    activeView: 'debug',
    clientSize: 'full',
  };

  public override config$!: BehaviorSubject<EmbertConfig>;
  public applicationState$!: BehaviorSubject<EmbertApplicationState>;

  public init() {
    const storedConfig = this.getStorageValue(this.STORAGE_KEYS.embertConfig) || this.defaultConfig;
    this.config$ = new BehaviorSubject<EmbertConfig>(storedConfig);

    const storedApplicationState =
      this.getStorageValue(this.STORAGE_KEYS.embertApplicationState) || this.defaultApplicationState;
    this.applicationState$ = new BehaviorSubject<EmbertApplicationState>(storedApplicationState);
    this.initConfigPersistence();
  }

  private initConfigPersistence() {
    this.config$.subscribe((config) => {
      this.persistValue(this.STORAGE_KEYS.embertConfig, config);
    });

    this.applicationState$.subscribe((applicationState) => {
      this.persistValue(this.STORAGE_KEYS.embertApplicationState, applicationState);
    });
  }

  private getStorageValue(key: string) {
    try {
      const storedConfig = sessionStorage.getItem(key);
      if (storedConfig) {
        return JSON.parse(storedConfig);
      }
      return null;
    } catch (err) {
      return null;
    }
  }

  private persistValue(key: string, value: any) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      console.error('Error persisting config', err);
    }
  }

  public override updateConfig(config: Partial<EmbertConfig>) {
    this.config$.next({
      ...this.config$.getValue(),
      ...config,
    });
  }

  public updateApplicationState(applicationState: Partial<EmbertApplicationState>) {
    this.applicationState$.next({
      ...this.applicationState$.getValue(),
      ...applicationState,
    });
  }
}
