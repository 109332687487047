import { DcuplList } from '../list';
var ListsController = /** @class */function () {
  function ListsController(dcuplInstance) {
    this.dcuplInstance = dcuplInstance;
    this.lists = [];
  }
  ListsController.prototype.get = function (listKey) {
    if (listKey) {
      return this.lists.find(function (list) {
        return list.key === listKey;
      });
    }
    return undefined;
  };
  ListsController.prototype.getAll = function (options) {
    if (!options) return this.lists;
    return this.lists.filter(function (list) {
      return list.modelKey === (options === null || options === void 0 ? void 0 : options.modelKey);
    });
  };
  ListsController.prototype.destroy = function (options) {
    var _this = this;
    if (!options) {
      this.lists.forEach(function (list) {
        return list.destroy();
      });
      this.lists = [];
    } else if (options.listKey) {
      var idx = this.lists.findIndex(function (list) {
        return list.key === options.listKey;
      });
      if (idx > -1) {
        this.lists[idx].destroy();
        this.lists.splice(idx, 1);
      }
    } else if (options.modelKey) {
      this.lists.forEach(function (list, idx) {
        if (list.modelKey === options.modelKey) {
          list.destroy();
          _this.lists.splice(idx, 1);
        }
      });
    }
  };
  ListsController.prototype.create = function (options) {
    var list = new DcuplList(this.dcuplInstance);
    list['init'](options);
    this.lists.push(list);
    return list;
  };
  return ListsController;
}();
export { ListsController };
